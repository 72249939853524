import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { SessionService } from '@features/session/session.service';
import { catchError } from 'rxjs/operators';
import { AlertService } from '@core/services/alert.service';
import { Router } from '@angular/router';

export interface HttpError {
  error: string;
  message: string;
  path: string;
  status: string;
  timestamp: string;
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private session: SessionService,
    private alertService: AlertService,
    private router: Router
  ) {}

  get accessToken() {
    return this.session.currentUser.signInUserSession.accessToken.jwtToken;
  }
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.alertService.showAlert(
            'Session Expired. Please login again.',
            'error'
          );
          // let userTourHistory = JSON.parse(localStorage.getItem('userTours'));
          localStorage.clear();
          // localStorage.setItem('userTours', JSON.stringify(userTourHistory));
          this.redirectToLogin();
          return;
        }
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          console.log('this is client side error');
          errorMsg = `Error: ${error.error.message}`;
        } else {
          console.log('this is server side error');
          errorMsg = `Error Code: ${error.status},  Message: ${error.error.errorMessage}`;
          this.handleError(error);
        }
        console.log(errorMsg);
        return throwError(error.error.errorMessage);
      })
    );
  }

  handleError(response: HttpErrorResponse) {
    const { message, status, error } = response;
    switch (status) {
      case 401:
        // unauthenticated
        this.redirectToLogin();
        break;
      case 0:
        // cors
        this.alertService.showAlert(
          'We are facing some issues. Please try again after some time',
          'error'
        );
      // this.redirectToDashboard();
      default:
        if (error.errorMessage) {
          this.alertService.showAlert(error.errorMessage, 'error');
        } else {
          this.alertService.showAlert(
            'We are facing some issues. Please try again after some time',
            'error'
          );
        }
        break;
    }
  }

  redirectToLogin() {
    this.session.navigateToLoginPage();
    this.alertService.showAlert(`Please Login.`, 'error');
  }

  redirectToDashboard() {
    this.router.navigateByUrl('/dashboard');
  }
}

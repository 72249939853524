import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Notification } from './notification.model';

@Component({
  selector: 'app-notification-view',
  templateUrl: './notification-view.component.html',
  styleUrls: ['./notification-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationViewComponent {
  @Input() notifications: Notification[] = [];
  @Output() markAllReadClicked = new EventEmitter<void>();
  @Output() notificationClicked = new EventEmitter<Notification>();
  constructor() {}

  onClickMarkAllRead() {
    this.markAllReadClicked.emit();
  }

  onNotificationClicked(notification: Notification) {
    this.notificationClicked.emit(notification);
  }

  extractNotificationName(notification: Notification) {
    return notification.hasOwnProperty('createdBy')
      ? this.extractNameInitial(notification.createdBy)
      : '';
  }

  extractNameInitial(name: string) {
    return name.charAt(0).toUpperCase();
  }

  isNotificationRead(notification: Notification) {
    return !!notification.read;
  }

  hasUnreadNotifications() {
    return (
      this.notifications.filter((notification) => !notification.read).length > 0
    );
  }
}

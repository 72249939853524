import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { SessionService } from '@features/session/session.service';
import { UiService } from './ui/ui.service';
import { LoggedInUserRole } from '@features/session/session.model';

type Route = {
  url: string;
  exact: boolean;
};

@Injectable()
/**
 * Service for managing global router state
 */
export class RouterService implements OnDestroy {
  notifier = new Subject();
  navbarRoutes = [
    '/admin-dashboard',
    '/manage-users',
    '/dashboard',
    '/tutorials',
    '/social-document',
    '/interactive-video',
    '/quiz/',
    '/mindmap',
    '/crossword',
    '/quiz-game/add',
    '/quiz-game/edit',
    '/attendance',
    '/image-upload',
    '/assignment',
    '/gradebook',
    '/gradereport',
    '/lesson-plans/add',
    '/lesson-plans/new',
    '/lesson-plans/generate',
    '/lesson-plans/wizard',
    '/lesson-plans/edit',
    '/lesson-plans/duplicate',
    '/flashcards',
    '/materials',
    '/simulations',
    '/grades',
    '/assessmentfeedbacks',
    '/assessmentfeedbacks/add',
  ];
  navbarExcludeRoutes = [
    '/submission/$/view',
    '/assessmentfeedbacks/$/view-details/$',
  ];
  sidenavRoutes = [
    '/dashboard/chapter-content',
    '/question-bank',
    '/question-papers',
    '/flashcards-list',
    '/question-notes',
    '/gradebook',
    '/gradereport',
    '/manage/',
    '/subgroups/',
    '/assessmentfeedbacks/$/$/list',
  ];
  collapseRoutes = [
    '/question-bank',
    '/question-papers',
    '/flashcards-list',
    '/question-notes',
    '/gradebook',
    '/gradereport',
    '/manage/',
    '/subgroups/',
    '/admin-dashboard',
    '/assessmentfeedbacks/$/$/list',
  ];
  sidenavExactRoutes = ['/admin-dashboard'];
  homeButtonRoutes = ['/session-start', '/dashboard/chapter-content/'];
  exitButtonRoutes = [
    '/social-document',
    '/interactive-video',
    '/quiz/',
    '/mindmap',
    '/crossword',
    '/tutorials',
    '/quiz-game/add',
    '/quiz-game/edit',
    '/attendance',
    '/dashboard/tutorial-content',
    '/question-bank',
    '/question-papers',
    '/flashcards-list',
    '/question-notes',
    '/assignment',
    '/announcements',
    '/manage/',
    '/subgroups/',
    '/gradebook',
    '/gradereport',
    '/lesson-plans/add',
    '/lesson-plans/edit',
    '/lesson-plans/new',
    '/lesson-plans/generate',
    '/lesson-plans/wizard',
    '/lesson-plans/duplicate',
    '/flashcards',
    '/materials',
    '/simulations',
    '/admin-dashboard/manage-users',
    '/grades',
    '/assessmentfeedbacks',
    '/assessmentfeedback/$/$/list/',
  ];

  allowPrevPageStorage = [
    '/social-document',
    '/interactive-video',
    '/quiz/',
    '/mindmap',
    '/crossword',
    '/tutorials',
    '/flashcards',
    '/materials',
    '/simulations',
  ];

  notificationRoutes = ['/dashboard', '/admin-dashboard'];
  constructor(
    private router: Router,
    private uiService: UiService,
    private breakpointObserver: BreakpointObserver,
    private session: SessionService
  ) {}

  routeUrlChanged$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((event) => event as NavigationEnd),
    map((event) => event.url)
  );

  public initRouterEvents() {
    this.router.events.pipe(takeUntil(this.notifier)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.conditionallyShowNavbar(event);
        this.conditionallyShowHomeButton(event);
        this.conditionallyShowChapterHomeButton(event);
        this.hideDefaultSidenavOnMobile(event);
        this.conditionallyShowExitButton(event);
        this.handleHomePageRoute(event.url);
        this.handleLoginPageRoute(event.url);
        const previousUrl = this.uiService.stateSnapshot.currentPage;
        const currentUrl = event.url;
        if (currentUrl !== previousUrl) {
          // Reloading
          let myContentUrl = this.uiService.returnMyContentUrl();
          if (this.isRoutePresent(this.allowPrevPageStorage, previousUrl)) {
            this.uiService.setPreviousPage(previousUrl);
          } else {
            this.uiService.setPreviousPage(myContentUrl);
          }
          this.uiService.setCurrentPage(currentUrl);
        }
        this.setCollapsible(event.url);
      }

      if (event instanceof NavigationError) {
      }
    });
  }

  handleHomePageRoute(url: string) {
    if (url !== '/') return;
    this.navigateLoggedInUserToDashboard();
  }

  setCollapsible(url: string) {
    if (this.isRoutePresent(this.collapseRoutes, url)) {
      this.uiService.setNavbarCollapsible(true);
      return;
    } else {
      this.uiService.setNavbarCollapsible(false);
    }
  }

  handleLoginPageRoute(url: string) {
    if (
      url === '/auth/login' ||
      url === '/auth/signupmode' ||
      url === '/auth/signup'
    ) {
      this.navigateLoggedInUserToDashboard();
    } else return;
  }
  navigateLoggedInUserToDashboard() {
    if (!this.session.isLoggedIn) return;
    let userRoleId = this.session.userRole;
    userRoleId === LoggedInUserRole.ADMIN
      ? this.router.navigateByUrl('/admin-dashboard')
      : this.router.navigateByUrl('/dashboard');
  }

  hideDefaultSidenavOnMobile(event: NavigationEnd) {
    this.breakpointObserver
      .observe(['(max-width: 750px)'])
      .pipe(takeUntil(this.notifier))
      .pipe(map((result) => result.matches))
      .subscribe((isMobile) => {
        this.conditionallyShowSidenav(event);
        if (isMobile) {
          this.uiService.setSidenavVisibility(false);
        }
      });
  }

  conditionallyShowNavbar(event: NavigationEnd) {
    if (this.isRoutePresent(this.navbarExcludeRoutes, event.url)) {
      this.uiService.setNavbarVisibility(false);
      return;
    }

    if (this.isRoutePresent(this.navbarRoutes, event.url)) {
      this.uiService.setNavbarVisibility(true);
    } else {
      this.uiService.setNavbarVisibility(false);
    }
  }
  conditionallyShowSidenav(event: NavigationEnd) {
    if (this.isRouteExactMatch(this.sidenavExactRoutes, event.url)) {
      this.uiService.setHasSidenav(true);
      this.uiService.setSidenavVisibility(true);
      return;
    }

    if (this.isRoutePresent(this.sidenavRoutes, event.url)) {
      this.uiService.setHasSidenav(true);
      this.uiService.setSidenavVisibility(true);
    } else {
      this.uiService.setSidenavVisibility(false);
      this.uiService.setHasSidenav(false);
    }
  }

  conditionallyShowHomeButton(event: NavigationEnd) {
    if (this.isRoutePresent(this.homeButtonRoutes, event.url)) {
      this.uiService.setShowHome(true);
    } else {
      this.uiService.setShowHome(false);
    }
  }

  conditionallyShowChapterHomeButton(event: NavigationEnd) {
    if (this.isRoutePresent(this.allowPrevPageStorage, event.url)) {
      this.uiService.setShowChapterHome(true);
    } else {
      this.uiService.setShowChapterHome(false);
    }
  }

  conditionallyShowExitButton(event: NavigationEnd) {
    if (this.isRoutePresent(this.exitButtonRoutes, event.url)) {
      this.uiService.setShowExit(true);
    } else {
      this.uiService.setShowExit(false);
    }
  }

  isRoutePresent(routes: string[], url: string) {
    // Some routes may have variable values inbetween paths,
    // For eg. we want to show the navbar on /submission/:id, but not on /submission/:id/view
    // so those routes can be setup with $ insdead of the variable. This function will check if the url
    // matches the has all parts of the route if split by the $ sign. So if the url is /submission/:id/view
    // will return true for route /submission/$/view but if the url is /submission/:id , it will return false
    // This allows handling /submission independently from /submission/:id/view

    let returnVal = false;
    routes.forEach((route) => {
      let routeParts = route.split('$');
      let check = (routePart) => url.includes(routePart);
      returnVal = returnVal || routeParts.every(check);
    });
    return returnVal;
  }

  isRouteExactMatch(routes: string[], url: string) {
    return routes.filter((route) => url === route).length > 0;
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}

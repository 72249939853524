import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlEditorComponent } from './html-editor.component';
import { NgxTinymceModule } from 'ngx-tinymce';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [HtmlEditorComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgxTinymceModule.forRoot({
      baseURL: 'https://cdnjs.cloudflare.com/ajax/libs/tinymce/5.10.7/',
    }),
  ],
  exports: [HtmlEditorComponent],
})
export class HtmlEditorModule {}

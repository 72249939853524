export async function delay(durationInSeconds: number) {
  return await new Promise((resolve) =>
    setTimeout(resolve, durationInSeconds * 1000)
  );
}

export function dataURItoBlob(dataURI) {
  const byteCharacters = atob(
    dataURI.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')
  );
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {
    type: 'image/jpeg',
  });

  return blob;
}

export function extractYoutubeId(youtubeUrl: string) {
  const youtubeRegex =
    /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const result = youtubeUrl.match(youtubeRegex);
  return result[1];
}

export function generateYoutubePosterUrl(youtubeId: string) {
  return `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`;
}

export function generateYoutubeEmbedUrl(youtubeUrl: string) {
  const youtubeId = extractYoutubeId(youtubeUrl);
  if (!youtubeId) return;
  return `https://youtube.com/embed/${youtubeId}`;
}

export function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));

    [array[i], array[j]] = [array[j], array[i]];
    return array;
  }
}

/**
 *
 * @param index - Index of array items (Note index needs to be > 0)
 * @returns respective option for multiple choice question. Eg a, b, c etc
 */
export function generateOptionFromIndex(index: number) {
  switch (index) {
    case 0:
      return 'a';
    case 1:
      return 'b';
    case 2:
      return 'c';
    case 3:
      return 'd';
    case 4:
      return 'e';
    case 5:
      return 'f';
  }
}

export function extractNumbersFromString(value: string) {
  return Number(value.match(/\d+/g).join());
}

export function encodeBase64(value: string) {
  return btoa(value);
}

export function decodeBase64(value: string) {
  return atob(value);
}

export function numberToRoman(number) {
  let roman = '';
  const romanNumList = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XV: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  let a;
  if (number < 1 || number > 3999) return 'Enter a number between 1 and 3999';
  else {
    for (let key in romanNumList) {
      a = Math.floor(number / romanNumList[key]);
      if (a >= 0) {
        for (let i = 0; i < a; i++) {
          roman += key;
        }
      }
      number = number % romanNumList[key];
    }
  }

  return roman;
}

// check if input is a valid number greater than 0
export function isValidNumber(value: number) {
  return Number(value) > 0;
}

import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { passwordMatchValidator } from '@core/utils/validator-utils';
import { SessionService } from '../session.service';
import { OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password-submit-form',
  templateUrl: './forgot-password-submit-form.component.html',
  styleUrls: ['./forgot-password-submit-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordSubmitFormComponent implements OnInit {
  showPassword = false;
  // @Input() set errorMessage(message: string) {
  //   if (this.forgotPasswordSubmitForm && message && message.trim().length) {
  //     this.forgotPasswordSubmitForm.setErrors({ invalid: true, message });
  //   }
  // }
  @Input() errorMessage$;
  @Input() forgotPasswordSubmitEmail;

  @Output() forgotPasswordSubmitClicked = new EventEmitter<{
    email: string;
    code: string;
    password: string;
  }>();
  @Output() loginClicked = new EventEmitter<boolean>();
  forgotPasswordSubmitForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private session: SessionService) {
    this.forgotPasswordSubmitForm = this.fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        code: ['', [Validators.required]],
        newPassword: ['', [Validators.minLength(8)]],
        confirmPassword: ['', [Validators.minLength(8)]],
      },
      { validators: passwordMatchValidator }
    );
  }

  ngOnInit(): void {
    this.email.patchValue(this.forgotPasswordSubmitEmail);
  }

  loading$ = this.session.loading$;

  get email() {
    return this.forgotPasswordSubmitForm.get('email') as UntypedFormControl;
  }

  get code() {
    return this.forgotPasswordSubmitForm.get('code') as UntypedFormControl;
  }

  get emailErrorMessage() {
    if (this.email.hasError('required')) return 'Email is required';
    if (this.email.hasError('email')) return 'Please provide a valid email';
    return '';
  }

  get codeErrorMessage() {
    if (this.code.hasError('required')) return 'Code is required';
    return '';
  }

  get newPassword() {
    return this.forgotPasswordSubmitForm.get('newPassword') as UntypedFormControl;
  }

  get confirmPassword() {
    return this.forgotPasswordSubmitForm.get('confirmPassword') as UntypedFormControl;
  }

  get newPasswordErrorMessage() {
    if (this.newPassword.hasError('required')) return 'Password is required';
    if (this.newPassword.hasError('minlength'))
      return 'Password must be minimum 8 characters long';
    return '';
  }
  get confirmPasswordErrorMessage() {
    if (this.confirmPassword.hasError('required'))
      return 'Password is required';
    if (this.newPassword.hasError('passwordMatch'))
      return `Passwords don't match`;
    return '';
  }

  get formErrorMessage() {
    if (
      this.forgotPasswordSubmitForm.hasError('invalid') &&
      this.forgotPasswordSubmitForm.hasError('message')
    ) {
      return this.forgotPasswordSubmitForm.errors['message'];
    }
    if (this.forgotPasswordSubmitForm.hasError('passwordMatch'))
      return `Passwords don't match`;
  }

  forgotPasswordSubmit() {
    if (!this.forgotPasswordSubmitForm.valid) return;
    this.forgotPasswordSubmitClicked.emit({
      email: this.email.value,
      code: this.code.value,
      password: this.newPassword.value,
    });
  }

  backToLogin() {
    this.loginClicked.emit(true);
  }
}

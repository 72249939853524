import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from '@features/session/session.service';
import packageInfo from '../../../../package.json';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
  constructor(private session: SessionService) {}

  urlsToExclude = ['/contactForm'];

  get accessToken() {
    return this.session?.accessToken || '';
  }
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.isUrlExcluded(request.url)) {
      return next.handle(request);
    }
    let authRequest = request;
    if (this.accessToken) {
      authRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.accessToken}`,
          'x-app-version': packageInfo.version,
        },
      });
    }
    return next.handle(authRequest);
  }

  isUrlExcluded(url: string) {
    return this.urlsToExclude.filter((item) => url.includes(item)).length > 0;
  }
}

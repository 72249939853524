import { Injectable } from '@angular/core';
import { ConfigService } from '@core/services/config.service';

@Injectable()
export class Constants {
  constructor(private config: ConfigService) {}

  private get apiBaseUrl() {
    return this.config.readConfig().apiBaseUrl;
  }

  private get notificationBaseUrl() {
    return this.config.readConfig().notificationApiBaseUrl;
  }
  public readonly API_ENDPOINT = this.apiBaseUrl;
  public readonly NOTIFICATION_ENDPOINT = this.notificationBaseUrl;
}

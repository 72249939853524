import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationViewComponent } from './notification-view.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [NotificationViewComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule],
  exports: [NotificationViewComponent],
})
export class NotificationViewModule {}

import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse): void {
    if (error instanceof HttpErrorResponse) {
      this.handleServerSideError(error);
    } else {
      this.handleClientSideError(error);
    }
  }

  // TODO implement this
  handleClientSideError(error: Error): void {
    const message = error.message ? error.message : error.toString();
  }

  // TODO implement this
  handleServerSideError(error: HttpErrorResponse): void {
    const message = error.message ? error.message : error.toString();
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { encodeBase64 } from '@core/utils/helper';
import { DashboardService } from '@features/dashboard/dashboard.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { UiStateService } from './ui-state.service';

@Injectable({ providedIn: 'root' })
export class UiService {
  constructor(
    private uiState: UiStateService,
    private router: Router,
    private dashboardService: DashboardService
  ) {}

  readonly navbarVisibility$ = this.uiState.getStateSlice('navbarVisible');
  readonly sidenavVisibility$ = this.uiState.getStateSlice('sidenavVisible');
  readonly sidenavisCollapsible$ = this.uiState.getStateSlice('isCollapsible');
  readonly sidenavisCollapsed$ = this.uiState.getStateSlice('isCollapsed');
  readonly allowCollapse$ = this.uiState.getStateSlice('allowCollapse');
  readonly hasSidenav$ = this.uiState.getStateSlice('hasSidenav');
  readonly showExit$ = this.uiState.getStateSlice('showExit');
  readonly showHome$ = this.uiState.getStateSlice('showHome');
  readonly userTourOpen$ = this.uiState.getStateSlice('userTourOpen');
  readonly showChapterHome$ = this.uiState.getStateSlice('showChapterHome');

  private _badgeViewisOpen = new BehaviorSubject<boolean>(false);

  //To do - sidenav collapsed needs to be an observable instead of state

  private currentCollapsedStateSubject$ = new BehaviorSubject<boolean>(false);
  currentCollapsedState$ = this.currentCollapsedStateSubject$.asObservable();

  private sidenavContentComponent = new Subject();
  sessionStatus$ = this.dashboardService.currentSessionParticipants$;
  get stateSnapshot() {
    return this.uiState.snapshot;
  }

  get sidenavContent$() {
    return this.sidenavContentComponent.asObservable();
  }

  get currentChapterId() {
    return this.dashboardService.stateSnapshot.currentChapter;
  }
  get currentSubjectId() {
    return this.dashboardService.stateSnapshot.currentSubject.subjectId;
  }
  get currentCourseId() {
    return this.dashboardService.stateSnapshot.currentCourseId;
  }

  get badgeViewisOpen() {
    return this._badgeViewisOpen.asObservable();
  }

  togglebadgeView() {
    this._badgeViewisOpen.next(!this._badgeViewisOpen.value);
  }

  closebadgeView() {
    this._badgeViewisOpen.next(false);
  }

  setSidenavContent(customComponent: any) {
    this.sidenavContentComponent.next(customComponent);
  }

  setNavbarVisibility(navbarVisible: boolean) {
    this.uiState.setState({ navbarVisible });
  }

  setUserTourVisibility(userTourOpen: boolean) {
    this.uiState.setState({ userTourOpen });
  }

  setsocDocActivePage(socDocActivePage: { id: number; activePage: number }[]) {
    this.uiState.setState({ socDocActivePage });
  }

  setNavbarCollapsible(isCollapsible: boolean) {
    this.uiState.setState({ isCollapsible });
  }

  setNavbarCollapsed(isCollapsed: boolean) {
    this.uiState.setState({ isCollapsed });
    this.currentCollapsedStateSubject$.next(isCollapsed);
  }
  setAllowCollapse(allowCollapse: boolean) {
    this.uiState.setState({ allowCollapse });
  }

  setSidenavVisibility(sidenavVisible: boolean) {
    this.uiState.setState({ sidenavVisible });
  }

  setHasSidenav(hasSidenav: boolean) {
    this.uiState.setState({ hasSidenav });
  }

  setShowExit(showExit: boolean) {
    this.uiState.setState({ showExit });
  }

  setShowHome(showHome: boolean) {
    this.uiState.setState({ showHome });
  }

  setShowChapterHome(showChapterHome: boolean) {
    this.uiState.setState({ showChapterHome });
  }

  setPreviousPage(previousPage: string) {
    this.uiState.setState({ previousPage });
  }

  setCurrentPage(currentPage: string) {
    this.uiState.setState({ currentPage });
  }

  navigateToHome() {
    this.router.navigateByUrl('/dashboard');
  }

  returnMyContentUrl() {
    let returnUrl;
    try {
      let courseIdEn = encodeURIComponent(
        encodeBase64(this.currentCourseId.toString())
      );
      let subjectIdEn = encodeURIComponent(
        encodeBase64(this.currentSubjectId.toString())
      );
      let chapterIdEn = encodeURIComponent(
        encodeBase64(this.currentChapterId.toString())
      );
      returnUrl = `/dashboard/chapter-content/${courseIdEn}/${subjectIdEn}/${chapterIdEn}`;
    } catch (error) {
      returnUrl = '/dashboard';
    }
    return returnUrl;
  }

  backToMyContent() {
    try {
      let courseIdEn = encodeURIComponent(
        encodeBase64(this.currentCourseId.toString())
      );
      let subjectIdEn = encodeURIComponent(
        encodeBase64(this.currentSubjectId.toString())
      );
      let chapterIdEn = encodeURIComponent(
        encodeBase64(this.currentChapterId.toString())
      );
      this.router.navigate(
        [
          `/dashboard/chapter-content/${courseIdEn}/${subjectIdEn}/${chapterIdEn}`,
        ],
        {
          queryParams: {
            viewMode: 'myContent',
          },
        }
      );
    } catch (error) {
      this.navigateToHome();
    }
  }
}

<!-- Equation Editor -->
<div class="wrapper">
<div class="container">
  <div class="col-1">
    <!-- Editor -->
    <div #mathField id="mathField" class="equation-editor"></div>
  </div>
  <div class="col-2">
    <!-- Button Bar -->

    <div class="btn-container m-0">
      <ng-container *ngIf="buttonBar$|async as buttonBar">
      <button (click)="insert(button)" *ngFor="let button of buttonBar" class="btn btn-toolbar">
        $${{ button.text }}$$
      </button>
      </ng-container>
    </div>
    <div class="col-2-container">
      <div *ngFor="let group of currentGroup$|async">
        <button (click)="collapse($event)" class="collapsible">
          {{ group.name }}
        </button>
        <div class="content">
          <div class="btn-container">
            <button (click)="insert(button)" *ngFor="let button of group.buttons" class="btn btn-collapse">
              $${{ button.text }}$$
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>

import { Route } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanLoad,
  UrlSegment,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class SessionGuard implements CanActivate, CanLoad {
  constructor(private session: SessionService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.session.isLoggedIn) {
      this.session.setRedirectUrl(state.url);
      this.session.navigateToLoginPage();
      return false;
    }
    return true;
  }

  canLoad(route: Route, segments: UrlSegment[]) {
    if (!this.session.isLoggedIn) {
      this.session.setRedirectUrl(this.extractCurrentUrl());
      this.session.navigateToLoginPage();
      return false;
    }
    if (this.session.isLoggedIn && !this.session.hasUserAttributes) {
      this.router.navigate(['/auth/signup'], {
        queryParams: { unfinished: true },
      });
      return false;
    }
    return true;
  }

  extractCurrentUrl() {
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extractedUrl) {
      return navigation.extractedUrl.toString();
    }
    return '';
  }
}

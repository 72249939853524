import { Injectable, Component, Inject } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

type AlertType = 'error' | 'warning' | 'success';

@Component({
  selector: 'app-snackbar-component',
  template: `
    <div class="snackbar-content">
      {{ data.message }}
      <button mat-icon-button class="close-button" (click)="closeSnackbar()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  `,
  styles: [
    `
      .snackbar-content {
        display: flex;
        align-items: center;
        margin-right: 1rem;
      }

      .close-button {
        color: #fff !important ;
        position: absolute;
        top: 0;
        right: 0;
      }
    `,
  ],
})
export class SnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  closeSnackbar() {
    // Handle close logic
  }
}

@Injectable({ providedIn: 'root' })
export class AlertService {
  constructor(private snackbar: MatSnackBar) {}

  showAlert(
    message: string,
    type: AlertType,
    duration = 3000,
    verticalPosition: MatSnackBarVerticalPosition = 'top',
    horizontalPosition: MatSnackBarHorizontalPosition = 'end'
  ) {
    const config: MatSnackBarConfig = {
      duration,
      verticalPosition,
      horizontalPosition,
      panelClass: type,
    };

    const snackbarRef = this.snackbar.openFromComponent(SnackbarComponent, {
      data: { message },
      ...config,
    });

    snackbarRef.instance.closeSnackbar = () => {
      snackbarRef.dismiss();
    };
  }
}

<div style="padding: 1rem">
  <h1 mat-dialog-title class="titleDiv">
    <div>
      <div class="matIconDiv">
        <mat-icon>warning</mat-icon>
      </div>
      {{ title }}
    </div>
  </h1>

  <div mat-dialog-content [innerHtml]="message"></div>

  <div mat-dialog-actions class="dialogActions">
    <button mat-button *ngIf="showCancel" (click)="onDismiss()">
      {{ cancelText }}
    </button>
    <button mat-button color="primary" (click)="onConfirm()">
      {{ confirmText }}
    </button>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { SessionStateService, SessionState } from './session-state.service';
import {
  CognitoUser,
  LoggedInUserRole,
  LoginResult,
  VerifyUserResponse,
} from './session.model';
import { BrowserStorageService } from '@core/services/storage.service';
import { ApiHttpService } from '@core/services/api-http.service';
import { ApiEndpointsService } from '@core/services/api-endpoints.service';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { UiService } from '@core/services/ui/ui.service';
import { delay } from '@core/utils/helper';
import { QueryStringParameters } from '@shared/classes/query-string-parameters';
import { Hub, ICredentials } from '@aws-amplify/core';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { AlertService } from '@core/services/alert.service';

@Injectable({ providedIn: 'root' })
export class SessionService {
  constructor(
    private sessionState: SessionStateService,
    private router: Router,
    private alertService: AlertService,
    private storageService: BrowserStorageService,
    private apiHttpService: ApiHttpService,
    private apiEndpointService: ApiEndpointsService,
    private uiService: UiService
  ) {
    if (this.isLoggedIn) {
      this.loggedInSubject$.next(true);
    }

    Hub.listen('auth', (data) => {
      const { channel, payload } = data;
      const { event } = payload;
      this.setLoading(true);
      if (event === 'oAuthSignOut') {
        this.setCurrentUser(undefined);
      }
      if (channel === 'auth' && event === 'signIn') {
        let payLoadData = payload.data;
        if ('storage' in payLoadData) {
          // Looks like amplify is storing a stringified value of all data which is making the stored object too big.
          // This sometimes prevents login due to "out of memory" issues.
          delete payLoadData.storage;
        }
        this.setCurrentUser(payLoadData);
        if (!this.hasUserAttributes) {
          // const qs: Params = {
          //   firstName: this.userFirstName,
          //   lastName: this.userLastName,
          //   email: this.userEmail,
          //   isSocialSignup: true,
          // };
          this.setLoading(false);
          this.router.navigate(['/auth/login']);
          this.alertService.showAlert(
            'This account does not exist. Please email contact@litmuslearn.com to setup a new account.',
            'warning',
            10000
          );
        } else {
          this.loggedInSubject$.next(true);
          this.getOrgDetails().subscribe((res) => {
            this.setOrgDetails(res);
          });
          this.router.navigate(['/dashboard']);
        }
      }
    });
  }

  getOrgDetails() {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'organisations',
      [`${this.organization}`]
    );

    return this.apiHttpService.get<any>(endpoint).pipe(map((res) => res));
  }

  readonly loading$ = this.sessionState.getStateSlice('loading');

  private loggedInSubject$ = new BehaviorSubject<boolean>(false);
  loggedIn$ = this.loggedInSubject$.asObservable();

  get stateSnapshot() {
    return this.sessionState.snapshot;
  }

  get redirectUrl() {
    return this.sessionState.getSnapshotSlice('redirectUrl');
  }

  get currentUser() {
    return this.sessionState.getSnapshotSlice('loggedInUser');
  }

  get organization() {
    return Number(
      this.currentUser?.signInUserSession?.idToken?.payload['custom:orgid']
    );
  }

  get userId() {
    return Number(
      this.currentUser?.signInUserSession?.idToken?.payload['custom:userid']
    );
  }

  get userFirstName() {
    return this.currentUser?.signInUserSession?.idToken?.payload?.name;
  }

  get userEmail() {
    return this.currentUser?.signInUserSession?.idToken?.payload?.email;
  }

  get userLastName() {
    return this.currentUser?.signInUserSession?.idToken.payload?.family_name;
  }

  get userRole() {
    return Number(
      this.currentUser?.signInUserSession?.idToken?.payload['custom:roleid']
    ) as LoggedInUserRole;
  }

  get hasUserAttributes() {
    return (
      this.currentUser?.attributes ||
      this.currentUser?.signInUserSession?.idToken?.payload?.['custom:userid']
    );
  }

  get isLoggedIn() {
    return !!this.currentUser;
  }

  get accessToken() {
    return (
      this.stateSnapshot?.loggedInUser?.signInUserSession?.accessToken
        ?.jwtToken || ''
    );
  }

  get accessTokenUsername() {
    return (
      this.stateSnapshot?.loggedInUser?.signInUserSession?.accessToken?.payload
        .username || ''
    );
  }

  updateLoggedInStatus(loggedIn: boolean) {
    this.loggedInSubject$.next(loggedIn);
  }

  setRedirectUrl(url: string) {
    this.sessionState.setState({ redirectUrl: url });
  }

  setOrgDetails(org: any) {
    this.sessionState.setState({ orgDetails: org });
  }

  async login(username: string, password: string): Promise<LoginResult> {
    try {
      this.setLoading(true);
      const user = await Auth.signIn(username, password);
      this.setLoading(false);
      this.loggedInSubject$.next(true);
      return { data: user, error: undefined };
    } catch (error) {
      this.setLoading(false);
      console.log('error signing in', error);
      this.loggedInSubject$.next(false);
      return { data: undefined, error: error };
    }
  }

  async googleSocialSignIn(): Promise<ICredentials> {
    this.setLoading(true);
    const result = await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
    return result;
  }

  async setNewPassword(user: CognitoUser, newPassword: string) {
    try {
      const email =
        user?.signInUserSession?.idToken?.payload?.email ||
        user?.challengeParam?.userAttributes?.email;
      this.setLoading(true);
      await Auth.completeNewPassword(user, newPassword);
      await this.verfiyUserEmail(email);
      this.setLoading(false);
      return { data: 'success', error: undefined };
    } catch (error) {
      this.setLoading(false);
      console.log('error changing password', error);
      return { data: undefined, error: error };
    }
  }
  async forgotPassword(email: string) {
    try {
      this.setLoading(true);
      await Auth.forgotPassword(email);
      this.setLoading(false);
      return { data: 'success', error: undefined };
    } catch (error) {
      this.setLoading(false);
      console.log('error during forgot password', error);
      return { data: undefined, error: error };
    }
  }

  async submitForgotPassword(email: string, code: string, password: string) {
    try {
      this.setLoading(true);
      await Auth.forgotPasswordSubmit(email, code, password);
      this.setLoading(false);
      return { data: 'success', error: undefined };
    } catch (error) {
      this.setLoading(false);
      console.log('error during forgot password submit', error);
      return { data: undefined, error: error };
    }
  }

  async logout() {
    this.uiService.setSidenavVisibility(false);
    await delay(0.2);
    await Auth.signOut();
    this.setCurrentUser(undefined);
    this.sessionState.setState({ redirectUrl: '' });
    this.router.navigateByUrl('/');
    await delay(0.1);
    let userTourHistory = JSON.parse(localStorage.getItem('userTours'));
    this.storageService.clear();
    localStorage.setItem('userTours', JSON.stringify(userTourHistory));
    window.location.reload();
  }

  async logoutQuietly() {
    this.uiService.setSidenavVisibility(false);
    this.setCurrentUser(undefined);
    this.sessionState.setState({ redirectUrl: '' });
    // this.router.navigateByUrl('/');
    let userTourHistory = JSON.parse(localStorage.getItem('userTours'));
    this.storageService.clear();
    localStorage.setItem('userTours', JSON.stringify(userTourHistory));
    // window.location.reload();
    await delay(0.2);
    await Auth.signOut();
  }

  navigateToLoginPage() {
    this.setCurrentUser(undefined);
    let userTourHistory = JSON.parse(localStorage.getItem('userTours'));
    this.storageService.clear();
    localStorage.setItem('userTours', JSON.stringify(userTourHistory));
    this.router.navigateByUrl('auth/login');
  }

  verfiyUserEmail(email: string) {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      'users',
      [`${email}`, 'actions', 'verifyemail']
    );
    return this.apiHttpService
      .post<string>(endpoint, {})
      .pipe(map((res) => res))
      .toPromise();
  }

  createNewUser(
    payload: {
      email: string;
      name: string;
      familyName: string;
      roleId?: number;
    },
    additionalPayload: { refcode?: string; classCode?: string }
  ) {
    let finalPayload = { ...payload, ...additionalPayload };
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariables(`users`);
    return this.apiHttpService
      .post<any>(endpoint, finalPayload)
      .pipe(map((res) => res));
  }

  registerNewUser(
    username: string,
    payload: { firstName: string; lastName: string },
    additionalPayload: { refcode?: string; classCode?: string }
  ) {
    const endpoint =
      this.apiEndpointService.createUrlWithPathVariablesAndQueryParams(
        `users`,
        [`${username}`, 'actions', 'register'],
        (qs: QueryStringParameters) => {
          qs.push('firstName', `${payload.firstName}`);
          qs.push('lastName', `${payload.lastName}`);
          if (additionalPayload.refcode) {
            qs.push('referralCode', `${additionalPayload.refcode}`);
          }
          if (additionalPayload.classCode) {
            qs.push('classCode', `${additionalPayload.classCode}`);
          }
        }
      );
    return this.apiHttpService.post<any>(endpoint, {}).pipe(map((res) => res));
  }

  setCurrentUser(user: CognitoUser) {
    const currentState = this.stateSnapshot;
    const nextState: SessionState = { ...currentState, loggedInUser: user };
    this.sessionState.setState(nextState);
  }

  setLoading(loading: boolean) {
    this.sessionState.setState({ loading });
  }
}

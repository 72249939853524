import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@core/interfaces/appConfig';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configuration: AppConfig;

  constructor(private httpClient: HttpClient) {}

  setConfig(): Promise<AppConfig> {
    const appConfigPath = '/assets/config/app-config.json';
    return this.httpClient
      .get<AppConfig>(appConfigPath)
      .toPromise()
      .then((config) => (this.configuration = config));
  }

  readConfig(): AppConfig {
    return this.configuration;
  }
}

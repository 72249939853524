import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { FaqComponent } from './faq/faq.component';
import { SharedModule } from '@shared/shared.module';
import { WhatsappWidgetComponent } from './whatsapp-widget/whatsapp-widget.component';
import { ScrollIndicatorComponent } from './scroll-indicator/scroll-indicator.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SubscriptionComponent } from './subscription/subscription.component';
import { MaterialModule } from '@shared/material/material.module';



@NgModule({
  declarations: [
    TermsComponent,
    PrivacyComponent,
    FaqComponent,
    WhatsappWidgetComponent,
    ScrollIndicatorComponent,
    SubscriptionComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
})
export class LandingModule {}

/**
 * Global app UI State service. Use this to manage displaying global ui elements like navbar, sidebar, global alerts etc.
 */

import { Injectable } from '@angular/core';
import { Store } from 'src/app/store';

type UiState = {
  navbarVisible: boolean;
  sidenavVisible: boolean;
  showExit: boolean;
  showHome: boolean;
  hasSidenav: boolean;
  isCollapsible: boolean; //Is Current Route Collapsible - Route automated
  isCollapsed: boolean; //Current collapse state
  allowCollapse: boolean; //Allow collapse state
  showChapterHome: boolean; //Show button to go to chapter page.
  userTourOpen: boolean;
  previousPage: string;
  currentPage: string;
  socDocActivePage: { id: number; activePage: number }[];
};

const initialState: UiState = {
  navbarVisible: false,
  sidenavVisible: false,
  showExit: false,
  showHome: false,
  hasSidenav: false,
  showChapterHome: false,
  userTourOpen: false,
  isCollapsible: false,
  isCollapsed: false,
  allowCollapse: true,
  previousPage: '/',
  currentPage: '/',
  socDocActivePage: [],
};

@Injectable({ providedIn: 'root' })
export class UiStateService extends Store<UiState> {
  constructor() {
    super(initialState, { persist: true, key: 'uistate' });
  }
}

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnsureModuleLoadedOnceGuard } from './ensure-module-loaded-once.guard';
import { Constants } from '../config/constants';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MaterialModule } from '@shared/material/material.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpHeaderInterceptor } from './interceptors/http-header.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { DateUtilsService } from './utils/date.service';
import { NotificationViewModule } from '@libs/notification-view/notification-view.module';
import { SharedModule } from '@shared/shared.module';
import { NotificationMenuComponent } from './components/notification-menu/notification-menu.component';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { FeatureFlagsModule } from '@libs/feature-flags/feature-flags.module';
import { SupportMenuComponent } from './components/support-menu/support-menu.component';
import { ShareMenuComponent } from './components/share-menu/share-menu.component';
import {
  popperVariation,
  TippyModule,
  tooltipVariation,
} from '@ngneat/helipopper';
import { BadgeViewerComponent } from './components/badge-viewer/badge-viewer.component';
import { SessionStatusComponent } from './components/session-status/session-status.component';
import { SnackbarComponent } from './services/alert.service';

@NgModule({
  declarations: [
    NavbarComponent,
    NotificationMenuComponent,
    ProfileMenuComponent,
    ShareMenuComponent,
    SupportMenuComponent,
    BadgeViewerComponent,
    SessionStatusComponent,
    SnackbarComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NotificationViewModule,
    SharedModule,
    FeatureFlagsModule,
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: {
          ...popperVariation,
          arrow: false,
          interactiveBorder: 10,
          offset: [-8, -8],
          theme: 'light',
          inertia: true,
          delay: 500,
          animation: 'scale',
          interactive: true,
          trigger: 'click',
        },
      },
    }),
  ],
  exports: [NavbarComponent],
  providers: [
    DateUtilsService,
    Constants,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  /**
   * Ensure that CoreModule is only loaded into AppModule
   * @param parentModule
   *
   * Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
   */

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}

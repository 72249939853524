<button
  mat-icon-button
  #menuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="notificationsMenu"
  [matBadge]="unreadNotificationsCount$ | async"
  matBadgeColor="warn"
  [matBadgeHidden]="!showUnreadNotificationsBadge"
  aria-label="Notifications icon button"
>
  <mat-icon>notifications</mat-icon>
  <mat-menu
    #notificationsMenu="matMenu"
    class="notification-menu"
    xPosition="before"
  >
    <app-notification-view
      [notifications]="notifications"
      (notificationClicked)="handleNotificationClicked($event)"
      (markAllReadClicked)="handleMarkAllReadClicked()"
    ></app-notification-view>
  </mat-menu>
</button>

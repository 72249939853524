<div class="support-icon" [matMenuTriggerFor]="supportMenu">
  <mat-icon class="material-icons-outlined">help</mat-icon>
</div>

<mat-menu #supportMenu="matMenu">
  <!-- <button mat-menu-item (click)="userTourClicked()">
    <mat-icon>map</mat-icon> Feature Tour
  </button> -->
  <!-- <ng-container *appUserRole="[role.TEACHER, role.ADMIN]">
    <button mat-menu-item (click)="routetoTutorial()">
      <mat-icon class="material-icons-outlined">school</mat-icon>
      How to use Litmus?
    </button>
  </ng-container> -->
  <button mat-menu-item>
    <a
      class="contact-us"
      href="mailto:contact@litmuslearn.com?subject='Need help with Something - Reg'"
      target="_blank"
      rel="noopener noreferrer"
    >
      <mat-icon class="material-icons-outlined">contact_support</mat-icon>
      Contact Litmus
    </a>
  </button>
</mat-menu>

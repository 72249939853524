import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { get, has } from 'lodash-es';
import { Injectable } from '@angular/core';
import { FeatureFlagConfig, FEATURE_FLAGS } from './feature-flags.model';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  config!: FeatureFlagConfig;
  configUrl = '/assets/config/feature-flags-config.json';

  constructor(private http: HttpClient) { }

  loadConfig() {
    return this.http
      .get<FeatureFlagConfig>(this.configUrl)
      .pipe(tap((data) => (this.config = data)))
      .toPromise();
  }

  isEnabled(value: FEATURE_FLAGS | FEATURE_FLAGS[]): boolean {
    if (this.isOfTypeString(value)) {
      if (this.config && has(this.config, value)) {
        return get(this.config, value, false);
      }
    } else {
      const featureFlagKeys = value as FEATURE_FLAGS[];
      return featureFlagKeys.filter((key) => !this.isEnabled(key)).length === 0;
    }
    return false;
  }

  private isOfTypeString(value: any) {
    return typeof value === 'string';
  }
}

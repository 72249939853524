import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  ViewChild,
  Input,
  Output,
} from '@angular/core';
import { map } from 'rxjs/operators';
import { SessionService } from '@features/session/session.service';
import packageInfo from '../../../../../package.json';
import { MatMenuTrigger } from '@angular/material/menu';
import { Notification } from '@libs/notification-view/notification.model';
import { Router } from '@angular/router';
import { NavBarTourService } from './navbar-usertour.service';
import { ConfigService } from '@core/services/config.service';
import { CourseEnrollment } from '@features/dashboard/dashboard.model';
import { LoggedInUserRole } from '@features/session/session.model';
import { ConfirmationService } from '@core/services/confirmation.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  @Input() visible = true;
  @Input() showBackButton = false;
  @Input() showHomeButton = false;
  @Input() showChapterHomeButton = false;
  @Input() hasSidenav = false;
  @Input() notifications: Notification[] = [];
  @Input() unreadNotificationsCount = 0;
  @Input() sessionStatus: CourseEnrollment[] = [];
  @Output() menuClicked = new EventEmitter<boolean>();
  @Output() backClicked = new EventEmitter<boolean>();
  @Output() homeClicked = new EventEmitter<boolean>();
  @Output() chapterHomeClicked = new EventEmitter<boolean>();
  @Output() notificationClicked = new EventEmitter<Notification>();
  @Output() allNotificationsRead = new EventEmitter<boolean>();
  @Output() notificationOpened = new EventEmitter<boolean>();
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  public version = packageInfo.version;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private session: SessionService,
    private confirmService: ConfirmationService,
    private router: Router,
    private appConfig: ConfigService,
    private navbarUserTour: NavBarTourService
  ) {}

  isMobile$ = this.breakpointObserver
    .observe(['(max-width: 820px)'])
    .pipe(map((result) => result.matches));

  onClickMenu() {
    this.menuClicked.emit(true);
  }
  onClickBack() {
    this.backClicked.emit(true);
  }

  onClickHome() {
    if (this.isResource) {
      this.confirmService.confirmDialog(
        'End Session',
        'You can start a new session from the dashboard. Are you sure?',
        (next) => {
          if (next) {
            this.homeClicked.emit(true);
          }
        }
      );
    } else {
      this.homeClicked.emit(true);
    }
  }

  onClickChapterHome() {
    this.chapterHomeClicked.emit(true);
  }

  getStudentNames(): string {
    if (this.sessionStatus) {
      const studentList = this.sessionStatus
        .map((student) => `<li>${student.userName}</li>`)
        .join('');
      return `<ul>${studentList}</ul>`;
    }
    return '';
  }

  get userInitials() {
    if (!this.session?.isLoggedIn) return 'A';
    const user = this.session;
    return user?.userFirstName?.charAt(0) || 'A';
  }

  onClickUserTour() {
    this.navbarUserTour.handleUserTour(this.router.url);
  }

  get ssoOrgId() {
    return this.appConfig.readConfig().sso_orgId;
  }

  get organisation() {
    return this.session.organization;
  }

  get showOrgInfo() {
    return this.organisation !== this.ssoOrgId;
  }

  logout() {
    this.confirmService.confirmDialog(
      'Logout',
      'You will be logged out of your account. Are you sure?',
      (next) => {
        if (next) {
          this.session.logout();
        }
      }
    );
  }

  get userRole() {
    return this.session.userRole;
  }

  get isResource() {
    return this.userRole === LoggedInUserRole.RESOURCE;
  }

  get orgName() {
    if (this.session.stateSnapshot.orgDetails.displayName) {
      return this.session.stateSnapshot.orgDetails.displayName;
    } else {
      this.session.getOrgDetails().subscribe((res) => {
        this.session.setOrgDetails(res);
      });
      return this.session.stateSnapshot.orgDetails.displayName;
    }
  }

  handleNotificationClicked(notification: Notification) {
    this.notificationClicked.emit(notification);
  }

  handleMarkAllReadClicked() {
    this.allNotificationsRead.emit(true);
  }
}

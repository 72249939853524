import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from '@shared/components/confirm-dialog/confirm-dialog.component';

@Injectable({ providedIn: 'root' })
export class ConfirmationService {
  constructor(private dialog: MatDialog) {}

  confirmDialog(
    title: string,
    message: string,
    response: CallableFunction,
    confirmText?: string,
    cancelText?: string,
    showCancel?: boolean
  ): void {
    const dialogData = new ConfirmDialogModel(
      title,
      message,
      confirmText,
      cancelText,
      showCancel
    );

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '450px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
      response(dialogResult);
    });
  }
}

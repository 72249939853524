<div class="prompt-container">
  <div *ngIf="data.mobileType === 'android'" color="primary" class="prompt">
    <div class="favicon">
      <img src="/assets/icons/favicon.svg" alt="Litmus icon" />
    </div>
    <div class="right-side">
      You can install Litmus as an app in your homescreen!
      <div class="icon-button-wrapper">
        <button mat-button color="accent" (click)="close()">Not now</button>

        <button mat-raised-button color="accent" (click)="installPwa()">
          Install
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="data.mobileType === 'ios'" class="prompt">
    <button class="close-ios-inv" mat-button color="primary" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
    <div>
      To install this web app on your device tap the Menu button and then 'Add
      to Home screen' button
    </div>
    <div>
      <mat-icon>menu</mat-icon> <mat-icon>navigate_next</mat-icon>
      <mat-icon>add</mat-icon>
    </div>
  </div>
</div>

import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { passwordMatchValidator } from '@core/utils/validator-utils';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-set-password-form',
  templateUrl: './set-password-form.component.html',
  styleUrls: ['./set-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetPasswordFormComponent {
  showPassword = false;
  setPasswordForm: UntypedFormGroup;
  @Input() set errorMessage(message: string) {
    if (this.setPasswordForm && message && message.trim().length) {
      this.setPasswordForm.setErrors({ invalid: true, message });
    }
  }
  @Output() setPasswordClicked = new EventEmitter<{
    password: string;
  }>();

  constructor(private fb: UntypedFormBuilder, private session: SessionService) {
    this.setPasswordForm = this.fb.group(
      {
        newPassword: ['', [Validators.minLength(8)]],
        confirmPassword: ['', [Validators.minLength(8)]],
      },
      { validators: passwordMatchValidator }
    );
  }

  loading$ = this.session.loading$;

  get newPassword() {
    return this.setPasswordForm.get('newPassword') as UntypedFormControl;
  }

  get confirmPassword() {
    return this.setPasswordForm.get('confirmPassword') as UntypedFormControl;
  }

  get newPasswordErrorMessage() {
    if (this.newPassword.hasError('required')) return 'Password is required';
    if (this.newPassword.hasError('minlength'))
      return 'Password must be minimum 8 characters long';
    return '';
  }
  get confirmPasswordErrorMessage() {
    if (this.confirmPassword.hasError('required'))
      return 'Password is required';
    if (this.newPassword.hasError('passwordMatch'))
      return `Passwords don't match`;
    return '';
  }

  get formErrorMessage() {
    if (
      this.setPasswordForm.hasError('invalid') &&
      this.setPasswordForm.hasError('message')
    ) {
      return this.setPasswordForm.errors['message'];
    }
    if (this.setPasswordForm.hasError('passwordMatch'))
      return `Passwords don't match`;
  }

  setPassword() {
    if (!this.setPasswordForm.valid) return;
    this.setPasswordClicked.emit({
      password: this.newPassword.value,
    });
  }
}

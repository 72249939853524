import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiEndpointsService } from '@core/services/api-endpoints.service';
import { ApiHttpService } from '@core/services/api-http.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  subscriptionForm: UntypedFormGroup;
  formSubmitted = false;

  constructor(
    private fb: UntypedFormBuilder,
    private apiEndpointService: ApiEndpointsService,
    private apiService: ApiHttpService,
    private cdn: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscriptionForm = this.fb.group({
      name: [''],
      email: ['', [Validators.required, Validators.email]],
      school: [''],
      designation: [],
      demo: [],
    });
  }

  formSubmit() {
    const endpoint = this.apiEndpointService.createUrlWithPathVariables(
      `genericdata`,
      []
    );

    this.apiService
      .post<any>(endpoint, {
        data: JSON.stringify(this.subscriptionForm.value),
        type: 'Webinar',
      })
      .pipe(map((res) => res))
      .subscribe((res) => {
        this.formSubmitted = true;
        this.cdn.detectChanges();
      });
  }
}

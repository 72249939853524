import { Injectable } from '@angular/core';
import { ApiResponseItemList } from '@core/interfaces/api-response.interface';
import { Notification } from '@libs/notification-view/notification.model';
import { map } from 'rxjs/operators';
import { ApiEndpointsService } from './api-endpoints.service';
import { ApiHttpService } from './api-http.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private apiHttpService: ApiHttpService,
    private apiEndpointService: ApiEndpointsService
  ) {}

  fetchNotifications() {
    const endpoint = this.apiEndpointService.createNotificationUrl();
    return this.apiHttpService.get<Notification[]>(endpoint);
  }

  markAllNotificationsAsRead() {
    const endpoint =
      this.apiEndpointService.createNotificationUrlWithPathVariables(
        'markAsRead'
      );
    return this.apiHttpService.post<any>(endpoint, {}).pipe(map((res) => res));
  }

  markNotificationAsRead(notificationId: string) {
    const endpoint =
      this.apiEndpointService.createNotificationUrlWithPathVariables(
        `${notificationId}`,
        ['markAsRead']
      );
    return this.apiHttpService.post<any>(endpoint, {}).pipe(map((res) => res));
  }
}

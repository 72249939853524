import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  ViewChild,
  Input,
  Output,
  ChangeDetectorRef,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Notification } from '@libs/notification-view/notification.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationMenuComponent {
  _unreadNotificationsCount: number;
  @Input() notifications: Notification[] = [];
  @Input() set unreadNotificationsCount(value: number) {
    this._unreadNotificationsCount = value;
    this.unreadNotificationsCountSubject$.next(value);
    this.cdRef.detectChanges();
  }
  @Output() notificationClicked = new EventEmitter<Notification>();
  @Output() allNotificationsRead = new EventEmitter<boolean>();
  @Output() notificationOpened = new EventEmitter<boolean>();
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  private unreadNotificationsCountSubject$ = new BehaviorSubject<number>(0);
  unreadNotificationsCount$ =
    this.unreadNotificationsCountSubject$.asObservable();

  constructor(private cdRef: ChangeDetectorRef) {}

  get showUnreadNotificationsBadge() {
    return this._unreadNotificationsCount > 0;
  }

  handleNotificationClicked(notification: Notification) {
    this.notificationClicked.emit(notification);
  }

  handleMarkAllReadClicked() {
    this.allNotificationsRead.emit(true);
  }
}

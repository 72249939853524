<div class="navbar" *ngIf="visible" color="primary">
  <ng-container *ngIf="hasSidenav">
    <button
      mat-icon-button
      aria-label="Menu icon"
      *ngIf="isMobile$ | async"
      (click)="onClickMenu()"
    >
      <mat-icon>menu</mat-icon>
    </button>
  </ng-container>
  <button
    mat-icon-button
    matTooltip="Go to Dashboard"
    aria-label="Home icon"
    *ngIf="showHomeButton"
    (click)="onClickHome()"
  >
    <mat-icon class="material-icons-outlined">home</mat-icon>
  </button>
  <button
    mat-icon-button
    matTooltip="Go to Chapter Home"
    aria-label="Chapter Home icon"
    *ngIf="showChapterHomeButton"
    (click)="onClickChapterHome()"
  >
    <mat-icon class="material-icons-outlined">home_work</mat-icon>
  </button>
  <button
    mat-icon-button
    aria-label="Menu icon"
    *ngIf="showBackButton"
    (click)="onClickBack()"
  >
    <mat-icon>arrow_back</mat-icon>
  </button>

  <div class="left-wrapper">
    <div class="logo-full">
      <img src="/assets/images/logo.svg" alt="Litmus icon" />
    </div>
    <div class="favicon">
      <img src="/assets/icons/favicon.svg" alt="Litmus icon" />
    </div>
    <div class="org-info" *ngIf="showOrgInfo">
      <mat-divider vertical></mat-divider>

      <span class="org-name">{{ orgName }}</span>
    </div>
  </div>
  <span class="spacer"></span>
  <button mat-icon-button matTooltip="Logout" (click)="logout()">
    <mat-icon>logout</mat-icon>
  </button>
  <!-- <app-badge-viewer></app-badge-viewer> -->
  <app-session-status
    [sessionStatus]="sessionStatus"
    (homeClicked)="onClickHome()"
  ></app-session-status>
  <app-support-menu (userTour)="onClickUserTour()"></app-support-menu>
  <!-- <app-share-menu></app-share-menu> -->
  <app-notification-menu
    [notifications]="notifications"
    [unreadNotificationsCount]="unreadNotificationsCount"
    (allNotificationsRead)="handleMarkAllReadClicked()"
    (notificationClicked)="handleNotificationClicked($event)"
  >
  </app-notification-menu>
  <app-profile-menu
    [userInitials]="userInitials"
    [version]="version"
    (userTour)="onClickUserTour()"
    (logout)="logout()"
  ></app-profile-menu>
</div>

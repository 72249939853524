import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileMenuComponent {
  @Input() version: string;
  @Input() userInitials: string;
  @Output() logout = new EventEmitter<boolean>();
  @Output() userTour = new EventEmitter<boolean>();
  constructor() {}

  onLogout() {
    this.logout.emit();
  }
}

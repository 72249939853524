import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

interface Options extends Object {
  persist: boolean; //persists data to local storage
  key: string; // use name of module as the key
  showLog?: boolean; //displays logs in development mode
}

export class Store<T> {
  private readonly stateSubject$: BehaviorSubject<T>;
  readonly state$: Observable<T>;
  readonly options: Options | undefined;
  private initialState: T;

  constructor(initialState: T, options?: Options) {
    this.stateSubject$ = new BehaviorSubject<T>(initialState);
    this.state$ = this.stateSubject$.asObservable();
    this.options = options;
    this.initialState = initialState;

    // sync data from local storage
    if (
      this.options &&
      !!this.options.persist &&
      localStorage.getItem(this.keyName)
    ) {
      //fetch and parse data
      const nextState = JSON.parse(localStorage.getItem(this.keyName)) as T;
      this.stateSubject$.next(nextState);
    }

    this.logState(this.keyName, this.snapshot);
  }

  get keyName() {
    return (this.options && this.options.key + '-state') || '';
  }

  get snapshot() {
    return this.stateSubject$.getValue();
  }

  /**
   * Provides an observable of a specific property of a state.
   *
   * Eg if state is {isLoggedIn: false, username: 'test'}
   * getStateSlice(isLoggedIn) will return an observable containing the value of isLoggedIn
   *
   * @param property property of the state whose value needs to be subscribed
   * @returns an observable of the property
   */
  getStateSlice<K extends keyof T>(property: K) {
    return this.state$.pipe(map((state) => state[property]));
  }

  /**
   * Provides the current value of an item in the state.
   *
   * Eg if state is {isLoggedIn: false, username: 'test'}
   * getSnapshotSlice(isLoggedIn) will return the value of isLoggedIn
   *
   * Use the getStateSlice method if it required to keep track of changes in value.
   *
   * @param property property of the state whose value needs to be subscribed
   * @returns the value of the property
   */
  getSnapshotSlice<K extends keyof T>(property: K) {
    return this.snapshot && this.snapshot[property];
  }

  /**
   * updates the state with the provided slice.
   *
   * @param nextState
   */
  setState(nextState: Partial<T>) {
    const updatedState = { ...this.snapshot, ...nextState };
    this.stateSubject$.next({ ...this.snapshot, ...nextState });
    if (this.options && !!this.options.persist) {
      //sync state to local storage
      localStorage.setItem(this.keyName, JSON.stringify(updatedState));
    }

    this.logState(this.keyName, `updated state is ${this.snapshot}`);
  }

  resetState() {
    this.stateSubject$.next(this.initialState);
    if (this.options && !!this.options.persist) {
      //sync state to local storage
      localStorage.removeItem(this.keyName);
    }
  }

  private logState(stateName: string, message: any) {
    if (!environment.production && this.options && this.options.showLog) {
      console.log(
        `%cState updated: Key is ${stateName} and value is ${JSON.stringify(
          message
        )}`,
        'color: blue; font-size: 18px'
      );
    }
  }
}

import { Injectable } from '@angular/core';
import { Store } from 'src/app/store';
import { CognitoUser } from './session.model';

export type SessionState = {
  loggedInUser: CognitoUser | undefined;
  loading: boolean;
  redirectUrl: string;
  orgDetails: any;
};

const initialState: SessionState = {
  loggedInUser: undefined,
  loading: false,
  redirectUrl: '',
  orgDetails: undefined,
};

@Injectable({ providedIn: 'root' })
export class SessionStateService extends Store<SessionState> {
  constructor() {
    super(initialState, { persist: true, key: 'session' });
  }
}

import { Injectable } from '@angular/core';
import { UiService } from '@core/services/ui/ui.service';
import { LoggedInUserRole } from '@features/session/session.model';
import { SessionService } from '@features/session/session.service';
import { ShepherdService } from 'angular-shepherd';

@Injectable({
  providedIn: 'root',
})
export class ChapterContentUserTourService {
  constructor(
    public shepherdService: ShepherdService,
    private uiService: UiService,
    private sessionService: SessionService
  ) {}

  role = LoggedInUserRole;

  isStartTour() {
    let userTourHistory = JSON.parse(localStorage.getItem('userTours'));
    if (userTourHistory) {
      let dashboardObject = userTourHistory.find(
        (o) => o.feature === 'chapter-content'
      );
      if (dashboardObject == null || dashboardObject.completed == false) {
        this.startTour();
      }
    } else {
      this.startTour();
    }
  }

  startTour() {
    if (!this.shepherdService.isActive) {
      const sidenavState = this.uiService.stateSnapshot.sidenavVisible;
      let defaultSteps = {
        classes: 'class-1 class-2',
        scrollTo: true,
        cancelIcon: {
          enabled: true,
        },
      };
      this.shepherdService.modal = true;
      this.uiService.setUserTourVisibility(true);
      this.shepherdService.defaultStepOptions = defaultSteps;
      let teacherSteps = [
        {
          title: 'Chapter Content',
          text: `Once you navigate to a subject, you'll get to the chapter content page!
      <br><br>Here, you can see all the content you've created for this subject conveniently organized by chapters.`,
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',

          buttons: [
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Managing Your Class',
          text: `You can manage your class from this menu list.<br><br>
          <div class="infoText"><i class="material-icons">home</i><p class="headInfo">Dashboard<p></div>You can go back to your dashboard to change to a different class or subject with the dashboard button.
          <br><br>
           <div class="infoText"><i class="material-icons">campaign</i><p class="headInfo">Announcements<p></div>Make announements to the whole class from your announcements page.
          <br><br>
          <div class="infoText"><i class="material-icons">front_hand</i><p class="headInfo">Attendance<p></div>For this class, you can see the attendance status of your students for
          today or take attendance with the attendance button.
          <br><br>
          <div class="infoText"><i class="material-icons">quiz</i><p class="headInfo">Content Bank<p></div>Content Bank helps you to reuse questions and create new questions and flashcards for this subject.
          You can also use the questions to build an offline question paper with an answer key!
          `,
          attachTo: {
            element: '.manageClassPanel',
            on: 'auto' as const,
          },
          showOn() {
            return sidenavState;
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Managing Your Class',
          text: `
          <div class="infoText"><i class="material-icons">grading</i><p class="headInfo">Gradebook<p></div>Grades from assignments and Quizzes automatically get added to the gradebook.
          <br>You can also add grades from tests that you conduct in your classroom
        and export everything in a single click!
       <br><br>
        <div class="infoText"><i class="material-icons">insights</i><p class="headInfo">Insights<p></div>Gain an indepth understanding of questions your students are struggling with on a daily basis
        from even 'stealth' assessments like interactive video or even a game.
          <br>Making Assessment-Informed Instruction (A-i-i) accessible!
        <br><br>
          <div class="infoText"><i class="material-icons">manage_accounts</i><p class="headInfo">Manage Class<p></div>Manage students in your class from here.
        `,
          attachTo: {
            element: '.manageClassPanel',
            on: 'auto' as const,
          },
          showOn() {
            return sidenavState;
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },

        {
          title: 'Chapters',
          text: `Litmus helps keep content organized by chapters.
          <br><br>Click here to expand the chapters list.`,
          attachTo: {
            element: '.chapterHeader',
            on: 'auto' as const,
          },
          showOn() {
            return sidenavState;
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Chapter Completion',
          text: `You can mark that a chapter is completed by clicking the tick icon <i class="material-icons">check_circle</i> next to that chapter<br><br>
           You can mark a chapter as incomplete by clicking the tick icon again.`,

          showOn() {
            return sidenavState;
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action: function () {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Bookmarks',
          text: `To make it easy to organize, you can click to bookmark the chapter you are currently teaching.<br><br>
           Next time you take this class, you will be taken directly to the chapter you bookmarked.`,
          showOn(): boolean {
            const element = document.querySelector(this.attachTo.element);
            return Boolean(element);
          },
          attachTo: {
            element: '.bookmarkBtn',
            on: 'auto' as const,
          },

          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action: function () {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Bookmarks',
          text: `To make it easy to organize, you can click to bookmark <i class="material-icons">bookmark</i> the chapter you are currently teaching.<br><br>
           Next time you take this class, you will be taken directly to the chapter you bookmarked.`,
          showOn(): boolean {
            const element = document.querySelector('.bookmarkBtn');
            return !Boolean(element);
          },

          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action: function () {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Edit Menu',
          text: `To archive content, or change it's title or move it to a different chapter, use the Edit Menu.`,
          attachTo: {
            element: '.editBtn',
            on: 'auto' as const,
          },

          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action: function () {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Lesson Plans',
          text: `Lesson plans allow teachers to plan their lessons ahead of time and organize their content.
          <i class="material-icons">assignment</i> Lesson plans can also be printed to take to your class. <br><br> Lesson plans are not visible to students.`,
          scrollTo: false,

          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action: function () {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },

        {
          title: 'My Content',
          text: `All the different interactive contents you create for your students will be neatly organized in this  <i class="material-icons">book</i> 'My Content' tab.
          <br><br> Students will have a read-only view of the content you share here unless it is hidden from students or in draft mode.`,
          scrollTo: false,

          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action: function () {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },

        {
          title: 'Explore Tab',
          text: `To reduce your burden, Litmus provides lots of content that can be reused in your classroom including entire lesson plans from the  <i class="material-icons">explore</i> explore menu!
          You can also adapt these to your needs once you duplicate them!
          <br><br> This is not visible for students.`,
          scrollTo: false,

          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action: function () {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },

        {
          title: 'Reactions',
          text: `<div class="infoText"><i class="material-icons">thumb_up</i><p class="headInfo">Reactions<p></div> <br><br>How your students feel about the learning content is very important!<br><br>
           They can choose '😎 Easy', '🤔 Medium' or '🤯 Hard' to express their reaction to your content. You can see who reacted how, by clicking on the <i class="material-icons" style="transform: translate(0px, 5px)">thumb_up</i> icon, so you can create the right kind of content for your students.`,

          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action: function () {
                return this.next();
              },
              text: `Next`,
            },
          ],
          id: 'creating',
        },

        {
          title: 'Comments',
          text: `<div class="infoText"><i class="material-icons">comments</i><p class="headInfo">Comments<p></div>  <br><br>Litmus is a social learning platform!<br><br>
           This means your students get to learn as a class, discuss and debate about everything they are learning.
           <br><br> As a teacher, you can add comments to your content, and can moderate comments from students.`,

          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: `Next`,
            },
          ],
          id: 'creating',
        },
        {
          title: 'Add a Social Blackboard',
          text: `What you write on the board is the most important way your students learn!
          <br><br>Click to take a photo of your board to create it into an socially interactive document.`,
          attachTo: {
            element: '.add-photo-btn',
            on: 'auto' as const,
          },

          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action: function () {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Add Content',
          text: `You can create a variety of interactive content for your students like:
          <br><br> <img src="/assets/icons/features/social_document_icon.svg" style="width:35px;height:35px;">
          Social Documents - For students to interact with each other on PPTs and Documents
          <br><br> <img src="/assets/icons/features/interactive_video_icon.svg" style="width:35px;height:35px;">
          Interactive Videos - Liven up videos with your own questions and answers inside a video of your choice!
          <br><br> <img src="/assets/icons/features/quiz_icon.svg" style="width:35px;height:35px;">
           Quiz - An exteremely powerful form of assessment called Confidence Assessments helping accurately understand your student progress!`,
          attachTo: {
            element: '.add-content-btn',
            on: 'auto' as const,
          },

          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action: function () {
                return this.next();
              },
              text: `Next`,
            },
          ],
          id: 'creating',
        },
        {
          title: 'Add Content',
          text: `<img src="/assets/icons/features/quiz_game_icon.svg" style="width:35px;height:35px;">
          Quiz Game - Add Fun to your students' learning with a quiz game to test their knowledge!
          <br><br> <img src="/assets/icons/features/assignment_icon.svg" style="width:35px;height:35px;">
          Assignment - A versatile tool to collect handwritten assignments from students and grade them without having to download.
          <br><br> <img src="/assets/icons/features/simulations_icon.svg" style="width:35px;height:35px;">
          Simulations - Over 100 interactive simulations to bring concepts to life!.
          <br><br> <img src="/assets/icons/features/flashcards_icon.svg" style="width:35px;height:35px;">
          Flashcards - Create a flashcard set to help your students remember important information. Questions from your questionbank can be added to your flashcard set.


          <br><br><br><br>And Many More Coming Soon!!!`,
          attachTo: {
            element: '.add-content-btn',
            on: 'auto' as const,
          },

          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action: function () {
                return this.next();
              },
              text: `Next`,
            },
          ],
          id: 'creating',
        },
        {
          title: `Let's Get Started!`,
          text: `You are ready to start creating content!<br><br>

          Create your first content today within a few clicks!!!`,
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',

          buttons: [
            {
              action() {
                return this.next();
              },
              text: `Let's Go!`,
            },
          ],
          id: 'creating',
        },
      ];
      let studentSteps = [
        {
          title: 'Chapter Content',
          text: `Once you navigate to a subject, you'll get to the chapter content page!
      <br><br>Here, you can see all the content your teachers created for this subject conveniently organized by chapters.`,
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',

          buttons: [
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },

        {
          title: 'Dashboard',
          text: `<div class="infoText"><i class="material-icons">home</i><p class="headInfo">Dashboard<p></div> You can go back to your dashboard to change to a different class or subject with this button.`,

          showOn() {
            return sidenavState;
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Chapters',
          text: `Litmus helps keep content organized by chapters so you'll know exactly where to find the right content.`,

          showOn() {
            return sidenavState;
          },

          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'General Content',
          text: `Not all content will be related to only one chapter.<br><br><br>
           If content is related to multiple chapters like Monthly tests or Revision Exams, teachers might create it in General Content.`,

          showOn() {
            return sidenavState;
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action: function () {
                return this.next();
              },
              text: `Next`,
            },
          ],
          id: 'creating',
        },
        {
          title: 'Reactions',
          text: `<div class="infoText"><i class="material-icons">thumb_up</i><p class="headInfo">Reactions<p></div> <br><br> How you feel about the learning content is very important!<br><br>
           You can choose '😎 Easy', '🤔 Medium' or '🤯 Hard' to express your reaction that will help teachers create the right kind of content for you.`,

          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action: function () {
                return this.next();
              },
              text: `Next`,
            },
          ],
          id: 'creating',
        },
        {
          title: 'Comments',
          text: `<div class="infoText"><i class="material-icons">comments</i><p class="headInfo">Comments<p></div> <br><br> Litmus is a social learning platform!<br><br>
           This means you get to learn as a class, discuss and debate about everything you are learning.
           <br><br><br> Click on a comment to see how you can add comments even anonymously.`,

          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Anonymous Comments',
          text: `Litmus is a safe and inclusive platform!<br><br>
           When commenting, you can add it as an anonymous comment.
           This means your name will not be visible to your classmates,
           but will still be able to see your comment.<br><br>
           Remember, your name will always be visible to your teacher.`,
          attachTo: {
            element: '.anonymous',
            on: 'auto' as const,
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: `Let's Go`,
            },
          ],
          id: 'creating',
        },
      ];

      //     showOn() {
      //   return document.querySelector('.king-claudius') ? true : false;
      // }
      if (this.isTeacher) {
        // this.shepherdService.addSteps(teacherSteps);
      }
      if (this.isStudent) {
        // this.shepherdService.addSteps(studentSteps);
      }
      this.shepherdService.modal = true;
      this.shepherdService.confirmCancel = false;
      this.shepherdService.start();
      this.shepherdService.tourObject.on('cancel', () => {
        this.userTourFinished();
      });
      this.shepherdService.tourObject.on('complete', () => {
        this.userTourFinished();
      });
    }
  }

  get isTeacher() {
    return (
      this.userRole === this.role.TEACHER || this.userRole === this.role.ADMIN
    );
  }

  get isStudent() {
    return this.userRole === this.role.STUDENT;
  }

  get userRole() {
    return this.sessionService.userRole;
  }

  userTourFinished() {
    let currentFeature = 'chapter-content';
    this.uiService.setUserTourVisibility(false);
    let existingEntries = JSON.parse(localStorage.getItem('userTours'));
    if (!existingEntries) {
      existingEntries = [];
    }
    let entry = {
      feature: currentFeature,
      completed: true,
    };
    if (
      existingEntries.filter((e) => e.feature === currentFeature).length === 0
    ) {
      // Save allEntries back to local storage
      existingEntries.push(entry);
    } else {
      existingEntries.filter((e) => e.feature === currentFeature)[0].completed =
        true;
    }
    localStorage.setItem('userTours', JSON.stringify(existingEntries));
  }
}

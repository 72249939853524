<div class="container py-5" *ngIf="!formSubmitted">
  <div class="row d-flex justify-content-center align-items-center h-100">
    <div class="col-xl-10">
      <div class="card rounded-3 text-black">
        <div class="row g-0">
          <div class="col-lg-6">
            <div class="card-body p-md-5 mx-md-4">
              <div class="text-center mb-4">
                <img src="/assets/images/logo.svg" alt="Litmus logo" />
              </div>
              <h4 class="text-center mt-1 mb-3 pb-1">
                Watch the launch webinar!
              </h4>
              <form
                *ngIf="subscriptionForm"
                [formGroup]="subscriptionForm"
                (ngSubmit)="formSubmit()"
              >
                <div class="form-outline mb-2">
                  <mat-form-field floatLabel="always" class="full-width">
                    <mat-label>Name</mat-label>
                    <input required formControlName="name" matInput />
                  </mat-form-field>
                </div>

                <div class="form-outline mb-2">
                  <mat-form-field floatLabel="always" class="full-width">
                    <mat-label>Email</mat-label>
                    <input required formControlName="email" matInput />
                  </mat-form-field>
                </div>

                <div class="form-outline mb-2">
                  <mat-form-field floatLabel="always" class="full-width">
                    <mat-label>School Name</mat-label>
                    <input required formControlName="school" matInput />
                  </mat-form-field>
                </div>

                <div class="form-outline mb-2">
                  <mat-form-field floatLabel="always" class="full-width">
                    <mat-label>Designation</mat-label>
                    <input required formControlName="designation" matInput />
                  </mat-form-field>
                </div>

                <div
                  class="d-flex align-items-center justify-content-center pb-4"
                >
                  <p class="me-2">
                    <mat-checkbox formControlName="demo"></mat-checkbox>
                  </p>
                  <p class="p-text">
                    Would you like a 1:1 demo of the platform?
                  </p>
                </div>

                <div
                  class="d-flex align-items-center justify-content-center pb-4"
                >
                  <button
                    type="submit"
                    [disabled]="subscriptionForm.invalid"
                    class="btn btn-outline-success"
                  >
                    Watch the webinar!
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-6 d-flex align-items-center gradient-custom-2">
            <div class="text-info px-3 py-4 p-md-5 mx-md-4">
              <h4 class="mb-4">What do you get from the webinar?</h4>
              <p class="small mb-0">
                Litmus Learn is an interactive blended learning platform helping
                schools usher in the future of learning in their schools with a
                focus on interactive learning with 21st century skills and
                development of students.
                <strong
                  >With large learning gaps from the pandemic on one side and
                  losing more than 50% of share of wallet on the other
                  side</strong
                >
                ,schools are in need of a strong partner to support the recovery
                and elevate their value. <br /><br />That technology partner is
                Litmus Learn! <br /><br /><br />
                The webinar, hosted by our CEO, will walk you through the Litmus
                blended learning platform and how it can be used to support your
                student's learning needs while enhancing the value of your
                school in the eyes of parents.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="formSubmitted">
  <div class="vidPlayer">
    <div class="video-container">
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/F4QDoKsLyyg"
        title="Litmus Learn Launch Webinar"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</ng-container>
<div class="waves">
  <svg viewBox="0 0 1516 279" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)" fill="#cb4242">
      <path
        d="M0 278.958V43.68C54.268 59.974 108.536 76.27 188.447 73.09c79.912-3.178 185.467-25.829 258.984-27.645 73.516-1.816 114.992 17.203 171.602 14.117 56.61-3.087 128.353-28.279 208.45-21.764 80.098 6.516 168.549 44.738 251.617 37.645 83.06-7.093 160.74-59.502 232.66-72.348 71.92-12.846 138.08 13.87 204.24 40.586v235.277H0z"
        fill-opacity=".5"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h1516v279H0z" />
      </clipPath>
    </defs>
  </svg>
</div>

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { RouterService } from '@core/services/router.service';
import { UiService } from '@core/services/ui/ui.service';
import { Subject, of, merge, BehaviorSubject } from 'rxjs';
import {
  catchError,
  map,
  share,
  startWith,
  switchMap,
  filter,
  delay,
  tap,
} from 'rxjs/operators';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from '@core/services/notification.service';
import { SessionService } from '@features/session/session.service';
import {
  Notification,
  NOTIFICATION_TYPE,
} from '@libs/notification-view/notification.model';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { encodeBase64 } from '@core/utils/helper';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { LoggedInUserRole } from '@features/session/session.model';
import { DashboardDataService } from '@features/dashboard/dashboard-data.service';
import { DashboardService } from '@features/dashboard/dashboard.service';
import { PwaService } from './pwa.service';
import { Platform } from '@angular/cdk/platform';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RouterService],
  animations: [
    trigger('inOutAnimation', [
      state(
        'open',
        style({
          width: 'max(20vw, 300px)',
        })
      ),
      state(
        'closed',
        style({
          width: '50px',
        })
      ),
      transition('* => closed', [animate('0.5s')]),
      transition('* => open', [animate('0.5s')]),
    ]),
  ],
})
export class AppComponent implements OnDestroy {
  private collapseTimer: any;
  // public promptEvent;

  // @HostListener('window:beforeinstallprompt', ['$event'])
  // onbeforeinstallprompt(e) {
  //   // console.log(e);
  //   e.preventDefault();
  //   this.promptEvent = e;
  // }

  // public installPWA() {
  //   this.promptEvent.prompt();
  // }

  // public shouldInstall(): boolean {
  //   return !this.isRunningStandalone() && this.promptEvent;
  // }

  // public isRunningStandalone(): boolean {
  //   return window.matchMedia('(display-mode: standalone)').matches;
  // }
  constructor(
    private routerService: RouterService,
    private uiService: UiService,
    private breakpointObserver: BreakpointObserver,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private notificationService: NotificationService,
    private session: SessionService,
    private dashboardDataService: DashboardDataService,
    private dashboardService: DashboardService,
    private router: Router,
    public pwa: PwaService
  ) {
    this.routerService.initRouterEvents();
    this.matIconRegistry.addSvgIcon(
      'qbank',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/images/ic_qbank_v1.svg'
      )
    );
    this.checkBrowserRefreshEvent();
    this.routerService.routeUrlChanged$.subscribe();
  }

  notifier = new Subject();
  animationComplete = true;

  isNavbarVisible$ = this.uiService.navbarVisibility$.pipe(share());
  isAllowCollapsible$ = this.uiService.allowCollapse$.pipe(share());
  isSidenavVisible$ = this.uiService.sidenavVisibility$.pipe(share());
  isNavbarCollapsible$ = this.uiService.sidenavisCollapsible$.pipe(share());
  isNavbarCollapsed$ = this.uiService.currentCollapsedState$.pipe(share());
  hasSidenav$ = this.uiService.hasSidenav$;
  showExit$ = this.uiService.showExit$;
  showHome$ = this.uiService.showHome$;
  showChapterHome$ = this.uiService.showChapterHome$;
  sessionStatus$ = this.uiService.sessionStatus$;
  customComponent$ = this.uiService.sidenavContent$;

  private notificationOpenActionSubject$ = new Subject<boolean>();
  notificationOpenAction$ = this.notificationOpenActionSubject$
    .asObservable()
    .pipe(switchMap((open) => this.notificationService.fetchNotifications()));

  private currentNotificationsSubject$ = new BehaviorSubject<Notification[]>(
    []
  );
  currentNotifications$ = this.currentNotificationsSubject$.asObservable();

  fetchNotificationsOnRouteChange$ = this.routerService.routeUrlChanged$.pipe(
    filter((url) => this.urlMatchesDashboard(url)),
    switchMap(() => this.notificationService.fetchNotifications()),
    tap((data) => {
      this.currentNotificationsSubject$.next(data);
    }),
    share()
  );

  urlMatchesDashboard(url: string) {
    return url.includes('dashboard');
  }

  notifications$ = merge(
    this.fetchNotificationsOnRouteChange$,
    this.notificationOpenAction$,
    this.currentNotifications$
  ).pipe(share());

  unreadNotificationsCount$ = this.notifications$
    .pipe(startWith([]))
    .pipe(map((notifications) => notifications.filter((n) => !n.read).length));

  isMobile$ = this.breakpointObserver
    .observe(['(max-width: 820px)'])
    .pipe(map((result) => result.matches));

  get isLoggedIn() {
    return this.session.isLoggedIn;
  }

  handleToggleMenu() {
    const sidenavState = this.uiService.stateSnapshot.sidenavVisible;
    this.uiService.setSidenavVisibility(!sidenavState);
  }

  hideMenu(isMobile) {
    if (isMobile) {
      this.uiService.setSidenavVisibility(false);
    }
  }

  onSinenavToggle(iscollapsible, iscollapsed, allowCollapse, isMobile) {
    if (!isMobile && allowCollapse && iscollapsible) {
      clearTimeout(this.collapseTimer); // Clear any existing timeout

      if (this.animationComplete) {
        if (!iscollapsed) {
          this.collapseTimer = setTimeout(() => {
            this.uiService.setNavbarCollapsed(!iscollapsed);
          }, 2000); // Set a timeout of 2 seconds before collapsing
        } else {
          this.uiService.setNavbarCollapsed(!iscollapsed); // Open immediately
        }
      } else {
        if (!iscollapsed) {
          //close after animation done.
          setTimeout(() => {
            this.uiService.setNavbarCollapsed(true);
          }, 2000);
        }
      }
    }
  }
  onSinenavEnter(iscollapsible, iscollapsed, allowCollapse, isMobile) {
    if (!isMobile && allowCollapse && iscollapsible) {
      clearTimeout(this.collapseTimer);
      this.uiService.setNavbarCollapsed(false);
    }
  }
  animationStart(animationEvent) {
    this.animationComplete = false;
  }
  animationDone(animationEvent) {
    this.animationComplete = true;
  }

  navigateToPreviousPage() {
    if (!this.session.isLoggedIn) return;
    const prevPage = this.uiService.stateSnapshot.previousPage;
    try {
      this.router.navigateByUrl(prevPage);
    } catch (error) {
      this.navigateToChapterHome();
    }
  }

  navigateToChapterHome() {
    this.uiService.backToMyContent();
  }

  navigateToDashboard() {
    this.uiService.navigateToHome();
  }

  handleReadAllNotifications() {
    this.notificationService.markAllNotificationsAsRead().subscribe((next) => {
      const updatedNotifications = this.currentNotificationsSubject$.value.map(
        (data) => {
          data.read = true;
          return data;
        }
      );
      this.currentNotificationsSubject$.next(updatedNotifications);
    });
  }

  handleNotificationClicked(notification: Notification) {
    this.notificationService
      .markNotificationAsRead(notification.notificationId)
      .subscribe((next) => {
        notification.read = true;
        const updatedNotifications =
          this.currentNotificationsSubject$.value.map((data) =>
            data.notificationId === notification.notificationId
              ? notification
              : data
          );
        this.currentNotificationsSubject$.next(updatedNotifications);
        this.navigateToContent(notification);
      });
  }

  handleNotificationOpened() {
    this.notificationOpenActionSubject$.next(true);
  }

  navigateToContent(notification: Notification) {
    switch (notification.notificationType) {
      case 'SOCIAL_DOC_COMMENT':
        this.router.navigate(['/social-document/viewer'], {
          queryParams: {
            documentId: notification?.documentId,
            pageNo: notification?.pageNo,
            annotationId: notification?.annotationId,
          },
        });

        break;
      // case 'ASSESSMENT_FEEDBACK_PUBLISH':
      //   this.router.navigate([
      //     `/dashboard/assessmentfeedbacks/${notification.courseId}/${notification.subjectId}/list`,
      //   ]);
      default:
        this.navigateToChapterContent(notification);
    }
  }

  navigateToChapterContent(notification: Notification) {
    let courseIdEn = encodeURIComponent(
      encodeBase64(notification.courseId.toString())
    );
    let subjectIdEn = encodeURIComponent(
      encodeBase64(notification.subjectId.toString())
    );
    let chapterIdEn = encodeURIComponent(
      encodeBase64(notification.chapterId.toString())
    );

    this.dashboardDataService
      .getChapterListByCourseAndSubject(
        notification.courseId,
        notification.subjectId
      )
      .subscribe((data) => {
        this.dashboardService.setCurrentChapterContent(data);
      });

    this.router.navigate(
      [
        `/dashboard/chapter-content/${courseIdEn}/${subjectIdEn}/${chapterIdEn}`,
      ],
      {
        queryParams: {
          ccid: notification.courseContentId,
          viewMode: 'myContent',
        },
      }
    );
  }

  checkBrowserRefreshEvent() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !this.router.navigated;
      }
    });
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}

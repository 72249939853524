import {
  APP_INITIALIZER,
  ErrorHandler,
  Injectable,
  NgModule,
} from '@angular/core';
import {
  BrowserModule,
  HammerModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
} from '@angular/platform-browser';
import * as Hammer from 'hammerjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

/* Add Amplify imports */
import { Amplify } from 'aws-amplify';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NotFoundComponent } from '@features/not-found/not-found.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LandingModule } from '@features/landing/landing.module';
import { ConfigService } from '@core/services/config.service';
import { GlobalErrorHandler } from '@core/global-error-handler';
import { FeatureFlagService } from '@libs/feature-flags/feature-flag.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { SharedModule } from '@shared/shared.module';
import { EquationEditorComponent } from './libs/equation-editor/equation-editor.component';
import { HtmlEditorModule } from '@libs/html-editor/html-editor.module';
import { PwaService } from './pwa.service';
import { PromptComponentComponent } from './prompt-component/prompt-component.component';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const labConfig = {
  aws_project_region: 'ap-south-1',
  aws_cognito_region: 'ap-south-1',
  aws_user_pools_id: 'ap-south-1_7HYWqega3',
  aws_user_pools_web_client_id: '43sbiu3ros6449569nbn3j3thf',
  sso_orgId: 20,
  af_orgs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
  other_boardid: 10,
  demo_subjectId: 140,
  other_classid: 89,
  homeUrl: 'https://lab.litmuslearn.com',
  oauth: {
    domain: 'lab-auth.litmuslearn.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'https://lab-app.litmuslearn.com',
    redirectSignOut: 'https://lab-app.litmuslearn.com',
    responseType: 'code',
  },
};

const prodConfig = {
  aws_project_region: 'ap-south-1',
  aws_cognito_region: 'ap-south-1',
  aws_user_pools_id: 'ap-south-1_N7vpHuCbe',
  aws_user_pools_web_client_id: '5rnmdohp2ah7gmqgieml7drdjs',
  sso_orgId: 9,
  af_orgs: [11, 18],
  other_boardid: 10,
  demo_subjectId: 164,
  other_classid: 19,
  homeUrl: 'https://litmuslearn.com',
  oauth: {
    domain: 'auth.litmuslearn.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'https://app.litmuslearn.com',
    redirectSignOut: 'https://app.litmuslearn.com',
    responseType: 'code',
  },
};

const localConfig = {
  aws_project_region: 'ap-south-1',
  aws_cognito_region: 'ap-south-1',
  aws_user_pools_id: 'ap-south-1_7HYWqega3',
  aws_user_pools_web_client_id: '43sbiu3ros6449569nbn3j3thf',
  sso_orgId: 20,
  af_orgs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
  other_boardid: 10,
  demo_subjectId: 140,
  other_classid: 10,
  oauth: {
    domain: 'lab-auth.litmuslearn.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'http://localhost:4200',
    redirectSignOut: 'http://localhost:4200',
    responseType: 'code',
  },
};

const isLab = window.origin === 'https://lab-app.litmuslearn.com';
const isProd = window.origin === 'https://app.litmuslearn.com';
// const initializer = (pwaService: PwaService) => () =>
//   pwaService.initPwaPrompt();

const appInitializerFn = (configService: ConfigService) => {
  return async () => {
    const config = await configService.setConfig();

    if (isLab) {
      Amplify.configure(labConfig);
    } else if (isProd) {
      Amplify.configure(prodConfig);
    } else {
      Amplify.configure(localConfig);
    }

    return config;
  };
};

const featureFactory = (featureFlagsService: FeatureFlagService) => () =>
  featureFlagsService.loadConfig();

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement) {
    return new Hammer.Manager(element, {
      touchAction: 'auto',
      inputClass: Hammer.TouchInput,
      recognizers: [
        [
          Hammer.Swipe,
          {
            direction: Hammer.DIRECTION_ALL,
          },
        ],
      ],
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    EquationEditorComponent,
    PromptComponentComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    HtmlEditorModule,
    FormsModule,
    ReactiveFormsModule,
    HammerModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    LandingModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: featureFactory,
      deps: [FeatureFlagService],
      multi: true,
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializer,
    //   deps: [PwaService],
    //   multi: true,
    // },

    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from '@angular/core';
import { UiService } from '@core/services/ui/ui.service';
import { LoggedInUserRole } from '@features/session/session.model';
import { SessionService } from '@features/session/session.service';
import { ShepherdService } from 'angular-shepherd';

@Injectable({
  providedIn: 'root',
})
export class DashboardUserTourService {
  constructor(
    public shepherdService: ShepherdService,
    private uiService: UiService,
    private sessionService: SessionService
  ) {}

  role = LoggedInUserRole;

  startTour() {
    if (!this.shepherdService.isActive) {
      const sidenavState = this.uiService.stateSnapshot.sidenavVisible;
      let defaultSteps = {
        classes: 'class-1 class-2',
        scrollTo: true,
        cancelIcon: {
          enabled: true,
        },
      };

      this.shepherdService.modal = true;
      this.shepherdService.defaultStepOptions = defaultSteps;
      let teacherSteps = [
        {
          title: 'Welcome!',
          text: `Welcome to the first step in helping your students succeed!
      <br><br>Within a few clicks, Litmus helps you create a variety of interactive learning experiences for your students. This tour will help you get started.`,
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',

          buttons: [
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Subjects',
          text: `You will see a list of subjects that you are enrolled in. Click on a subject to start teaching.`,
          attachTo: {
            element: '.subject-list',
            on: 'auto' as const,
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Add Classes',
          text: `You can add classes by clicking on the 'Add Class' button.
           You can choose your syllabus, class, subject and section from the dropdown menus.
           <br><br>If you do not find the right subject or syllabus, reach out to us at contact@litmuslearn.com and we will help you set it up.
          `,
          attachTo: {
            element: '.add-class-btn',
            on: 'bottom' as const,
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Tutorials',
          text: `Click the support icon <i class="material-icons">help</i> on top to see a variety of support tools including "How to use Litmus". In here, you will find quick 1 minute videos on how to use all the different tools in Litmus to create interactive experiences for your students.`,
          attachTo: {
            element: '.support-icon',
            on: 'auto' as const,
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Share with other Teachers',
          text: `We hope to help reduce your burden while helping you create unforgetable learning experience for your students.<br><br> If you find Litmus helpful, your peers and colleagues might as well. Use this link to share an invite.
          `,
          attachTo: {
            element: '.share-us',
            on: 'bottom' as const,
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Profile',
          text: `Your Profile is where you can safely signout.
          `,
          attachTo: {
            element: '.profile-avatar',
            on: 'bottom' as const,
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action: function () {
                return this.complete();
              },
              text: 'Start Teaching!',
            },
          ],
          id: 'creating',
        },
      ];
      let studentSteps = [
        {
          title: 'Welcome!',
          text: `Welcome to the first step in a interactive, fun, learning experience!
      <br><br>This tour will help you get started.`,
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',

          buttons: [
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Subjects',
          text: `You will see a list of subjects that you are enrolled in. Click on a subject to start learning.`,
          attachTo: {
            element: '.subject-list',
            on: 'auto' as const,
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },

        {
          title: 'Profile',
          text: `Your Profile is where you can safely signout.<br><br> You can always revisit these tours at any time by clicking on the 'map' button in this menu.
        `,
          attachTo: {
            element: '.profile-avatar',
            on: 'bottom' as const,
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Share with other Students',
          text: `We hope to make your learning experience fun and engaging.<br><br> If you find Litmus helpful, your friends might as well! Use this link to share an invite.
          `,
          attachTo: {
            element: '.share-us',
            on: 'bottom' as const,
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Contact Us',
          text: `We are here to support your on your learning quest!<br><br> Please email us if you have any questions or suggestions on how to improve.
          `,
          attachTo: {
            element: '.contact-us',
            on: 'bottom' as const,
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },

            {
              action: function () {
                return this.complete();
              },
              text: 'Start Learning!',
            },
          ],
          id: 'creating',
        },
      ];
      if (this.isTeacher) {
        // this.shepherdService.addSteps(teacherSteps);
      }
      if (this.isStudent) {
        // this.shepherdService.addSteps(studentSteps);
      }
      this.shepherdService.modal = true;
      this.shepherdService.confirmCancel = false;
      this.shepherdService.start();
      this.shepherdService.tourObject.on('cancel', () => {
        this.userTourFinished();
      });
      this.shepherdService.tourObject.on('complete', () => {
        this.userTourFinished();
      });
    }
  }

  get isTeacher() {
    return (
      this.userRole === this.role.TEACHER || this.userRole === this.role.ADMIN
    );
  }
  get isStudent() {
    return (
      this.userRole === this.role.STUDENT ||
      this.userRole === this.role.RESOURCE
    );
  }

  get userRole() {
    return this.sessionService.userRole;
  }

  userTourFinished() {
    let currentFeature = 'dashboard';
    let existingEntries = JSON.parse(localStorage.getItem('userTours'));
    if (!existingEntries) {
      existingEntries = [];
    }
    let entry = {
      feature: currentFeature,
      completed: true,
    };
    if (
      existingEntries.filter((e) => e.feature === currentFeature).length === 0
    ) {
      // Save allEntries back to local storage
      existingEntries.push(entry);
    } else {
      existingEntries.filter((e) => e.feature === currentFeature)[0].completed =
        true;
    }
    localStorage.setItem('userTours', JSON.stringify(existingEntries));
  }
}

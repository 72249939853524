import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-privacy',
  template: `
    <div class="privacy-wrapper">
      <mat-card appearance="outlined" class="privacy-card">
        <mat-card-title>Privacy Policy - Litmus Learn</mat-card-title>
        <div>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >This privacy policy (the &ldquo;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Privacy Policy</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&rdquo;, &ldquo;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Policy</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&rdquo;) applies to the Litmus Learn website, the Litmus Learn
              mobile applications, services and the content created/curated,
              owned and operated (collectively called the &ldquo;Litmus Learn
              Materials&rdquo;) by &ldquo;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >DHRONA&nbsp;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >EDTECH PRIVATE LIMITED&rdquo;, (hereinafter &ldquo;Litmus
              Learn&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo; or
              &ldquo;our&rdquo;). This policy does not apply to websites or
              services or practices of companies that Litmus Learn does not own
              or control, such as third-party services you might access through
              various links or other features provided on our
              application/service/product. These other third party services have
              their own privacy policies.&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >This policy has been created to tell the Litmus Learn users
              (hereinafter referred to as &ldquo;user&rdquo;, &ldquo;you&rdquo;
              or &ldquo;your&rdquo;) what information the Litmus Learn Materials
              collect, how we use that information, and who we share or will do
              with that information with, if at all. Where our privacy practices
              for Student Litmus Learn Users differ from our practices compared
              to those of other Litmus Learn Users (defined below), we mention
              it separately.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >In case of Student Litmus Learn users below the age 18 years,
              consent from the parent/ legal guardian is required and it is
              advised that you read the policy carefully. &nbsp;</span
            >
          </p>
          <h1
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
          >
            <span
              style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >INTRODUCTION</span
            >
          </h1>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >AT Litmus Learn our mission is to help students and teachers
              engage with each other through various Information Technology
              tools including the power of Interactive video learning, Quizzes
              and Quiz games. &nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >We empower teachers and institutions to decide how students
              engage with Litmus Learn Material. The teachers will be able to
              create their own independent content using our tools. Litmus Learn
              will provide recommendations based on content Litmus Learn has
              created/curated and also publicly available content for which
              Litmus Learn claims no ownership or copyright. We at Litmus Learn
              take due care and perform due diligence to ensure privacy aspects
              of each and every individual who uses Litmus Learn Material and
              Services, in alignment with all applicable laws, rules and
              regulations. Earning your trust by ensuring your privacy and
              safety is therefore extremely important to us.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >WE value your trust and in order to honour that trust, Litmus
              Learn adheres not only to legal standards but also to ethical and
              moral standards in collecting, gathering, using and safeguarding
              any information you provide.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Your privacy is extremely important to us and therefore, we take
              great care to limit what we collect and how we use it, and to help
              you understand our policies. Please read our Terms and Conditions
              and Privacy Policy, which explains how we treat your personal
              information and protect your privacy when you use our
              application/Services/products.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >BY USING OUR APPLICATION/SERVICES/PRODUCT, YOU ACKNOWLEDGE THAT
              YOU ACCEPT AND AGREE TO THIS PRIVACY POLICY.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Additionally, you also agree that Litmus Learn can use such data
              in accordance with our Privacy Policy.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >This Policy has been drafted in accordance with the applicable
              Data Protection Laws, Rules and Regulations for the time being in
              force but is not limited to the Information Technology Act, 2000
              (</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&ldquo;IT Act&rdquo;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >), the Information Technology (Reasonable Security Practices and
              Procedures And Sensitive Personal Data or Information) Rules, 2011
              (</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&ldquo;IT Rules&rdquo;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >) and Information Technology (Intermediaries guidelines) Rules,
              2011, collectively referred to as &ldquo;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Data Protection Laws</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&rdquo;.&nbsp;</span
            >
          </p>
          <h1
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:12pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
          >
            <span
              style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >COMPLIANCE WITH PRIVACY LAWS</span
            >
          </h1>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Litmus Learn complies with the provisions of the Information
              Technology Act, 2000 (</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&ldquo;IT Act&rdquo;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >) and the Information Technology (Reasonable Security Practices
              and Procedures And Sensitive Personal Data or Information) Rules,
              2011 (</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&ldquo;IT Rules&rdquo;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >), Information Technology (Intermediaries guidelines) Rules,
              2011, collectively referred to as &ldquo;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Data Protection Laws</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&rdquo;, regarding collection, use, retention, processing and
              handling of personal information and all other applicable laws for
              the time being in force.</span
            >
          </p>
          <h1
            dir="ltr"
            style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
          >
            <span
              style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >GOVERNING LAW</span
            >
          </h1>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:15pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >This Privacy Policy forms part of our Terms and Conditions and is
              governed in accordance with the laws in India.&nbsp;</span
            ><span
              style="font-size:12pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >The materials contained in Litmus Learn Materials are protected
              by applicable copyright and trademark law.</span
            >
          </p>
          <h1
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
          >
            <span
              style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >DEFINITIONS</span
            >
          </h1>
          <p
            dir="ltr"
            style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >For purposes of this Privacy Policy, the following definitions
              apply:&nbsp;</span
            >
          </p>
          <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
              >
                <span
                  style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&ldquo;</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >Litmus Learn Materials&rdquo;</span
                ><span
                  style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&nbsp;</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >means and includes Litmus Learn website, the Litmus Learn
                  mobile applications and the content created/curated, owned and
                  operated by Litmus Learn.</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
              >
                <span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&ldquo;Litmus Learn User&rdquo;&nbsp;</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >includes Institutions, Teacher Litmus Learn Users, Student
                  Litmus Learn Users. It also includes parents/legal guardians
                  of the student Litmus Learn Users.&nbsp;</span
                ><span
                  style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&nbsp;&nbsp;</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
              >
                <span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&quot;</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >Institution</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&quot; means a school or an educational institution that
                  enters into a contract with Litmus Learn and adopts/ uses the
                  Litmus Learn Materials.</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
              >
                <span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&quot;</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >Personal Information</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&quot; means personal information of a particular User as
                  defined by applicable data protection law. (Information
                  Technology Act, 2000), Information Technology (Reasonable
                  security practices and procedures and sensitive personal data
                  or information) Rules, 2011 (the &ldquo;Data Protection
                  Rules)&rdquo;. &nbsp;</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
              >
                <span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&ldquo;School&rdquo;</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&nbsp;means and includes Secondary Schools and Higher
                  Secondary Schools imparting education or training and
                  affiliated to or recognized by or registered with Central
                  Board of School Education (CBSE) or The Indian Certificate of
                  Secondary Education (ICSE) or various State Recognized Boards
                  in India.&nbsp;</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
              >
                <span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&quot;</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >Sensitive Personal Data or Information</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&quot; means sensitive personal data or information of a
                  particular User as defined by applicable data protection law.
                  (Information Technology Act, 2000), Information Technology
                  (Reasonable security practices and procedures and sensitive
                  personal data or information) Rules, 2011 (the &ldquo;Data
                  Protection Rules)&rdquo;.</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
              >
                <span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&ldquo;Student Litmus Learn User&rdquo;</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&nbsp;is a user who is the student of an institution (as
                  defined under clause 3 of definitions) and has a valid Student
                  Litmus Learn users account and accesses the participation
                  portion of the Litmus Learn Materials provided by the Teacher
                  Litmus Learn user or by Litmus Learn and accessing and using
                  the Litmus Learn Materials and/or &nbsp;Services.&nbsp;</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
              >
                <span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&ldquo;Services / Litmus Learn Services&rdquo;&nbsp;</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >means and includes</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&nbsp;</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >Litmus Learn website, the Litmus Learn mobile applications or
                  any associated applications, technology, products or services
                  the company may provide now or in future.&nbsp;</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
              >
                <span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&ldquo;</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >Teacher Litmus Learn User&rdquo;</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&nbsp;A Teacher Litmus Learn User is a single, named, person
                  of legal age who is employed in an institution(as defined
                  under clause 3 of definitions) and has a legally binding
                  contract with the Institution and has a valid Teacher Litmus
                  Learn user account and who is not a student Litmus Learn
                  user.</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
              >
                <span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&ldquo;</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >Terms and Conditions</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&rdquo; mean the Litmus Learn Terms and Conditions for the
                  Litmus Learn Materials and/or Services.</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:12pt;"
              >
                <span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&ldquo;</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >User&rdquo;</span
                ><span
                  style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >&nbsp;means an end-user of the Litmus Learn Materials and/or
                  Services.&nbsp;</span
                >
              </p>
            </li>
          </ol>
          <h1
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
          >
            <span
              style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >WHY DOES Litmus Learn COLLECT PERSONAL INFORMATION FROM ITS USERS
              ?</span
            >
          </h1>
          <p
            dir="ltr"
            style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Litmus Learn</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;collects personal information from users in order to
              provide the Service. Concretely, the personal information of
              Litmus Learn users is collected and used for the following
              purposes:</span
            >
          </p>
          <ul style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:10pt;font-family:'Noto Sans Symbols',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.7999999999999998;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
              >
                <span
                  style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >To create the necessary accounts to use the Litmus Learn
                  Materials.</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:10pt;font-family:'Noto Sans Symbols',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.7999999999999998;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
              >
                <span
                  style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >To help personalize the experience for Litmus Learn
                  users.&nbsp;</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:10pt;font-family:'Noto Sans Symbols',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.7999999999999998;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
              >
                <span
                  style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >To provide teachers with analytics on student progress.</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:10pt;font-family:'Noto Sans Symbols',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.7999999999999998;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
              >
                <span
                  style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >To send email updates to teachers, if applicable.</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:10pt;font-family:'Noto Sans Symbols',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.7999999999999998;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
              >
                <span
                  style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >To assess the quality of the Service Litmus Learn
                  provides.</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:10pt;font-family:'Noto Sans Symbols',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.7999999999999998;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
              >
                <span
                  style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >To help Teacher Litmus Learn users create and curate
                  content.&nbsp;</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:10pt;font-family:'Noto Sans Symbols',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.7999999999999998;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
              >
                <span
                  style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >To secure and safeguard personal information.</span
                >
              </p>
            </li>
            <li
              aria-level="1"
              dir="ltr"
              style="list-style-type:disc;font-size:10pt;font-family:'Noto Sans Symbols',sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
            >
              <p
                dir="ltr"
                style="line-height:1.7999999999999998;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
              >
                <span
                  style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                  >To comply with all applicable laws on the protection of
                  personal information.&nbsp;</span
                >
              </p>
            </li>
          </ul>
          <h1
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:6pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
          >
            <span
              style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >WHAT TYPE OF INFORMATION DO WE COLLECT?&nbsp;</span
            >
          </h1>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >We do not automatically collect any personal information. We only
              collect information provided by you or your institution to us at
              the time of registration. We also track, collect and aggregate
              non-personal information when you visit or browse through our
              Website or Application.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >At the time of registration Litmus Learn users are required to
              give us valid contact information (like your name, telephone
              number and a valid email address). As the parents/ legal guardians
              of the Student Litmus Learn users or as institutions you are
              required to provide a valid email address of the potential Litmus
              Learn user along with an alternative email address of one of the
              parent/legal guardians in case of student Litmus Learn users, at
              the time of registration that represents your identity on our
              application/service/ product. This information is compiled and
              analyzed on an aggregated basis. This information may include the
              URL that you just came from (whether this URL is on the Site or
              not), which URL you next go to (whether this URL is on the Site or
              not), your computer browser information, your IP address, and
              other information associated with your interaction with the
              Site.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:6pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >We do not collect any of your financial information like bank
              account details, credit card, debit card details, billing address
              etc.).</span
            >
          </p>
          <h1
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
          >
            <span
              style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >BASIS FOR PROCESSING INFORMATION</span
            >
          </h1>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Authorization is given to Litmus Learn to collect, process and
              otherwise handle Personal Information under this Privacy Policy
              and the Litmus Learn Terms and Conditions when an Institution
              enters into a contract with Litmus Learn and purchases the Litmus
              Learn Material as well as when a Teacher Litmus Learn user is
              registered to use the Litmus Learn Material. &nbsp;&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:6pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Further authorization is given to collect, process, and otherwise
              handle Personal Information of a Student Litmus Learn User under
              this Privacy Policy and the Terms and Conditions/ Terms of Use,
              when a parent/ legal guardian after giving his consent registers
              his/her ward with Litmus Learn to use the Litmus Learn
              Materials.</span
            >
          </p>
          <h1
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
          >
            <span
              style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >INFORMATION COLLECTED THROUGH Litmus Learn MATERIALS&nbsp;</span
            >
          </h1>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >When you access the Litmus Learn services the servers
              automatically collect some information sent by your browser. The
              type of information Litmus Learn collects depends on whether you
              are accessing the Litmus Learn Materials by visiting the
              website/application, have a Litmus Learn user account, or have a
              Teacher Litmus Learn user account, or are accessing the Litmus
              Learn Materials as a Student Litmus Learn user.</span
            >
          </p>
          <h2
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:13.5pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >a. Website/Application Visitors</span
            >
          </h2>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >For individuals who visit the Litmus Learn website/application
              but do not have a Litmus Learn user account, we automatically
              collect IP address, a device identifier, and other non-personally
              identifiable information. For example, we may receive information
              from your device when you use Litmus Learn, including, but not
              limited to: the duration of time you spend on Litmus Learn
              website/application, the type of device you&rsquo;re using, device
              event information like crashes, hardware settings, date and time
              information, and actions performed by you while visiting our
              website or application.</span
            >
          </p>
          <h2
            dir="ltr"
            style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:13.5pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >b. Litmus Learn user account</span
            >
          </h2>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >For Litmus Learn users the only information we collect is that
              you voluntarily provide us at the time of registration.
              Additionally, you may be asked to provide some basic information
              such as your first and last name, email address, name of the
              school/institution, role, and a profile photo (optional).</span
            >
          </p>
          <h2
            dir="ltr"
            style="line-height:1.3800000000000001;text-align: justify;margin-top:12pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:13.5pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >c. Personal Information of Teacher Litmus Learn Users</span
            >
          </h2>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >The only information that is collected in connection with the
              Application is personal information that you voluntarily provide
              while registering with us, such as your name, personal email id,
              contact information, the name of the institution, subjects taught
              in each class and which class you are in charge of.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >In addition to this personal information Litmus Learn will also
              collect all teacher- generated data and content that is created/
              curated by the teachers and also as a result of a teacher&rsquo;s
              interaction with the platform.&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >If you are a Litmus Learn user or a Teacher Litmus Learn user and
              believe that you have provided Litmus Learn with personally
              identifiable information, you should notify us at&nbsp;</span
            ><a
              href="mailto:privacy@litmuslearn.com"
              style="text-decoration:none;"
              ><span
                style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1155cc;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >privacy@litmuslearn.com</span
              ></a
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >,</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;so that we may delete the information from our servers
              after verification.</span
            >
          </p>
          <h2
            dir="ltr"
            style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:13.5pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >d. Personal Information of STUDENT Litmus Learn Users</span
            >
          </h2>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >The only personal information that is collected in connection
              with the App is personal information that you voluntarily provide
              while registering with us, such as the name of your ward, his
              personal email id, email id of the parent or legal guardian, name
              of the institution, class, section and subjects enrolled in and
              unique id number(roll no).</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >In addition to this personal information Litmus Learn will also
              collect all student- generated data and content that is created as
              a result from a student&rsquo;s interaction with the platform
              including mouse clicks, likes, questions answered, answer choices
              selected, game scores, grades, assignments and notes saved and
              uploaded by the student users upon completion of a lesson. Some
              lessons will also have an interactive feature that allows students
              to record audio and video, depending on the device used, to take
              photos of themselves to be uploaded onto the platform; which can
              subsequently only be viewed by their teacher, any Litmus Learn
              account administrator associated with the teacher account, and the
              other students who were given access by the Teacher Litmus Learn
              User, and who joined that lesson.&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:6pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >If you are a parent or legal guardian or teacher or an
              institution and believe that your ward or student has provided
              Litmus Learn with personally identifiable information by creating
              an account, you should notify us at&nbsp;</span
            ><a
              href="mailto:privacy@litmuslearn.com"
              style="text-decoration:none;"
              ><span
                style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1155cc;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >privacy@</span
              ><span
                style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1155cc;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >litmuslearn.com</span
              ></a
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >, so that we may delete the information from our servers after
              verification.</span
            >
          </p>
          <h2
            dir="ltr"
            style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:13.5pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >What children&rsquo;s information is visible to others?</span
            >
          </h2>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >No Litmus Learn user&apos;s profile is made available or visible
              to the public.</span
            ><span
              style="font-size:12pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Teachers may share the content that is created/ curated by them,
              with other teacher Litmus Learn users they co-teach within their
              school/institution, or with their school/institution for purposes
              of collaboration, and with the student Litmus Learn users of their
              class. The students and teachers present in a particular class
              room on Litmus Learn will see the teacher&rsquo;s names and
              comments, other student user&rsquo;s names and comments.</span
            ><span
              style="font-size:12pt;font-family:'Helvetica Neue',sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >The teacher Litmus Learn users will be able to see
              student&rsquo;s names, responses of individual students to
              questions, comments and total scores of the student
              users.&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:6pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >EDUCATIONAL INSTITUTIONS AND TEACHER Litmus Learn USERS SHALL
              MAKE A RESPONSIBLE USE OF THE SERVICE AND AVOID COMPROMISING
              STUDENT Litmus Learn USER&rsquo;S PERSONAL INFORMATION AT ALL
              TIMES WHEN USING Litmus Learn MATERIAL&rsquo;S. Litmus Learn SHALL
              NOT BE HELD LIABLE FOR THE INAPPROPRIATE USE OF THE SERVICE BY THE
              EDUCATIONAL INSTITUTION OR BY THE TEACHER Litmus Learn
              USERS.&nbsp;</span
            >
          </p>
          <h1
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;"
          >
            <span
              style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >WEB SERVER HOST INFORMATION</span
            >
          </h1>
          <h1
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Litmus Learn uses a third party web server to host/store its
              application, website and other content called Amazon Web Services
              (AWS), &nbsp;you can see the AWS Privacy (</span
            ><a
              href="https://aws.amazon.com/privacy"
              style="text-decoration:none;"
              ><span
                style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1155cc;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >https://aws.amazon.com/privacy</span
              ></a
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >) and AWS Data Privacy FAQ (</span
            ><a
              href="https://aws.amazon.com/compliance/data-privacy-faq/"
              style="text-decoration:none;"
              ><span
                style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1155cc;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >https://aws.amazon.com/compliance/data-privacy-faq/</span
              ></a
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >) for more information about how AWS handles content of its
              customers.</span
            >
          </h1>
          <h1
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
          >
            <span
              style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >DOES Litmus Learn SHARE INFORMATION WITH THIRD PARTIES?</span
            >
          </h1>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Unless disclosed otherwise at the time of collection and in this
              policy, we do not share any information with any third party other
              than our employees (these employees are not authorized to use your
              information for any secondary purposes), unless it is necessary to
              comply with the applicable law. We reserve the right to disclose
              your information as required by law, when we believe that the
              disclosure is necessary to protect our legal rights or to comply
              with judicial proceedings, court order, or any legal process
              served on Litmus Learn.&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >We also reserve the rights to share the information of the
              students with our partners for the purpose of providing value to
              the student.&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >We will never sell your information to any third party without
              your consent to any one, except in connection with the sale or
              merger of Litmus Learn, its assets or division of Litmus Learn or
              a bankruptcy or reorganization. In such an event, we will endeavor
              to require the buyer or transferee to agree to our commitments
              provided in this Privacy Policy. Notice of such transfer may be
              provided in accordance with our&nbsp;</span
            ><a
              href="https://nearpod.com/terms-conditions"
              style="text-decoration:none;"
              ><span
                style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >Terms and Conditions</span
              ></a
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;and also via a public press release.</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:6pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Litmus Learn will not use any personally identifiable information
              to advertise or market third party services to Litmus Learn users
              and does not rent or sell any such information.</span
            >
          </p>
          <h1
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
          >
            <span
              style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >CONFIDENTIALITY AND SECURITY</span
            >
          </h1>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Litmus Learn takes all reasonable precautions to protect your
              Personally Identifiable Information both online and offline, in
              order to avoid any unauthorised access or breach.&nbsp;</span
            >
          </p>
          <h1
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
          >
            <span
              style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >CHANGE TO THE PRIVACY POLICY</span
            >
          </h1>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >WE expect to amend our Privacy Policy from time to time in order
              to comply with the applicable laws.&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >WE reserve the right to modify this privacy policy at any time,
              so please review it frequently. If we make any material changes to
              this policy, we will notify you here.&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >We will also notify the institutions, the Teacher Litmus Learn
              user and also the legal guardians of the student Litmus Learn
              users who are below the age of 18 years through a separate email
              if and when we make any material changes to this policy that
              affect the privacy of the student users.&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:10pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >If and when we decide to change this privacy policy, we will post
              those changes to this privacy policy and other places we deem
              appropriate so that you are aware of what information we collect,
              how we use it, and under what circumstances, if any we disclose
              it.</span
            >
          </p>
          <h1
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 10pt 0pt;"
          >
            <span
              style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >DATA PROTECTION AFTER CONTRACT TERMINATION</span
            >
          </h1>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Litmus Learn agrees that within 90 days after the termination,
              cancellation, expiration or other conclusion of the Agreement with
              an institution, Litmus Learn shall delete, destroy any and all
              Personal Data or information of all the Litmus Learn users of that
              particular institution. &nbsp;&nbsp;</span
            >
          </p>
          <h1
            dir="ltr"
            style="line-height:1.38;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
          >
            <span
              style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >DISCLAIMER</span
            >
          </h1>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Although we are committed to protecting your privacy, &nbsp; we
              cannot always ensure that all of your private communications and
              other personal information will never be disclosed in ways not
              otherwise described in this Privacy Policy. As a user of Litmus
              Learn Materials, you understand and agree that you assume all
              responsibility and risk for your use of the Site, the internet
              generally, and the information you post or access and for your
              conduct on and off the Site.</span
            >
          </p>
          <h1
            dir="ltr"
            style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
          >
            &nbsp;
          </h1>
          <h1
            dir="ltr"
            style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
          >
            <span
              style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >HOW TO CONTACT Litmus Learn</span
            >
          </h1>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >YOU may contact us with any question or concern you may have
              at:</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Email:&nbsp;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#3c4043;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1a73e8;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >contact</span
            ><a
              href="mailto:contact@litmuslearn.com"
              style="text-decoration:none;"
              ><span
                style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1a73e8;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >@litmuslearn.com</span
              ></a
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1a73e8;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;or at the following address:</span
            >
          </p>
          <p><br /></p>
          <p
            dir="ltr"
            style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >DHRONA EDTECH PRIVATE LIMITED</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
          >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >1st Floor Hanush, 5/3 Vishwanathan Street, West Mambalam, Chennai
              &nbsp;600033 Tamil Nadu</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
          >
            <span><br /><br /></span>
            <span
              >In accordance with the Information Technology (Intermediary
              Guidelines and Digital Media Ethics Code) Rules, 2021, the name
              and contact details of the Grievance Officer are provided below:
            </span>
            <span><br /><br /></span>
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Grievance Officer:</span
            >
            <span> Mr Murali</span>
            <span
              >, 1st Floor Hanush, 5/3 Vishwanathan Street, West Mambalam,
              Chennai &nbsp;600033 Tamil Nadu.&nbsp;</span
            >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Email:&nbsp;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1a73e8;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >grievance</span
            ><a
              href="mailto:contact@litmuslearn.com"
              style="text-decoration:none;"
              ><span
                style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1a73e8;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >@litmuslearn.com</span
              ></a
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
          >
            <span><br /><br /></span>
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Data Protection Officer:</span
            >
            <span> Mr Murali</span>
            <span
              >, 1st Floor Hanush, 5/3 Vishwanathan Street, West Mambalam,
              Chennai &nbsp;600033 Tamil Nadu.&nbsp;</span
            >
            <span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >Email:&nbsp;</span
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1a73e8;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >contact</span
            ><a
              href="mailto:contact@litmuslearn.com"
              style="text-decoration:none;"
              ><span
                style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1a73e8;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >@litmuslearn.com</span
              ></a
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >&nbsp;</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
          >
            &nbsp;
          </p>
          <p><br /></p>
          <p><br /></p>
          <p><br /></p>
          <p><br /></p>
        </div>
      </mat-card>

      <div class="waves">
        <svg
          viewBox="0 0 1516 279"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0)" fill="#cb4242">
            <path
              d="M0 278.958V43.68C54.268 59.974 108.536 76.27 188.447 73.09c79.912-3.178 185.467-25.829 258.984-27.645 73.516-1.816 114.992 17.203 171.602 14.117 56.61-3.087 128.353-28.279 208.45-21.764 80.098 6.516 168.549 44.738 251.617 37.645 83.06-7.093 160.74-59.502 232.66-72.348 71.92-12.846 138.08 13.87 204.24 40.586v235.277H0z"
              fill-opacity=".5"
            />
            <path
              d="M0 278.959V161.32c55.471-5.12 110.943-10.24 182.131-2.353 71.187 7.887 158.092 28.78 244.244 18.234 86.152-10.546 171.553-52.532 252.667-47.056 81.113 5.476 157.941 58.414 213.714 57.055 55.773-1.358 90.491-57.013 160.024-67.642 69.53-10.629 173.87 23.768 256.88 38.232 83 14.465 144.67 8.997 206.34 3.53v117.639H0z"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <path fill="#fff" d="M0 0h1516v279H0z" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  `,
  styles: [
    `
              .privacy-wrapper {
                position: relative;
                height: 100vh;
                background-color: var(--soul);
                display: flex;
                align-items: center;
              }
        
              .waves {
                position: absolute;
                bottom: 0;
                width: 100%;
                left: 0;
              }
        
              .full-width {
                width: 100%;
              }
        
              .privacy-card {
                width: 80%;
                max-width: 800px;
                margin: 0 auto;
                padding: 2rem;
                position: relative;
                z-index: 1;
                height: 90vh;
                overflow-y: auto;
                position: relative;
              }
            `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyComponent {
  constructor() {}
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { ConfirmationService } from '@core/services/confirmation.service';
import { UiService } from '@core/services/ui/ui.service';
import { CourseEnrollment } from '@features/dashboard/dashboard.model';

@Component({
  selector: 'app-session-status',
  templateUrl: './session-status.component.html',
  styleUrls: ['./session-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionStatusComponent {
  @Input() sessionStatus: CourseEnrollment[] = [];
  @Output() homeClicked = new EventEmitter<boolean>();

  constructor(private confirmService: ConfirmationService) {}

  endSession() {
    this.homeClicked.emit(true);
  }
}

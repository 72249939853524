import { Injectable } from '@angular/core';
import { DashboardUserTourService } from '@features/dashboard/dashboard/dashboard-usertour.service';
import { ChapterContentUserTourService } from '@features/dashboard/chapter-content/chapter-content-usertour.service';
import { ShepherdService } from 'angular-shepherd';
import { AttendanceUserTourService } from '@features/dashboard/attendance/attendance-usertour.service';

@Injectable({
  providedIn: 'root',
})
export class NavBarTourService {
  constructor(
    public shepherdService: ShepherdService,
    private dashboardUserTour: DashboardUserTourService,
    private attendanceUserTour: AttendanceUserTourService,
    private ChapterContentUserTourService: ChapterContentUserTourService
  ) {}

  handleUserTour(route: string) {
    let tourRoute = this.getTourRoute(route);
    if (tourRoute === 'dashboard') {
      this.dashboardUserTour.startTour();
    }
    if (tourRoute === 'chapter-content') {
      this.ChapterContentUserTourService.startTour();
    }
    if (tourRoute === 'attendance') {
      this.attendanceUserTour.startTour();
    }
  }

  getTourRoute(route: string) {
    if (this.isRouteExactMatch(this.navExactRoutes, route).length > 0) {
      return this.isRouteExactMatch(this.navExactRoutes, route)[0].return;
    }
    if (this.isRoutePresent(this.navRoutes, route).length > 0) {
      return this.isRoutePresent(this.navRoutes, route)[0].return;
    } else {
      return '';
    }
  }

  navRoutes = [
    { path: '/chapter-content', return: 'chapter-content' },
    { path: '/social-document', return: 'social-document' },
    { path: '/attendance/view', return: 'attendance' },
    { path: '/attendance/edit', return: 'attendance' },

    // '/interactive-video',
    // '/quiz/',
    // '/quiz-game/add',
    // '/quiz-game/edit',
    // '/attendance',
    // '/image-upload',
    // '/assignment',
    // '/gradebook',
  ];

  navExactRoutes = [{ path: '/dashboard', return: 'dashboard' }];

  isRoutePresent(routes: any[], url: string) {
    return routes.filter((route) => url.includes(route.path));
  }

  isRouteExactMatch(routes: any[], url: string) {
    return routes.filter((route) => url === route.path);
  }
}

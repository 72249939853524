import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureFlagDirective } from './feature-flag.directive';
import { FeatureGuard } from './feature-flag.guard';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [FeatureFlagDirective],
  imports: [CommonModule, HttpClientModule],
  exports: [FeatureFlagDirective],
  providers: [FeatureGuard],
})
export class FeatureFlagsModule {}

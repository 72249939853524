import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-terms',
  template: `<div class="terms-wrapper">
    <mat-card appearance="outlined" class="terms-card">
      <mat-card-title>Litmus Learn Terms and Conditions</mat-card-title>
      <div>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >By clicking &quot;I accept&quot; or &quot;I agree&quot; to these
            Terms and Conditions (&ldquo;Terms&rdquo;) or by signing up for an
            account or otherwise using, accessing, or registering for Litmus
            Learn (collectively referred to as, &ldquo;we,&rdquo;
            &ldquo;us,&rdquo;, &ldquo;company&rdquo; or &quot;Litmus
            Learn&quot;) website &lsquo;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >www.litmuslearn.com&rsquo;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:italic;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >, Litmus Learn mobile applications, (collectively the &ldquo;Litmus
            Learn Materials and/or Services&rdquo;) or any other websites,
            applications, or online services that link to these Terms, you, the
            Litmus Learn user (referred to as &ldquo;user&rdquo; or
            &ldquo;you&rdquo;) are agreeing to these Terms, which is a legally
            binding contract with Litmus Learn for the benefit of itself and its
            affiliate and govern the your use of Litmus Learn Materials and/or
            Services.
          </span>
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:6pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#252525;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Litmus Learn (collectively, &quot;Litmus Learn&quot;, Litmus Learn
            mobile application&ldquo;, &quot;app&quot;, &quot;Litmus Learn
            Website&rdquo;, &ldquo;website&rdquo;, or &ldquo;Litmus Learn
            Service&rdquo;, &ldquo;Service&rdquo;) is owned &amp; operated by
            DHRONA Edtech Private Limited, incorporated under the Companies Act,
            2013 (hereinafter referred to as &ldquo;The Company&rdquo;), having
            its registered office at&nbsp;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#333333;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >, 1st Floor Hanush, 5/3 Vishwanathan Street, West Mambalam, Chennai
            + &nbsp;600033 Tamil Nadu.&nbsp;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >, India.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >We have done our best to write these terms in simple and clear
            terms.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
        >
          <span
            style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >DEFINITIONS</span
          ><span
            style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >For purposes of this Privacy Policy, the following definitions
            apply:&nbsp;</span
          >
        </p>
        <ol style="margin-top:0;margin-bottom:0;padding-inline-start:48px;">
          <li
            aria-level="1"
            dir="ltr"
            style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
          >
            <p
              dir="ltr"
              style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
            >
              <span
                style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&ldquo;</span
              ><span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >Litmus Learn Materials&rdquo;</span
              ><span
                style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&nbsp;</span
              ><span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >mean and include the Litmus Learn website, the Litmus Learn
                mobile applications and the content created/ curated, owned and
                operated by Litmus Learn.</span
              >
            </p>
          </li>
          <li
            aria-level="1"
            dir="ltr"
            style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
          >
            <p
              dir="ltr"
              style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
            >
              <span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&ldquo;Litmus Learn User&rdquo;&nbsp;</span
              ><span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >includes Institutions/schools, School Administrators, Teacher
                Litmus Learn Users, Student Litmus Learn Users. It also includes
                parents/legal guardians of the student Litmus Learn
                Users.&nbsp;</span
              ><span
                style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&nbsp;&nbsp;</span
              >
            </p>
          </li>
          <li
            aria-level="1"
            dir="ltr"
            style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
          >
            <p
              dir="ltr"
              style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
            >
              <span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&quot;</span
              ><span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >Institution</span
              ><span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&quot; means a school or an educational institution that enters
                into a contract with Litmus Learn and adopts/ uses the Litmus
                Learn Materials and/or Services.</span
              >
            </p>
          </li>
          <li
            aria-level="1"
            dir="ltr"
            style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
          >
            <p
              dir="ltr"
              style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
            >
              <span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&ldquo;School&rdquo;</span
              ><span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&nbsp;means and includes Secondary Schools and Higher Secondary
                Schools imparting education or training and affiliated to or
                recognized by or registered with Central Board of School
                Education (CBSE) or The Indian Certificate of Secondary
                Education (ICSE) or various State Recognized Boards in
                India.&nbsp;</span
              >
            </p>
          </li>
          <li
            aria-level="1"
            dir="ltr"
            style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
          >
            <p
              dir="ltr"
              style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
            >
              <span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&ldquo;Student Litmus Learn User&rdquo;</span
              ><span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&nbsp;A Student Litmus Learn User is a user who is the student
                of an institution (as defined under clause 3 of definitions),
                has a valid Student Litmus Learn users account and accesses the
                Litmus Learn Materials provided by the Teacher Litmus Learn user
                or by Litmus Learn and accessing and using the Litmus Learn
                Materials and/or &nbsp;Services.&nbsp;</span
              >
            </p>
          </li>
          <li
            aria-level="1"
            dir="ltr"
            style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
          >
            <p
              dir="ltr"
              style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
            >
              <span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&ldquo;Services / Litmus Learn Services&rdquo;&nbsp;</span
              ><span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >means and includes</span
              ><span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&nbsp;</span
              ><span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >Litmus Learn website, the Litmus Learn mobile applications or
                any associated applications, technology, products or services
                the company may provide now or in future.</span
              >
            </p>
          </li>
          <li
            aria-level="1"
            dir="ltr"
            style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
          >
            <p
              dir="ltr"
              style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
            >
              <span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&ldquo;</span
              ><span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >Teacher Litmus Learn User&rdquo;</span
              ><span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&nbsp;A Teacher Litmus Learn User is a single, named, person of
                legal age who is employed in an institution(as defined under
                clause 3 of definitions) and has a legally binding contract with
                the Institution and has a valid Teacher Litmus Learn user
                account and who is not a student Litmus Learn user.</span
              >
            </p>
          </li>
          <li
            aria-level="1"
            dir="ltr"
            style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
          >
            <p
              dir="ltr"
              style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
            >
              <span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&ldquo;</span
              ><span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >Terms and Conditions</span
              ><span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&rdquo; mean the Litmus Learn Terms and Conditions for the
                Litmus Learn Materials and/or Services.</span
              >
            </p>
          </li>
          <li
            aria-level="1"
            dir="ltr"
            style="list-style-type:decimal;font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
          >
            <p
              dir="ltr"
              style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:12pt;"
            >
              <span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&ldquo;</span
              ><span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >User&rdquo;</span
              ><span
                style="font-size:12pt;font-family:Arial;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                >&nbsp;means an end-user of the Litmus Learn Materials and/or
                Services.</span
              >
            </p>
          </li>
        </ol>
        <p
          dir="ltr"
          style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >INTRODUCTION</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >AT Litmus Learn our mission is to help students and teachers engage
            with each other through various Information Technology tools
            including the power of Interactive video learning, Quizzes and Quiz
            games. &nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >We empower teachers and institutions to decide how students engage
            with the Litmus Learn Material. Litmus Learn will provide
            recommendations based on content Litmus Learn has created/curated
            and also publicly available content for which Litmus Learn claims no
            ownership or copyright.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >It is important to us that the Litmus Learn Materials provide you
            with a helpful and reliable experience. To protect our rights and
            yours, these Terms govern all uses of the Litmus Learn
            Materials.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Access or use of the Litmus Learn Materials and/or Services or
            which you have registered or enrolled constitutes acceptance of
            these Terms as a binding agreement.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >If you do not agree to these Terms, then please do not use Litmus
            Learn and its services.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >When using the Litmus Learn Materials and/or Services, you will be
            subject to the Litmus Learn&nbsp;Terms, Privacy Policy&nbsp;and any
            published guidelines, policies or rules applicable to our website or
            applications or services (collectively the
            &ldquo;Guidelines&rdquo;). These Terms and our Privacy Policy,
            including any other Guidelines and future modifications
            (collectively, the &ldquo;Agreement&rdquo;), govern your use of the
            Service and are a legal contract between you and Litmus Learn.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >If you are a school, institution, or an organization that has
            entered into a separate written agreement with Litmus Learn, that
            agreement shall govern your use of the Service, and all matters not
            covered by said agreement will be subject to our Terms and
            Conditions and Privacy Policy.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >EDUCATIONAL INSTITUTIONS/SCHOOLS UTILIZING THE SERVICES OF Litmus
            Learn ARE RESPONSIBLE FOR MONITORING THE RIGHTS AND INTERESTS OF
            THEIR STUDENTS AND MUST THEREFORE TAKE SPECIAL CARE IN THE REVIEW OF
            THIS AGREEMENT. Litmus Learn SHALL NOT BE RESPONSIBLE FOR ANY
            NEGLIGENCE OF THE EDUCATIONAL INSTITUTION IN THE REVIEW OF THIS
            AGREEMENT.</span
          ><span
            style="font-size:12pt;font-family:'Helvetica Neue',sans-serif;color:#333333;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >CHANGE TO TERMS AND CONDITIONS</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >WE expect to amend our Terms and Conditions from time to time in
            order to comply with the applicable laws.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >WE reserve the right to modify these Terms at any time, so please
            review them frequently. If we make any material changes to these
            Terms, we will notify you here. If you continue to use any of the
            Litmus Learn materials after we have posted any change to these
            Terms or our&nbsp;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Privacy Policy</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >, you will be agreeing to any such changes.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >PRIVACY</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Your privacy is extremely important to us, and we take great care
            to limit what we collect and how we use it, and to help you
            understand our policies. Please read our Privacy Policy, which
            explains how we treat your personal information and protect your
            privacy when you use our Litmus Learn Materials and/or
            Services.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:10pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >ACCOUNTS, SECURITY AND PAYMENTS</span
          >
        </p>
        <h2
          dir="ltr"
          style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Sign-up and Security</span
          >
        </h2>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >As a condition to using the Litmus Learn Materials and/or Services,
            you are required to create an account with Litmus Learn and select a
            password and username or provide certain additional information
            required at the time of creating the account.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Further, you must provide Litmus Learn with accurate, complete, and
            updated personal information. You will not provide any false
            information or create an account for anyone other than yourself
            without permission.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Litmus Learn has three kinds of users: Institutions (school
            administrators), Teachers and Students (i.e. those who join lessons
            via a Student Account provisioned by their institutions).</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >All user accounts will be created by Litmus Learn based on
            information provided by the institutions. Once Litmus Learn creates
            individual accounts for all its users, users will be given access
            and control of their individual user accounts which they can use to
            log-in and access Litmus Learn Materials.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >By registering with Litmus Learn as a School administrator or as a
            Teacher Litmus Learn user you&apos;re representing that (i)
            you&apos;re an adult, (ii) you are currently employed by the
            institution/ school that has an agreement with Litmus
            Learn.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Litmus Learn does not intend for students and/or minors to sign up
            to the Litmus Learn Materials on their own without the consent of
            their parents and the authorization of the institutions; rather, you
            the Student Litmus Learn users should review these terms with your
            parent or legal guardian.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >FURTHER, as parents or legal guardians of Student Litmus Learn
            users you are representing that you have the ability to and, in
            fact, do provide consent for the students to use Litmus Learn
            Materials.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >We reserve the right to refuse to create an account or to delete an
            account at our discretion. You shall also be responsible for
            maintaining the confidentiality of your password and will not share
            your account with anyone or let anyone else access your account. You
            affirm, acknowledge and pledge that all the information you submit
            when creating an account is truthful and accurate.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >You are responsible for maintaining the confidentiality of
            passwords associated with your accounts to access the Litmus Learn
            Materials and you are responsible for all activities that occur
            under your account(s). Furthermore, you are solely responsible for
            any consequences, losses, or damages that we may directly or
            indirectly incur or suffer due to any unauthorized activities
            conducted by you.&nbsp;</span
          >
        </p>
        <h2
          dir="ltr"
          style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Payments</span
          >
        </h2>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >We do not charge Litmus Learn Student users directly; instead we
            charge&nbsp;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >educational institutions that enter into an agreement with Litmus
            Learn. When an institution enters into an agreement with Litmus
            Learn, they pay for or otherwise sign up to use the Litmus Learn
            Materials. An institution will be given access for user licenses
            purchased by it. Each license must be assigned to an individual user
            (teachers, school administrators and students) within the purchasing
            institution using their individual email addresses. The institution
            shall pay Litmus Learn for each license issued to the Student Litmus
            Learn users. The payments shall be made per student user per month.
            Litmus Learn grants institutions licenses to use the Litmus Learn
            Materials for the term of the agreement.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >The Agreement between Litmus Learn and the institutions shall be
            for a period of one (1) year, unless</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;either</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;party delivers a written notice of termination to the other
            at least one (1) month prior to the expiration of the term of the
            Agreement.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >In the event an institution terminates its agreement with Litmus
            Learn&nbsp;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >prior to</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;the expiration of the applicable Term, the institution will
            pay as damages a lump sum termination charge, as decided in its
            separate written agreement with Litmus Learn. &nbsp;&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >The institutions shall make the payments in advance on or before
            the 10</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            ><span style="font-size:0.6em;vertical-align:super;">th</span></span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;day of each month. If an institution fails to make payments
            before the 10</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            ><span style="font-size:0.6em;vertical-align:super;">th</span></span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;day of a particular month, Litmus Learn will suspend its
            services to that institution until the payments are made. Once
            DHORNA suspends its services to the institution the Teacher Litmus
            Learn users and School admin users will be able to view only the
            prior material/ content uploaded by Litmus Learn or the Teacher
            Litmus Learn users, but they will not be able to upload any new
            content/ material until the institution makes the payment. Further,
            the Student Litmus Learn users will also be able to view and
            interact with only the old material/ content but will not be able to
            view any new material/ content until the institution makes the
            payment.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Further, Litmus Learn shall cancel the licenses issued to
            institutions, if they fail to make the payments within 15 days from
            payment due date.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >All fees and charges are prepaid and nonrefundable, and there are
            no refunds.</span
          >
        </p>
        <h1
          dir="ltr"
          style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
        >
          <span
            style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >USING THE Litmus Learn APPLICATION/ PRODUCT/ MATERIAL/
            SERVICE</span
          >
        </h1>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >As long as you are complying with all of the terms and conditions
            and the Privacy Policy, Litmus Learn gives you permission to access
            and use the its Applications/ Materials/ Services. These
            Applications/ Products/ Materials/ Services are available for your
            personal, non-commercial use.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >We are constantly changing and improving our Applications/
            Products/ Materials/ Services. We may add or remove functionalities
            or features, and we may suspend or stop a Service altogether if, for
            example, you do not comply with this Agreement or if we are
            investigating any suspected/ reported misconduct. You can stop using
            our Service at any time, although we will be sorry to see you
            go.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >We may at our sole discretion suspend, stop or terminate or add or
            create new limits or restrict your access to all or a part of the
            Litmus Learn Materials at any time with or without notice or
            liability, including without limitation, breach of these Terms and
            Conditions. &nbsp;</span
          >
        </p>
        <h1
          dir="ltr"
          style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
        >
          <span
            style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Litmus Learn MATERIALS AND CONTENT OWNERSHIP AND LICENSE</span
          >
        </h1>
        <h2
          dir="ltr"
          style="line-height:1.3800000000000001;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Litmus Learn&rsquo;s Ownership</span
          >
        </h2>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >The Litmus Learn Materials (including past, present, and future
            versions) contain Content that is owned by or licensed to us.</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 6pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&lsquo;Content&rsquo;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;means all the text, graphics, user interfaces, visual
            interfaces, photographs, logos, sounds, music, artwork, activities,
            assessments, printables, pictures, video, animation, characters,
            audio clips, trademarks, trade names, service marks, computer code
            displayed on or available through the Litmus Learn Materials; the
            design, layout, look, appearance, structure, selection,
            coordination, expression, arrangement and graphics of such
            materials, all materials and other items relating to the Litmus
            Learn Materials, the Litmus Learn services and the Litmus Learn
            products; and any and all other forms of intellectual
            property.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 6pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Reproduction of the Litmus Learn Materials or Content outside the
            Litmus Learn Material&rsquo;s functionality is prohibited. Litmus
            Learn owns all legal rights, title, and interest in and to the
            Litmus Learn Materials and/or Services, including any intellectual
            property rights, whether those rights are registered or not, and
            wherever in the world those rights may exist.&nbsp;</span
          >
        </p>
        <h2
          dir="ltr"
          style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >License</span
          >
        </h2>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 6pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Subject to your strict compliance with these Terms, our Privacy
            Policy and any additional terms that may be mutually agreed, we
            grant you a limited, non-exclusive, non-sublicensable,
            non-transferable, revocable, and non-assignable license and right to
            access, use, reproduce, distribute, publicly perform, and display
            the Litmus Learn Materials and Content. The license does not give
            you any ownership or interest in any intellectual property of the
            Litmus Learn Materials or Content and you cannot otherwise use the
            Content or Litmus Learn Materials, without our express, prior,
            written consent. Litmus Learn hereby reserve all rights in and to
            the Litmus Learn products/ Services or Content (as the context may
            require) not expressly granted in these Terms. Except as expressly
            permitted in these Terms or with Litmus Learn&rsquo;s prior express
            written consent, no part of the Litmus Learn Materials or Services
            &nbsp;may be used, copied, reproduced, distributed, uploaded,
            posted, publicly displayed, translated, transmitted, sold, licensed,
            or otherwise used for any reason whatsoever. &nbsp;Any portion of
            the Site may not be reproduced, duplicated, copied, sold, resold,
            visited, or otherwise exploited for any commercial purpose without
            express written consent of the Company. You may not frame or utilize
            framing techniques to enclose any trademark, logo, or other
            proprietary information (including images, text, page layout, or
            form) of the Website or of the Company and/or its affiliates without
            the express written consent of the Company. You may not use any meta
            tags or any other &ldquo;hidden text&rdquo; utilizing the
            Company&rsquo;s name or trademarks without the express written
            consent of the Company. You shall not attempt to gain unauthorized
            access to any portion or feature of the Site, or any other systems
            or networks connected to the Site or to any server, computer,
            network, or to any of the services offered on or through the Site,
            by hacking, &lsquo;password mining&rsquo; or any other illegitimate
            means.</span
          >
        </p>
        <h2
          dir="ltr"
          style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Teacher Litmus Learn User-Generated Content</span
          >
        </h2>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >By using the Litmus Learn Materials, the Teacher Litmus Learn user
            may provide or create contents and any other materials, information,
            ideas, concepts, and know-how (collectively referred to as
            &ldquo;Teacher-generated Content&rdquo;). Under no circumstances
            will Litmus Learn become liable for any payment to you for any
            information that you provide. You, and not Litmus Learn, are solely
            responsible for any Teacher-Generated Content you make available
            through your use of the Litmus Learn Materials. Litmus Learn does
            not control the Teacher-Generated Content hosted via the Litmus
            Learn Materials, nor does it guarantee the accuracy, integrity or
            quality of such Teacher-Generated Content.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Except as expressly set forth, in these Terms, the Teacher Litmus
            Learn users shall retain all rights, including intellectual property
            rights, for the Content that they create with their Litmus Learn
            account.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Although the Teacher Litmus Learn user is and remains the owner of
            any Teacher-Generated Content, and data, submitted through the
            Litmus Learn Materials, you grant us a perpetual, irrevocable,
            worldwide, sub-licensable, royalty-free, and transferable right and
            license to use, reproduce, publish, display, modify, make derivative
            works of, transmit and copy your content and to additionally
            distribute and publicly perform your content in connection with the
            Litmus Learn Materials and Litmus Learn&rsquo;s (and its
            successor&rsquo;s) business, in any media formats and through any
            media channels for the purposes of delivering the services to you.
            Additionally, You grant Litmus Learn the irrevocable, perpetual,
            worldwide, sub-licensable, transferrable, right to use your
            information solely on an aggregated and anonymized basis. Litmus
            Learn represents and warrants it will not use student personal
            information for third party marketing or use. Finally, You also
            hereby grant to each user of the Litmus Learn Materials a
            non-exclusive license to access and view your Content as permitted
            by the functionality of the Litmus Learn Materials and these Terms.
            Notwithstanding the immediately preceding sentence, or anything else
            to the contrary, the Litmus Learn Materials only make your content
            available to others if you choose to allow it. For the avoidance of
            doubt, such anonymized Teacher-Generated Content shall not include
            any personally identifiable information.</span
          >
        </p>
        <h2
          dir="ltr"
          style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Litmus Learn Materials And Content Use Restrictions</span
          >
        </h2>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >You will not and will not attempt to: (i) &nbsp;license,
            sublicense, copy, duplicate, distribute, modify, publicly perform or
            display, transmit, publish, edit, adapt, create derivative works
            from, reproduce, sell, trade, or resell any of the Litmus Learn
            Materials or Content (including, without limitation, the
            reproduction, sale, trading or resale of Litmus Learn Materials or
            Content customized by other Litmus Learn users) without our prior
            written agreement; (ii) decompile, disassemble, reverse engineer or
            otherwise attempt to obtain or perceive the source code from which
            any software component of the Litmus Learn Materials are compiled or
            interpreted, and you acknowledge that nothing in these Terms will be
            construed to grant you any right to obtain or use such code; (iii)
            create any derivative product from of the foregoing, without our
            prior consent; (iv) assign, sublicense, sell, resell, lease, rent,
            or otherwise transfer, convey, or pledge as security or otherwise
            encumber, your rights under these Terms; (v) remove any title,
            trademark, copyright, or restricted rights notices or labels from
            the Litmus Learn Materials or related documentation; or (vi) share
            accounts.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >We take privacy very seriously. As such, if you learn that the
            license users are sharing accounts, you must notify the user that
            they are not to share accounts or passwords. If the user continues
            to share their account or password with someone else, you must
            notify us at&nbsp;</span
          ><a
            href="mailto:privacy@litmuslearn.com"
            style="text-decoration:none;"
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1155cc;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >privacy@litmuslearn.com</span
            ></a
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;within twenty-four (24) hours. &nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Notwithstanding anything to the contrary in this section or
            otherwise, the Litmus Learn Materials may include functionality that
            allows Teacher Litmus Learn users to create new content and certain
            other functionalities. These activities are permitted to the extent
            enabled within the Litmus Learn Materials, provided that such edited
            content is used solely for private educational purposes of the
            user.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >In connection with the Teacher Generated content, you further agree
            that you will not; (i) use material that is subject to third party
            intellectual property or proprietary rights, including privacy and
            publicity rights, unless you are the owner of such rights or have
            permission from their rightful owner to post the material or; (ii)
            use material that is subject to third party intellectual property or
            proprietary rights, including privacy and publicity rights, unless
            your right is protected under Fair use as per the Copyright Act or,
            (iii) use material that is unlawful, defamatory, libelous,
            threatening, pornographic, obscene, harassing, hateful, racially or
            ethnically offensive or encourages conduct that would be considered
            a criminal offense, violate any law or is otherwise
            inappropriate.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >We&apos;re not responsible for the content that the users post. We
            don&apos;t guarantee the availability of your content through the
            platform. Similarly, we are not responsible for content provided by
            third parties, even if Litmus Learn links to the third-party.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >We own all the Litmus Learn Materials, or have the right to use
            third-party materials that we license. Using, sharing, distributing,
            or otherwise accessing the Litmus Learn Materials in a way that is
            not allowed by Litmus Learn is a violation of these
            Terms.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >You won&apos;t infringe on other people&apos;s Intellectual
            Property with the material you post; post anything that is
            defamatory, obscene, pornographic, paedophilic, invasive of
            another&rsquo;s privacy, including bodily privacy, insulting or
            harassing on the basis of gender, libellous, racially or ethnically
            objectionable, relating or encouraging money laundering or gambling,
            or otherwise inconsistent with or contrary to the laws in force.
            Further, you won&apos;t advertise or market for business; or, use
            defamatory, obscene, violent, or otherwise inappropriate
            language.</span
          >
        </p>
        <h1
          dir="ltr"
          style="line-height:1.7999999999999998;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
        >
          <span
            style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >COPYRIGHT AND TRADEMARKS</span
          >
        </h1>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >EXCEPT AS EXPRESSLY SET FORTH, IN THESE TERMS, THE COMPANY,
            EXPRESSLY RESERVES ALL INTELLECTUAL PROPERTY RIGHTS.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >The Litmus Learn Materials, and/or Services are intended solely for
            the personal, non-commercial use of our users and may only be used
            in accordance with this Agreement. &ldquo;Litmus Learn Materials,
            Technology and Services&rdquo; means all past, present and future
            content of the Service, including, all the software, hardware and
            technology used to provide the Service (including Litmus Learn
            proprietary code and third-party software), user interfaces,
            materials displayed or performed on the Service, such as text,
            graphics, articles, photographs, images, illustrations and the
            design, structure, sequence and &ldquo;look and feel&rdquo; of the
            Services, and all other intellectual property.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Litmus Learn Materials, Technology and Services are protected by
            copyright and other intellectual property laws. You are not allowed
            to use, store, copy, reproduce, modify, translate, publish,
            broadcast, transmit, distribute, perform, upload, create derivative
            works from, display, license, sell or otherwise exploit the Litmus
            Learn Materials, Technology and Services for any purposes other than
            as expressly permitted under this Agreement. You will not decompile,
            reverse engineer, or otherwise attempt to obtain the source code of
            any Litmus Learn Materials, Technology and Services.&nbsp;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Additionally, you agree not to try and scrape our website,
            technology or use external forces to try and interfere with our
            platforms.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Using our Material or Technology or Services does not give you
            ownership of any intellectual property rights in our Service or the
            Litmus Learn Material or Technology. You may not use content from
            our Materials or Services, including User Submissions, unless you
            obtain permission from its owner or are otherwise permitted by
            law.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >The names and logos and all related product and service names,
            design marks and slogans are the trademarks/service marks of the
            Company, its affiliates, its partners or its suppliers/service
            providers. All other marks are the property of their respective
            owners. No trademark or service mark license is granted in
            connection with the materials contained on the Site. Access to or
            use of the Litmus Learn Materials including applications and website
            does not authorize anyone to use any name, logo or mark in any
            manner. References on the Site to any names, marks, products or
            services of third parties or hypertext links to third party sites or
            information are provided solely as a convenience to you and do not
            in any way constitute or imply the Company&rsquo;s endorsement,
            sponsorship or recommendation of the third party, the information,
            its product or services.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Litmus Learn is not responsible for the content of any third party
            sites and does not make any representations regarding the content or
            accuracy of material on such sites. If you decide to access a link
            of any third party websites, you do so entirely at your own risk and
            expense.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Litmus Learn respects the intellectual property rights of others
            and requires that the people who use the Litmus Learn Materials do
            the same. It is our policy to respond promptly to claims of
            intellectual property misuse.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Please note that you will be liable for damages (including costs
            and attorney&rsquo;s fees) if you make any material
            misrepresentations when making or countering a copyright
            infringement claim.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >If you believe that your work has been copied and is accessible on
            the Litmus Learn Materials in a way that constitutes copyright
            infringement, you may notify us at&nbsp;</span
          ><a
            href="mailto:copyright@litmuslearn.com"
            style="text-decoration:none;"
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1155cc;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >copyright@litmuslearn.com</span
            ></a
          >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >.&nbsp;</span
          >
        </p>
        <h1
          dir="ltr"
          style="line-height:1.7999999999999998;background-color:#ffffff;margin-top:13.6pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
        >
          <span
            style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >DISCLAIMER OF WARRANTIES AND LIABILITIES</span
          >
        </h1>
        <h2
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Representations and Warranties by Litmus Learn Users
            &ldquo;YOU&rdquo;&nbsp;</span
          >
        </h2>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >You represent and warrant that, in connection with these Terms and
            Conditions or the Litmus Learn Materials or Technology or Services:
            (i) your use of the Litmus Learn Materials and/or Services will be
            in strict accordance with these Terms and Conditions and with all
            applicable laws and regulations; (ii) you will not and will not
            attempt to re-join or attempt to use the Litmus Learn Materials
            and/or Services if Litmus Learn has suspended or revoked your
            access.; (iii) you will not and will not attempt to defraud Litmus
            Learn; (iv) that you, to the extent applicable, are duly organized,
            validly existing and in good standing under the laws of the
            jurisdiction of your incorporation or organization; (v) that the
            execution or performance of these Terms will not conflict with or
            violate any provision of any law applicable to you; (vi) that by
            creating an account, or otherwise using the Litmus Learn Materials
            you agree to be bound by these Terms, that these terms will
            constitute a valid and binding obligation on you, and will be
            enforceable against you in accordance with the terms herein; (vii)
            you will comply with with all applicable laws, statutes,
            regulations, or rules, without limitation,&nbsp;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Information Technology Act, 2000 (</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;IT Act&rdquo;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >) and the Information Technology (Reasonable Security Practices and
            Procedures And Sensitive Personal Data or Information) Rules, 2011
            (</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&ldquo;IT Rules&rdquo;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >);&nbsp;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >and (viii) you will not and will not attempt to use another
            user&rsquo;s account or allow another person to use your user
            account.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Further, if you are a Teacher Litmus Learn user you represent and
            warrant that, in connection with these Terms and Conditions or the
            Litmus Learn Materials or Technology or Services, your
            Teacher-Generated Content and your use of the Litmus Learn Materials
            will not infringe or misappropriate the intellectual property rights
            of any third party.&nbsp;</span
          >
        </p>
        <h2
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:12pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Disclaimer of Warranties by Litmus Learn</span
          >
        </h2>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >THE LITMUS LEARN MATERIALS AND/OR SERVICES ARE PROVIDED &ldquo;AS
            IS.&rdquo; LITMUS LEARN EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY
            KIND, EXPRESS, IMPLIED OR STATUTORY, INCLUDING, WITHOUT LIMITATION,
            THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, TITLE OR NON-INFRINGEMENT. LITMUS LEARN DOES NOT WARRANT OR
            MAKE ANY REPRESENTATION THAT THE LITMUS LEARN MATERIALS WILL BE
            ERROR-FREE OR THAT ACCESS THERETO WILL BE CONTINUOUS OR
            UNINTERRUPTED,&nbsp;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#333333;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >OR&nbsp;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >THAT MATERIALS, INFORMATION AND RESULTS OBTAINED WILL BE EFFECTIVE,
            ACCURATE OR RELIABLE OR</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#333333;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;THAT ALL DEFECTS AND ERRORS WILL BE CORRECTED</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;OR THAT THE LITMUS LEARNMATERIALS AND/OR SERVICES ARE FREE
            FROM VIRUSES OR OTHER HARMFUL COMPONENTS.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >YOU UNDERSTAND THAT YOU USE THE LITMUS LEARN MATERIALS AND/OR
            SERVICES AT YOUR OWN DISCRETION AND RISK. YOU ARE SOLELY RESPONSIBLE
            FOR ANY DAMAGE TO YOUR COMPUTER OR OTHER DEVICES AND FOR ANY LOSS OF
            DATA THAT MAY RESULT FROM THE DOWNLOAD OF SUCH CONTENT. WE DO NOT
            PROVIDE ANY WARRANTY OR GUARANTEE AS TO THE ACCURACY, PERFORMANCE,
            COMPLETENESS, OR SUITABILITY OF THE INFORMATION AND MATERIALS FOUND
            OR OFFERED ON THE LITMUS LEARN MATERIALS AND/OR SERVICES. YOU
            ACKNOWLEDGE THAT SUCH INFORMATION AND MATERIALS MAY CONTAIN
            INACCURACIES OR ERRORS AND WE EXPRESSLY EXCLUDE LIABILITY FOR ANY
            SUCH INACCURACIES OR ERRORS TO THE FULLEST EXTENT PERMITTED BY
            LAW.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >LITMUS LEARN DOES NOT GUARANTEE OR WARRANT THAT ANY CONTENT YOU MAY
            STORE OR ACCESS THROUGH THE LITMUS LEARN SERVICES WILL NOT BE
            SUBJECT TO INADVERTENT DAMAGE, LOSS OR REMOVAL. CERTAIN STATE LAWS
            DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES. IF THESE LAWS APPLY
            TO YOU, SOME OR ALL OF THE FOREGOING DISCLAIMERS, EXCLUSIONS, OR
            LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL
            RIGHTS.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:-5.2pt 0pt 0pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >FURTHER, LITMUS LEARN TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
            LAW WILL HAVE NO LIABILITY RELATED TO USER CONTENT ARISING UNDER
            INTELLECTUAL PROPERTY RIGHTS, LIBEL, PRIVACY, PUBLICITY, OBSCENITY
            OR OTHER LAWS.&nbsp;</span
          >
        </p>
        <h1
          dir="ltr"
          style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:6.8pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
        >
          <span
            style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >LIMITATION OF LIABILITY</span
          >
        </h1>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >IN NO EVENT, SHALL LITMUS LEARN OR ITS SUCCESSORS, AFFILIATES,
            CONTRACTORS, EMPLOYEES, LICENSORS, PARTNERS OR AGENTS, BE LIABLE TO
            YOU FOR ANY DAMAGES INCLUDING INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL, OR EXEMPLARY DAMAGES, BUT NOT LIMITED TO, DAMAGES FOR
            LOSS OF DATA OR PROFITS, COST OF COVER, GOODWILL, USE, OR OTHER
            INTANGIBLE LOSSES (EVEN IF LITMUS LEARN HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGES), ARISING FROM OR RELATING TO (i) THIS
            AGREEMENT; (ii) YOUR USE OR THE INABILITY TO USE THE SERVICES,
            CONTENT, OR USER SUBMISSIONS; OR (iii) ANY INTERACTION WITH ANY
            THIRD PARTY THROUGH OR IN CONNECTION WITH THE SERVICE, INCLUDING
            OTHER USERS.</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >BECAUSE SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED
            WARRANTIES, OR LIMITATIONS OF LIABILITY FOR CONSEQUENTIAL OR
            INCIDENTAL DAMAGES, THESE LIMITATIONS MAY NOT APPLY TO YOU. THIS
            LIMITATION SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL
            PURPOSE OF ANY LIMITED REMEDY.</span
          >
        </p>
        <h1
          dir="ltr"
          style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:6.8pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
        >
          <span
            style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >INDEMNIFICATION</span
          >
        </h1>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:6pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS LITMUS LEARN,
            &nbsp;INCLUDING BUT NOT LIMITED TO ITS OFFICERS, DIRECTORS,
            EMPLOYEES, AGENTS, OTHER SERVICE PROVIDERS OR CUSTOMERS FROM AND
            AGAINST ANY AND ALL LOSSES, LIABILITIES, CLAIMS, DAMAGES, DEMANDS,
            COSTS AND EXPENSES (INCLUDING LEGAL FEES AND DISBURSEMENTS IN
            CONNECTION THEREWITH AND INTEREST CHARGEABLE THEREON) ASSERTED
            AGAINST OR INCURRED BY US THAT ARISE OUT OF, RESULT FROM, OR MAY BE
            PAYABLE BY VIRTUE OF, ANY BREACH OR NON-PERFORMANCE OF ANY
            REPRESENTATION, WARRANTY, COVENANT OR AGREEMENT MADE OR OBLIGATION
            TO BE PERFORMED BY YOU PURSUANT TO THESE TERMS AND CONDITIONS.</span
          >
        </p>
        <h1
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
        >
          <span
            style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >WAIVER AND SEVERABILITY&nbsp;</span
          >
        </h1>
        <h2
          dir="ltr"
          style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 6pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >WAIVER</span
          >
        </h2>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 6pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;THE FAILURE OF ANY PARTY AT ANY TIME TO REQUIRE PERFORMANCE
            OF ANY PROVISION OF THIS AGREEMENT IN NO MANNER SHALL AFFECT SUCH
            PARTY&apos;S RIGHT AT A LATER TIME TO ENFORCE THE SAME. NO WAIVER BY
            EITHER PARTY OF ANY BREACH OF THIS AGREEMENT, WHETHER BY CONDUCT OR
            OTHERWISE, IN ANY ONE OR MORE INSTANCES, SHALL BE DEEMED TO BE, OR
            CONSTRUED AS, A FURTHER OR CONTINUING WAIVER OF ANY OTHER SUCH
            BREACH, OR A WAIVER OF ANY OTHER BREACH OF THIS AGREEMENT.</span
          >
        </p>
        <h2
          dir="ltr"
          style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 6pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >SEVERABILITY</span
          >
        </h2>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:6pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;IF ANY PROVISION OF THIS AGREEMENT SHALL TO ANY EXTENT BE
            HELD INVALID, ILLEGAL OR UNENFORCEABLE, FOR ANY REASON THE VALIDITY,
            LEGALITY AND ENFORCEABILITY OF THE REMAINING PROVISIONS OF THIS
            AGREEMENT SHALL IN NO WAY BE AFFECTED OR IMPAIRED THEREBY AND EACH
            SUCH PROVISION OF THIS AGREEMENT SHALL BE VALID AND ENFORCEABLE TO
            THE FULLEST EXTENT PERMITTED BY LAW. IN SUCH A CASE, THIS AGREEMENT
            SHALL BE REFORMED TO THE MINIMUM EXTENT NECESSARY TO CORRECT ANY
            INVALIDITY, ILLEGALITY OR UNENFORCEABILITY, WHILE PRESERVING TO THE
            MAXIMUM EXTENT THE RIGHTS AND COMMERCIAL EXPECTATIONS OF THE PARTIES
            HERETO, AS EXPRESSED HEREIN.</span
          >
        </p>
        <h1
          dir="ltr"
          style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 11.25pt 0pt;"
        >
          <span
            style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >TERMINATION</span
          >
        </h1>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:10pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >This Agreement shall remain in full force and effect while you use
            our Services. You may terminate your use of the Service by
            delivering a written notice of termination to us at least one (1)
            month in advance.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:10pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >In the event an institution terminates its agreement with Litmus
            Learn prior to&nbsp;</span
          >
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;the expiration of the applicable Term, the institution will
            pay as damages a lump sum termination charge, as decided in its
            separate written agreement with Litmus Learn.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:6pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >We may at our sole discretion suspend, stop or terminate or add or
            create new limits or restrict your access to all or a part of the
            Litmus Learn Materials at any time with or without notice or
            liability, including without limitation, breach of these Terms and
            Conditions.&nbsp;</span
          >
        </p>
        <h1
          dir="ltr"
          style="line-height:1.3800000000000001;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:10pt;padding:0pt 0pt 11.25pt 0pt;"
        >
          <span
            style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >DISPUTE RESOLUTION AND JURISDICTION</span
          >
        </h1>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 6pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >In the interest of resolving disputes between you and Litmus Learn
            in the most expedient and cost effective manner, you and Litmus
            Learn agree that any and all controversies, claims or disputes
            arising in</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#333333;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >connection with , but not limited to, all claims arising out of or
            relating to any aspect of these Terms and Conditions &nbsp;and our
            Privacy Policy, including any other Guidelines and other future
            modifications, whether based on contract, tort, statute, fraud,
            misrepresentation or any other legal theory, and regardless of
            whether the claims arise during or after the termination of these
            Terms and Conditions shall be resolved through a two-step Alternate
            Dispute Resolution system.</span
          >
        </p>
        <h2
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 6pt 0pt;"
        >
          <span
            style="font-size:11pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Mediation&nbsp;</span
          >
        </h2>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:12pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >In case of a dispute, the matter will first be attempted to be
            resolved by a sole mediator who is a neutral third party and will be
            selected at the mutual acceptance of a proposed mediator by both
            parties. Both parties may raise a name for sole arbitrator and in
            case both parties accept the proposed name, the said person shall be
            appointed as sole mediator. In any case, the parties are not able to
            reach a consensus within two proposed mediators, the Company
            reserves the right to decide who the final mediator is. The decision
            of the mediator is not binding on the parties; however, the parties
            in good faith will attempt to be bound by the decision.</span
          >
        </p>
        <h2
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:6pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Arbitration&nbsp;</span
          >
        </h2>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >In case that mediation does not yield a result suitable or
            preferred by anyone of the parties, arbitration may follow, the
            award of which is binding on both parties. The Arbitration Board
            shall comprise three members &ndash; one appointed by each party and
            the third member to be nominated by the two appointed members by
            mutual consent. The arbitration shall be held at Chennai, Tamil
            Nadu, India. The arbitrator&rsquo;s award shall be final and binding
            on the Parties. If the dispute cannot be resolved by this two-step
            Alternate Dispute Resolution System, it shall be referred to the
            courts at Chennai, Tamil Nadu, India.</span
          >
        </p>
        <p><br /></p>

        <h1
          dir="ltr"
          style="line-height:1.7999999999999998;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
        >
          <span
            style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >PhET Simulations</span
          >
        </h1>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:12pt 0pt 12pt 0pt;"
        >
          <span
            style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
          >
            All simulations identified as PHET Simulations accessed within
            Litmus Learn are property of University of Colorado Boulder. All
            simulations available at https://phet.colorado.edu are open
            educational resources available under the Creative Commons
            Attribution license (CC-BY). Permission is granted to freely use,
            share, or redistribute PhET sims under the CC-BY license. The
            following attribution is required:

            <br />
          </span>
        </p>
        <p
          style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
        >
          PhET Interactive Simulations <br />
          University of Colorado Boulder <br />
          https://phet.colorado.edu <br />
        </p>

        <h1
          dir="ltr"
          style="line-height:1.7999999999999998;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
        >
          <span
            style="font-size:13.999999999999998pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >HOW TO CONTACT Litmus Learn</span
          >
        </h1>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:12pt 0pt 12pt 0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >YOU may contact us with any question or concern you may have
            at:</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Email:&nbsp;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1a73e8;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >contact</span
          ><a
            href="mailto:contact@litmuslearn.com"
            style="text-decoration:none;"
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1a73e8;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >@litmuslearn.com</span
            ></a
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Address: DHRONA EDTECH PRIVATE LIMITED</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >, 1st Floor Hanush, 5/3 Vishwanathan Street, West Mambalam, Chennai
            &nbsp;600033 Tamil Nadu.&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.7999999999999998;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
        >
          &nbsp;
        </p>
        <p
          dir="ltr"
          style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
        >
          <span
            >In accordance with the Information Technology (Intermediary
            Guidelines and Digital Media Ethics Code) Rules, 2021, the name and
            contact details of the Grievance Officer are provided below:
          </span>
          <span><br /><br /></span>
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Grievance Officer:</span
          >
          <span> Mr Murali</span>
          <span
            >, 1st Floor Hanush, 5/3 Vishwanathan Street, West Mambalam, Chennai
            &nbsp;600033 Tamil Nadu.&nbsp;</span
          >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Email:&nbsp;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1a73e8;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >grievance</span
          ><a
            href="mailto:contact@litmuslearn.com"
            style="text-decoration:none;"
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1a73e8;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >@litmuslearn.com</span
            ></a
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;</span
          >
        </p>
        <p
          dir="ltr"
          style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
        >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Data Protection Officer:</span
          >
          <span> Mr Murali</span>
          <span
            >, 1st Floor Hanush, 5/3 Vishwanathan Street, West Mambalam, Chennai
            &nbsp;600033 Tamil Nadu.&nbsp;</span
          >
          <span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >Email:&nbsp;</span
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1a73e8;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >contact</span
          ><a
            href="mailto:contact@litmuslearn.com"
            style="text-decoration:none;"
            ><span
              style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1a73e8;background-color:#ffffff;font-weight:700;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
              >@litmuslearn.com</span
            ></a
          ><span
            style="font-size:12pt;font-family:'Book Antiqua',serif;color:#1f1f2a;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
            >&nbsp;</span
          >
        </p>
      </div>
    </mat-card>

    <div class="waves">
      <svg
        viewBox="0 0 1516 279"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)" fill="#cb4242">
          <path
            d="M0 278.958V43.68C54.268 59.974 108.536 76.27 188.447 73.09c79.912-3.178 185.467-25.829 258.984-27.645 73.516-1.816 114.992 17.203 171.602 14.117 56.61-3.087 128.353-28.279 208.45-21.764 80.098 6.516 168.549 44.738 251.617 37.645 83.06-7.093 160.74-59.502 232.66-72.348 71.92-12.846 138.08 13.87 204.24 40.586v235.277H0z"
            fill-opacity=".5"
          />
          <path
            d="M0 278.959V161.32c55.471-5.12 110.943-10.24 182.131-2.353 71.187 7.887 158.092 28.78 244.244 18.234 86.152-10.546 171.553-52.532 252.667-47.056 81.113 5.476 157.941 58.414 213.714 57.055 55.773-1.358 90.491-57.013 160.024-67.642 69.53-10.629 173.87 23.768 256.88 38.232 83 14.465 144.67 8.997 206.34 3.53v117.639H0z"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <path fill="#fff" d="M0 0h1516v279H0z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>`,
  styles: [
    `
              .terms-wrapper {
                position: relative;
                height: 100vh;
                background-color: var(--soul);
                display: flex;
                align-items: center;
              }
        
              .waves {
                position: absolute;
                bottom: 0;
                width: 100%;
                left: 0;
              }
        
              .full-width {
                width: 100%;
              }
        
              .terms-card {
                width: 80%;
                max-width: 800px;
                margin: 0 auto;
                padding: 2rem;
                position: relative;
                z-index: 1;
                height: 90vh;
                overflow-y: auto;
                position: relative;
              }
            `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsComponent {
  constructor() {}
}

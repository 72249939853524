import { style } from '@angular/animations';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  MathfieldElement,
  renderMathInDocument,
  convertLatexToMarkup,
} from 'mathlive';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-equation-editor',
  templateUrl: './equation-editor.component.html',
  styleUrls: ['./equation-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquationEditorComponent implements OnInit {
  @ViewChild('mathField') mathFieldEl: ElementRef;

  defaultGroup = '';
  buttonGroups = {};
  latex = '';
  mathLiveConfig = {};
  mathfield;

  private buttonBarSubject$ = new BehaviorSubject<any>([]);
  buttonBar$ = this.buttonBarSubject$.asObservable();
  private currentGrpSubject$ = new BehaviorSubject<any>([]);
  currentGroup$ = this.currentGrpSubject$.asObservable();

  constructor() {}

  ngOnInit() {
    window.addEventListener('message', this.getParams, false);
    setTimeout(function () {
      renderMathInDocument({
        fontsDirectory: '/assets/fonts',
      });
    }, 200);
    window.parent.postMessage(
      {
        mceAction: 'equation-mounted',
        status: true,
      },
      '*'
    );
  }

  collapse(event) {
    event.target.classList.toggle('active');
    const content = event.target.nextElementSibling;
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + 'px';
    }
  }

  getParams = (evt) => {
    if (
      (evt.data.type = 'message' && 'equation_editor_button_groups' in evt.data)
    ) {
      const data = evt.data;
      this.defaultGroup = data.equation_editor_group;
      this.buttonBarSubject$.next(data.equation_editor_button_bar);
      this.buttonGroups = data.equation_editor_button_groups;
      this.currentGrpSubject$.next(this.buttonGroups[this.defaultGroup]);
      this.latex = data.latex;
      this.mathLiveConfig = data.mathlive_config;
      this.initEquation();
    }
  };

  initEquation() {
    this.mathfield = new MathfieldElement({ smartFence: false });
    this.mathfield.setOptions({
      fontsDirectory: '/assets/fonts',
    });
    this.mathFieldEl.nativeElement.appendChild(this.mathfield);
    this.mathFieldEl.nativeElement.addEventListener('input', (ev) => {
      this.latex = this.mathfield.value;
      this.sendLatex();
    });
    if (this.latex) {
      this.mathfield.value = this.latex;
    }
  }

  insert(button) {
    this.mathfield.insert(button.latex);
  }

  sendLatex() {
    const content = {
      mceAction: 'equation-update',
      html: convertLatexToMarkup(this.latex),
      latex: this.latex,
    };
    window.parent.postMessage(content, '*');
  }
}

import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { LoggedInUserRole } from '@features/session/session.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-support-menu',
  templateUrl: './support-menu.component.html',
  styleUrls: ['./support-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportMenuComponent {
  role = LoggedInUserRole;

  @Output() userTour = new EventEmitter<boolean>();
  constructor(private router: Router) {}

  userTourClicked() {
    this.userTour.emit();
  }
  routetoTutorial() {
    this.router.navigate([`/tutorials`]);
  }
}

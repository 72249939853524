<ng-container *ngIf="sessionStatus && sessionStatus.length > 0">
  <mat-chip-option
    class="session-status"
    (click)="showSession.show()"
    #showSession="tippy"
    [tippy]="popover"
    interactive="true"
    >Session Live</mat-chip-option
  >
</ng-container>
<ng-template #popover let-hide>
  <div class="popover-content">
    <ul class="student-list">
      <li *ngFor="let student of sessionStatus">{{ student.userName }}</li>
    </ul>
    <hr />
    <button mat-button color="primary" (click)="endSession()">
      End Session
    </button>
  </div>
</ng-template>

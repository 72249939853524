import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
/**
 * Helper service that makes it easy to do api calls instead of calling the http client multiple times.
 *
 * NOTE:: The methods with an appended 'X' are an enhanced version of the existing methods. The aim is to eventually replace
 * the existing methods with these as these provide a loading and error state as well for each network request.
 * Both the methods exists with different names now so as to not break existing implementations.
 */
export class ApiHttpService {
  constructor(private http: HttpClient) { }

  parseResponseData<T>(data: T) {
    if (data && data.hasOwnProperty('errors')) {
      if (data['errors'] && data['errors'].length > 0) {
        return { error: data['errors'], data: null, loading: false };
      }
    }
    return { data, loading: false, error: null };
  }

  public get<T>(url: string, options?: { headers: HttpHeaders }) {
    return this.http.get<T>(url, options);
  }

  public getX<T>(
    url: string,
    options?: { headers: HttpHeaders }
  ): Observable<{ data: T | null; loading: boolean; error: any }> {
    return this.http.get<T>(url, options).pipe(
      map((data) => {
        return this.parseResponseData(data);
      }),
      startWith({ loading: true, data: null, error: null }),
      catchError((error) => of({ loading: false, error, data: null }))
    );
  }
  public post<T>(url: string, data: any, options?: { headers: HttpHeaders }) {
    return this.http.post<T>(url, data, options);
  }
  public postX<T>(
    url: string,
    data?: any,
    options?: { headers: HttpHeaders }
  ): Observable<{ data: T | null; loading: boolean; error: any }> {
    return this.http.post<T>(url, data, options).pipe(
      map((data) => {
        return this.parseResponseData(data);
      }),
      startWith({ loading: true, data: null, error: null }),
      catchError((error) => of({ loading: false, error, data: null }))
    );
  }

  public patch<T>(url: string, data: any, options?: { headers: HttpHeaders }) {
    return this.http.patch<T>(url, data, options);
  }

  public put<T>(url: string, data: any, options?: { headers: HttpHeaders }) {
    return this.http.put<T>(url, data, options);
  }
  public putX<T>(
    url: string,
    data: any,
    options?: { headers: HttpHeaders }
  ): Observable<{ data: T | null; loading: boolean; error: any }> {
    return this.http.put<T>(url, data, options).pipe(
      map((data) => {
        return this.parseResponseData(data);
      }),
      startWith({ loading: true, data: null, error: null }),
      catchError((error) => of({ loading: false, error, data: null }))
    );
  }
  public delete<T>(url: string, options?: { headers: HttpHeaders }) {
    return this.http.delete<T>(url, options);
  }
  public deleteX<T>(
    url: string,
    options?: { headers: HttpHeaders }
  ): Observable<{ data: T | null; loading: boolean; error: any }> {
    return this.http.delete<T>(url, options).pipe(
      map((data) => {
        return this.parseResponseData(data);
      }),
      startWith({ loading: true, data: null, error: null }),
      catchError((error) => of({ loading: false, error, data: null }))
    );
  }
}

<!-- <div class="signup-btn" (click)="signupClicked()" matTooltip="Signup">
  <button mat-button><mat-icon>person_add</mat-icon>Signup</button>
</div> -->
<form [formGroup]="loginForm" (ngSubmit)="login()">
  <mat-form-field appearance="outline" floatLabel="always" class="full-width">
    <mat-label>Email</mat-label>
    <input
      matInput
      placeholder="arjun@gmail.com"
      required
      autocomplete="username"
      type="email"
      formControlName="email"
    />
    <mat-error *ngIf="email.invalid">{{ emailErrorMessage }}</mat-error>
  </mat-form-field>
  <mat-form-field floatLabel="always" appearance="outline" class="full-width">
    <mat-label>Password</mat-label>
    <input
      matInput
      required
      autocomplete="current-password"
      formControlName="password"
      [type]="showPassword ? 'text' : 'password'"
    />
    <button
      type="button"
      mat-icon-button
      matSuffix
      (click)="showPassword = !showPassword"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="!showPassword"
    >
      <mat-icon class="material-icons-outlined">{{
        showPassword ? 'visibility' : 'visibility_off'
      }}</mat-icon>
    </button>
    <mat-error *ngIf="password.invalid">{{ passwordErrorMessage }}</mat-error>
  </mat-form-field>
  <mat-error *ngIf="loginForm.invalid">{{ formErrorMessage }}</mat-error>

  <div class="actions">
    <a class="forgot-password-link" (click)="forgotPassword()"
      >Forgot Password?</a
    >
    <ng-container *ngIf="{ loadingValue: loading$ | async } as value">
      <button
        type="submit"
        mat-flat-button
        color="accent"
        [disabled]="!googleLogin && value.loadingValue"
      >
        Login
        <mat-spinner
          diameter="20"
          appCustomSpinner
          color="white"
          *ngIf="!googleLogin && value.loadingValue"
        ></mat-spinner>
      </button>
    </ng-container>
  </div>
  <div class="dividerContainer">
    <div class="line">
      <mat-divider></mat-divider>
    </div>
    <div class="text orText mat-typography">Or</div>
    <div class="line">
      <mat-divider></mat-divider>
    </div>
  </div>
  <div class="googleSigninDiv">
    <button
      type="button"
      (click)="signInUsingGoogle()"
      class="login-with-google-btn"
    >
      Sign in with Google
    </button>
    <!-- <button
      mat-raised-button
      color="white"
      class="googleSignupBtn"
      (click)="signInUsingGoogle()"
    >
      <img src="/assets/images/Google_logo.svg" />
      Sign in with Gmail
    </button> -->
  </div>

  <div class="terms">
    By signing up to Litmus Learn, you agree to our
    <a routerLink="/terms">Terms</a> and
    <a routerLink="/privacy">Privacy Policy</a>.
  </div>
</form>

import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { UiService } from '@core/services/ui/ui.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent implements OnInit {
  constructor(private uiService: UiService) {}

  ngOnInit() {
    this.uiService.setNavbarVisibility(false);
    this.uiService.setSidenavVisibility(false);
  }
}

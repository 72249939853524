import { Injectable } from '@angular/core';

import { UrlBuilder } from '../../shared/classes/url-builder';
import { QueryStringParameters } from '../../shared/classes/query-string-parameters';

import { ConfigService } from './config.service';

@Injectable({ providedIn: 'root' })
export class ApiEndpointsService {
  constructor(private configService: ConfigService) {}

  createUrl(action: string): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(
      this.configService.readConfig().apiBaseUrl,
      action
    );
    return urlBuilder.toString();
  }
  createNotificationUrl(action: string = ''): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(
      this.configService.readConfig().notificationApiBaseUrl,
      action
    );
    return urlBuilder.toString();
  }

  /**
   * Generates endpoint url having path variables as well as query paramaters
   *
   * Eg; To create a endpoint url like API_HOSTNAME/questions?textOnly=true
   * use createUrlWithPathVariables('questions', (qs: QueryStringParameters) => {
      qs.push('textOnly', 1);
    } )
   */
  createUrlWithQueryParameters(
    action: string,
    queryStringHandler?: (queryStringParameters: QueryStringParameters) => void
  ): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(
      this.configService.readConfig().apiBaseUrl,
      action
    );
    // Push extra query string params
    if (queryStringHandler) {
      queryStringHandler(urlBuilder.queryString);
    }
    return urlBuilder.toString();
  }

  /**
   * Generates endpoint url having path variables
   *
   * Eg; To create a endpoint url like API_HOSTNAME/organization/{organizationId}
   * use createUrlWithPathVariables('organization', [organizationId] )
   */
  createUrlWithPathVariables(
    action: string,
    pathVariables: any[] = []
  ): string {
    let encodedPathVariablesUrl: string = '';
    // Push extra path variables
    for (const pathVariable of pathVariables) {
      if (pathVariable !== null) {
        encodedPathVariablesUrl += `/${encodeURIComponent(
          pathVariable.toString()
        )}`;
      }
    }
    return this.createUrl(`${action}${encodedPathVariablesUrl}`);
  }
  /**
   * Generates endpoint url having path variables
   *
   * Eg; To create a endpoint url like API_HOSTNAME/organization/{organizationId}
   * use createUrlWithPathVariables('organization', [organizationId] )
   */
  createNotificationUrlWithPathVariables(
    action: string,
    pathVariables: any[] = []
  ): string {
    let encodedPathVariablesUrl: string = '';
    // Push extra path variables
    for (const pathVariable of pathVariables) {
      if (pathVariable !== null) {
        encodedPathVariablesUrl += `/${encodeURIComponent(
          pathVariable.toString()
        )}`;
      }
    }
    return this.createNotificationUrl(`${action}${encodedPathVariablesUrl}`);
  }

  /**
   * Generates endpoint url having path variables as well as query paramaters
   *
   * Eg; To create a endpoint url like API_HOSTNAME/organization/{organizationId}/questions?textOnly=true
   * use createUrlWithPathVariables('organization', [organizationId], (qs: QueryStringParameters) => {
      qs.push('textOnly', 1);
    } )
   */
  createUrlWithPathVariablesAndQueryParams(
    action: string,
    pathVariables: any[] = [],
    queryStringHandler?: (queryStringParameters: QueryStringParameters) => void
  ): string {
    let encodedPathVariablesUrl: string = '';
    // Push extra path variables
    for (const pathVariable of pathVariables) {
      if (pathVariable !== null) {
        encodedPathVariablesUrl += `/${encodeURIComponent(
          pathVariable.toString()
        )}`;
      }
    }
    const urlBuilder: UrlBuilder = new UrlBuilder(
      this.configService.readConfig().apiBaseUrl,
      `${action}${encodedPathVariablesUrl}`
    );
    // Push extra query string params
    if (queryStringHandler) {
      queryStringHandler(urlBuilder.queryString);
    }
    return urlBuilder.toString();
  }
}

<main>
  <mat-card appearance="outlined" class="session-card">
    <div class="cancel-btn" (click)="onClickBack()" matTooltip="Back to home">
      <button mat-button>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ng-container [ngSwitch]="formInView$ | async">
      <div *ngSwitchCase="'CHANGE_PASSWORD'">
        <app-change-password-form
          [errorMessage]="errorMessage$ | async"
          (changePasswordClicked)="handleSetNewPassword($event)"
        ></app-change-password-form>
      </div>
      <div *ngSwitchCase="'SET_NEW_PASSWORD'">
        <app-set-password-form
          [errorMessage]="errorMessage$ | async"
          (setPasswordClicked)="handleSetNewPassword($event)"
        ></app-set-password-form>
      </div>
      <!-- <div *ngSwitchCase="'SIGNUP'">
        <app-signup-form
          [errorMessage]="errorMessage$ | async"
          [signUploading]="signUploading"
          [inputEmail]="inputEmail"
          [errorMsg]="errorMsg"
          [iserror]="iserror"
          (signupClicked)="handleSignup($event)"
          (gotologinClicked)="gotologin()"
        ></app-signup-form>
      </div>
      <div *ngSwitchCase="'SIGNUP_MODE'">
        <app-signupmode></app-signupmode>
      </div> -->
      <div *ngSwitchCase="'FORGOT_PASSWORD'">
        <app-forgot-password-form
          [errorMessage]="errorMessage$ | async"
          (forgotPasswordClicked)="handleForgotPassword($event)"
          (bypassForgotPasswordClicked)="handleBypassForgotPassword()"
          (loginClicked)="showLoginForm()"
        ></app-forgot-password-form>
      </div>
      <div *ngSwitchCase="'FORGOT_PASSWORD_SUBMIT'">
        <app-forgot-password-submit-form
          [forgotPasswordSubmitEmail]="forgotPasswordSubmitEmail"
          [errorMessage$]="errorMessage$"
          (forgotPasswordSubmitClicked)="handleForgotPasswordSubmit($event)"
          (loginClicked)="showLoginForm()"
        ></app-forgot-password-submit-form>
      </div>
      <div *ngSwitchCase="'LOGIN'">
        <app-login-form
          [errorMessage]="errorMessage$ | async"
          (loginClicked)="handleLogin($event)"
          (forgotClicked)="showForgotPasswordForm()"
          (singupModeClicked)="showSignupModeForm()"
        ></app-login-form>
      </div>
      <div *ngSwitchDefault>
        <app-login-form
          [errorMessage]="errorMessage$ | async"
          (loginClicked)="handleLogin($event)"
          (forgotClicked)="showForgotPasswordForm()"
        ></app-login-form>
      </div>
    </ng-container>
  </mat-card>

  <div class="waves">
    <svg viewBox="0 0 1516 279" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)" fill="#cb4242">
        <path
          d="M0 278.958V43.68C54.268 59.974 108.536 76.27 188.447 73.09c79.912-3.178 185.467-25.829 258.984-27.645 73.516-1.816 114.992 17.203 171.602 14.117 56.61-3.087 128.353-28.279 208.45-21.764 80.098 6.516 168.549 44.738 251.617 37.645 83.06-7.093 160.74-59.502 232.66-72.348 71.92-12.846 138.08 13.87 204.24 40.586v235.277H0z"
          fill-opacity=".5"
        />
        <path
          d="M0 278.959V161.32c55.471-5.12 110.943-10.24 182.131-2.353 71.187 7.887 158.092 28.78 244.244 18.234 86.152-10.546 171.553-52.532 252.667-47.056 81.113 5.476 157.941 58.414 213.714 57.055 55.773-1.358 90.491-57.013 160.024-67.642 69.53-10.629 173.87 23.768 256.88 38.232 83 14.465 144.67 8.997 206.34 3.53v117.639H0z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff !important" d="M0 0h1516v279H0z" />
        </clipPath>
      </defs>
    </svg>
  </div>
</main>

<div class="app-container">
  <ng-container
    *ngIf="{
      collapsible: isNavbarCollapsible$ | async,
      collapsed: isNavbarCollapsed$ | async,
      isMobile: isMobile$ | async,
      allowCollapse: isAllowCollapsible$ | async
    } as collapseObj"
  >
    <app-navbar
      [visible]="isNavbarVisible$ | async"
      [showBackButton]="showExit$ | async"
      [showHomeButton]="showHome$ | async"
      [hasSidenav]="hasSidenav$ | async"
      [notifications]="notifications$ | async"
      [sessionStatus]="sessionStatus$ | async"
      [showChapterHomeButton]="showChapterHome$ | async"
      [unreadNotificationsCount]="unreadNotificationsCount$ | async"
      (notificationClicked)="handleNotificationClicked($event)"
      (allNotificationsRead)="handleReadAllNotifications()"
      (menuClicked)="handleToggleMenu()"
      (homeClicked)="navigateToDashboard()"
      (backClicked)="navigateToPreviousPage()"
      (chapterHomeClicked)="navigateToChapterHome()"
      (notificationOpened)="handleNotificationOpened()"
    ></app-navbar>
    <mat-sidenav-container
      class="sidenav-container"
      [class.navbar-visible]="isNavbarVisible$ | async"
      [style.marginTop.px]="0"
      (backdropClick)="hideMenu(collapseObj.isMobile)"
      #drawer
    >
      <mat-sidenav
        class="sidenav"
        [mode]="collapseObj.isMobile ? 'over' : 'side'"
        [opened]="isSidenavVisible$ | async"
        [disableClose]="true"
        fixedTopGap="56"
        [@inOutAnimation]="
          collapseObj.collapsible &&
          collapseObj.collapsed &&
          !collapseObj.isMobile &&
          collapseObj.allowCollapse
            ? 'closed'
            : 'open'
        "
        (@inOutAnimation.start)="animationStart($event)"
        (@inOutAnimation.done)="animationDone($event)"
        (mouseenter)="
          onSinenavEnter(
            collapseObj.collapsible,
            collapseObj.collapsed,
            collapseObj.allowCollapse,
            collapseObj.isMobile
          )
        "
        (mouse)="
          onSinenavToggle(
            collapseObj.collapsible,
            collapseObj.collapsed,
            collapseObj.allowCollapse,
            collapseObj.isMobile
          )
        "
        (mouseleave)="
          onSinenavToggle(
            collapseObj.collapsible,
            collapseObj.collapsed,
            collapseObj.allowCollapse,
            collapseObj.isMobile
          )
        "
        [class.collapsed]="
          collapseObj.collapsible &&
          animationComplete &&
          collapseObj.collapsed &&
          collapseObj.allowCollapse &&
          !collapseObj.isMobile
        "
      >
        <div class="sidenav-content">
          <div class="custom-content">
            <ng-container
              [ngComponentOutlet]="customComponent$ | async"
            ></ng-container>
          </div>
        </div>
      </mat-sidenav>

      <mat-sidenav-content
        [class.collapsedcontent]="
          collapseObj.collapsible &&
          collapseObj.allowCollapse &&
          animationComplete &&
          collapseObj.collapsed &&
          !collapseObj.isMobile
        "
      >
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-container>
</div>

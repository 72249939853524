import { Route } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanLoad,
  UrlSegment,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';
import { LoggedInUserRole } from './session.model';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanLoad {
  constructor(private session: SessionService, private router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]) {
    if (segments.length !== 1) return true;
    let userRoleId = this.session.userRole;
    if (userRoleId !== LoggedInUserRole.ADMIN) {
      if (segments[0].path === 'admin-dashboard') {
        this.router.navigate(['/dashboard']);
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}

<form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
  <mat-form-field floatLabel="always" appearance="outline" class="full-width">
    <mat-label>Email</mat-label>
    <input
      matInput
      required
      placeholder="arjun@gmail.com"
      type="email"
      formControlName="email"
    />
    <mat-error *ngIf="email.invalid">{{ emailErrorMessage }}</mat-error>
  </mat-form-field>
  <div class="have-verification-wrapper">
    <a (click)="bypassForgotPassword()">Already have verification code</a>
  </div>

  <mat-error *ngIf="forgotPasswordForm.invalid">{{
    formErrorMessage
  }}</mat-error>
  <span class="small-text"
    >Note: If you signed up with Google, you have to login using the google
    button.<br />
    You cannot set a password for Google Signups.</span
  >

  <div class="actions">
    <a class="login-link" (click)="backToLogin()">Back to Login</a>
    <ng-container *ngIf="{ loadingValue: loading$ | async } as value">
      <button
        type="submit"
        mat-flat-button
        color="accent"
        [disabled]="value.loadingValue"
      >
        Forgot Password
        <mat-spinner
          diameter="20"
          appCustomSpinner
          color="white"
          *ngIf="value.loadingValue"
        ></mat-spinner>
      </button>
    </ng-container>
  </div>
</form>

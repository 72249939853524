import { Injectable } from '@angular/core';
import { DashboardStateService } from './dashboard-state.service';
import {
  UserSubject,
  ChapterView,
  ChapterContent,
  DashboardContent,
  CourseEnrollment,
} from './dashboard.model';
import {
  LessonPlan,
  LessonPlanAIGenerate,
} from './lesson-plans/lesson-plans.model';
import { encodeBase64 } from '@core/utils/helper';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  constructor(
    private dashboardState: DashboardStateService,
    private router: Router
  ) {}

  // slices of state

  readonly currentChapter$ =
    this.dashboardState.getStateSlice('currentChapter');
  readonly currentCourseId$ =
    this.dashboardState.getStateSlice('currentCourseId');
  readonly currentSubject$ =
    this.dashboardState.getStateSlice('currentSubject');
  readonly hasPermission$ = this.dashboardState.getStateSlice('hasPermission');
  readonly currentCourseName$ =
    this.dashboardState.getStateSlice('currentCourseName');
  readonly dashboardContent$ =
    this.dashboardState.getStateSlice('dashboardContent');

  readonly currentChapterContent$ = this.dashboardState.getStateSlice(
    'currentChapterContent'
  );
  readonly bookmarkedChapter$ =
    this.dashboardState.getStateSlice('bookmarkedChapter');
  readonly currentCourseContentId$ = this.dashboardState.getStateSlice(
    'currentCourseContentId'
  );
  readonly currentContentTypeId$ = this.dashboardState.getStateSlice(
    'currentContentTypeId'
  );

  readonly currentSessionParticipants$ = this.dashboardState.getStateSlice(
    'currentSessionParticipants'
  );

  readonly currentResourceSessionId$ = this.dashboardState.getStateSlice(
    'currentResourceSessionId'
  );

  readonly currentChapterLesson$ = this.dashboardState.getStateSlice(
    'currentChapterLesson'
  );

  readonly currentLessonAIGen$ =
    this.dashboardState.getStateSlice('currentLessonAIGen');

  readonly currentChapterView$ =
    this.dashboardState.getStateSlice('currentChapterView');

  readonly adminDefaultViewMode$ = this.dashboardState.getStateSlice(
    'adminDefaultViewMode'
  );

  get stateSnapshot() {
    return this.dashboardState.snapshot;
  }

  setAdminDefaultViewMode(value: string) {
    this.dashboardState.setState({ adminDefaultViewMode: value });
  }

  setCurrentChapter(chapterId: number) {
    this.dashboardState.setState({ currentChapter: chapterId });
  }

  setDashboardContent(dashboardContent: DashboardContent) {
    this.dashboardState.setState({ dashboardContent: dashboardContent });
  }

  setCurrentChapterContent(currentChapterContent: ChapterContent) {
    this.dashboardState.setState({
      currentChapterContent: currentChapterContent,
    });
  }

  setHasPermission(hasPermission: boolean) {
    this.dashboardState.setState({ hasPermission: hasPermission });
  }

  setCurrentResourceSessionId(currentResourceSessionId: number) {
    this.dashboardState.setState({
      currentResourceSessionId: currentResourceSessionId,
    });
  }

  setCurrentCourseId(courseId: number) {
    this.dashboardState.setState({ currentCourseId: courseId });
  }

  setCurrentSessionParticipants(
    currentSessionParticipants: CourseEnrollment[]
  ) {
    this.dashboardState.setState({
      currentSessionParticipants: currentSessionParticipants,
    });
  }
  setCurrentSubject(subject: UserSubject) {
    this.dashboardState.setState({ currentSubject: subject });
  }
  setCurrentCourseName(currentCourseName: string) {
    this.dashboardState.setState({ currentCourseName });
  }

  setCurrentCourseContentId(currentCourseContentId: number) {
    this.dashboardState.setState({ currentCourseContentId });
  }

  setCurrentContentTypeId(currentContentTypeId: number) {
    this.dashboardState.setState({ currentContentTypeId });
  }

  setBookmarkedChapter(bookmarkedChapter: number) {
    this.dashboardState.setState({ bookmarkedChapter });
  }

  setCurrentChapterLesson(currentChapterLesson: LessonPlan) {
    this.dashboardState.setState({ currentChapterLesson });
  }

  setcurrentLessonAIGen(currentLessonAIGen: LessonPlanAIGenerate) {
    this.dashboardState.setState({ currentLessonAIGen });
  }

  setCurrentChapterView(currentChapterView: ChapterView) {
    this.dashboardState.setState({ currentChapterView });
  }

  navigateToChapterContent(
    courseId: number,
    subjectId: number,
    chapterId: number
  ) {
    let courseIdEn = encodeURIComponent(encodeBase64(courseId.toString()));
    let subjectIdEn = encodeURIComponent(encodeBase64(subjectId.toString()));
    let chapterIdEn = encodeURIComponent(encodeBase64(chapterId.toString()));
    this.router.navigateByUrl(
      `/dashboard/chapter-content/${courseIdEn}/${subjectIdEn}/${chapterIdEn}`
    );
  }
}

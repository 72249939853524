import { Injectable } from '@angular/core';
import { UiService } from '@core/services/ui/ui.service';
import { LoggedInUserRole } from '@features/session/session.model';
import { SessionService } from '@features/session/session.service';
import { ShepherdService } from 'angular-shepherd';

@Injectable({
  providedIn: 'root',
})
export class AttendanceUserTourService {
  constructor(
    public shepherdService: ShepherdService,
    private uiService: UiService,
    private sessionService: SessionService
  ) {}

  role = LoggedInUserRole;

  startTour() {
    if (!this.shepherdService.isActive) {
      const sidenavState = this.uiService.stateSnapshot.sidenavVisible;
      let defaultSteps = {
        classes: 'class-1 class-2',
        scrollTo: true,
        cancelIcon: {
          enabled: true,
        },
      };

      this.shepherdService.modal = true;
      this.shepherdService.defaultStepOptions = defaultSteps;
      let teacherSteps = [
        {
          title: 'Welcome!',
          text: `Litmus helps keep track of your student attendance within one click! If attendance was already taken, you can click the edit button to modify the entries.`,
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',

          buttons: [
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Date',
          text: `Select the date for which you want to take the attendance. It is preloaded to today's date to make it easier.
          <br><br> When you click on the calendar, you will notice that all the days that you have taken attendance before are marked by a green circle.`,
          attachTo: {
            element: '.mat-form-field-outline',
            on: 'auto' as const,
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Mark All',
          text: `Click on Present or Absent in the header to mark all students as present or absent.<br><br> You can change the attendance for individual students after this.
          `,
          attachTo: {
            element: '.headerLabels',
            on: 'auto' as const,
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },
            {
              action() {
                return this.next();
              },
              text: 'Next',
            },
          ],
          id: 'creating',
        },
        {
          title: 'Attendance Report',
          text: `You can view the attendance history for this class by clicking on the report.You can also export the report to a spreadsheet from this page.
          <br><br> Go ahead! Try taking attendance right now!
          `,
          attachTo: {
            element: '.view-report',
            on: 'auto' as const,
          },
          classes:
            'shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',

          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: 'Back',
            },

            {
              action: function () {
                return this.complete();
              },
              text: 'Take Attendance!',
            },
          ],
          id: 'creating',
        },
      ];

      if (this.isTeacher) {
        // this.shepherdService.addSteps(teacherSteps);
      }

      this.shepherdService.modal = true;
      this.shepherdService.confirmCancel = false;
      this.shepherdService.start();
      this.shepherdService.tourObject.on('cancel', () => {
        this.userTourFinished();
      });
      this.shepherdService.tourObject.on('complete', () => {
        this.userTourFinished();
      });
    }
  }

  get isTeacher() {
    return (
      this.userRole === this.role.TEACHER || this.userRole === this.role.ADMIN
    );
  }
  get isStudent() {
    return this.userRole === this.role.STUDENT;
  }

  get userRole() {
    return this.sessionService.userRole;
  }

  userTourFinished() {
    let currentFeature = 'attendance';
    let existingEntries = JSON.parse(localStorage.getItem('userTours'));
    if (!existingEntries) {
      existingEntries = [];
    }
    let entry = {
      feature: currentFeature,
      completed: true,
    };
    if (
      existingEntries.filter((e) => e.feature === currentFeature).length === 0
    ) {
      // Save allEntries back to local storage
      existingEntries.push(entry);
    } else {
      existingEntries.filter((e) => e.feature === currentFeature)[0].completed =
        true;
    }
    localStorage.setItem('userTours', JSON.stringify(existingEntries));
  }
}

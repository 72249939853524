<form
  [formGroup]="forgotPasswordSubmitForm"
  (ngSubmit)="forgotPasswordSubmit()"
>
  <mat-form-field floatLabel="always" appearance="outline" class="full-width">
    <mat-label>Verification Code</mat-label>
    <input
      matInput
      required
      placeholder="Enter Verfication Code"
      type="text"
      formControlName="code"
    />
    <mat-error *ngIf="code.invalid">{{ codeErrorMessage }}</mat-error>
  </mat-form-field>
  <mat-form-field floatLabel="always" appearance="outline" class="full-width">
    <mat-label>Email</mat-label>
    <input
      matInput
      required
      placeholder="arjun@gmail.com"
      type="email"
      formControlName="email"
    />
    <mat-error *ngIf="email.invalid">{{ emailErrorMessage }}</mat-error>
  </mat-form-field>
  <mat-form-field floatLabel="always" appearance="outline" class="full-width">
    <mat-label>New Password</mat-label>
    <input
      matInput
      required
      formControlName="newPassword"
      [type]="showPassword ? 'text' : 'password'"
    />
    <button
      type="button"
      mat-icon-button
      matSuffix
      (click)="showPassword = !showPassword"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="!showPassword"
    >
      <mat-icon class="material-icons-outlined">{{
        showPassword ? 'visibility' : 'visibility_off'
      }}</mat-icon>
    </button>
    <mat-error *ngIf="newPassword.invalid">{{
      newPasswordErrorMessage
    }}</mat-error>
  </mat-form-field>
  <mat-form-field floatLabel="always" appearance="outline" class="full-width">
    <mat-label>Confirm Password</mat-label>
    <input
      matInput
      required
      formControlName="confirmPassword"
      [type]="showPassword ? 'text' : 'password'"
    />
    <button
      type="button"
      mat-icon-button
      matSuffix
      (click)="showPassword = !showPassword"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="!showPassword"
    >
      <mat-icon class="material-icons-outlined">{{
        showPassword ? 'visibility' : 'visibility_off'
      }}</mat-icon>
    </button>
    <mat-error *ngIf="confirmPassword.invalid">{{
      confirmPasswordErrorMessage
    }}</mat-error>
  </mat-form-field>

  <mat-error>{{ errorMessage$ | async }}</mat-error>

  <div class="terms">
    <span class="small-text">
      Note: You can only use forgot password after signing in with your
      temporary password for the first time.</span
    >
    <span style="color: var(--mask-orange)"
      >Please check promotions/updates tab and spam in your email inbox for an
      email from Litmus Learn with your verification code.
    </span>
  </div>

  <div class="actions">
    <a class="login-link" (click)="backToLogin()">Back to Login</a>
    <ng-container *ngIf="{ loadingValue: loading$ | async } as value">
      <button
        type="submit"
        mat-flat-button
        color="accent"
        [disabled]="forgotPasswordSubmitForm.invalid || value.loadingValue"
      >
        Update Password
        <mat-spinner
          diameter="20"
          appCustomSpinner
          color="white"
          *ngIf="value.loadingValue"
        ></mat-spinner>
      </button>
    </ng-container>
  </div>
</form>

<div class="change-pass-header">Change your password...</div>
<form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
  <mat-form-field floatLabel="always" appearance="outline" class="full-width">
    <mat-label>New Password</mat-label>
    <input
      matInput
      required
      formControlName="newPassword"
      [type]="showPassword ? 'text' : 'password'"
    />
    <button
      type="button"
      mat-icon-button
      matSuffix
      (click)="showPassword = !showPassword"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="!showPassword"
    >
      <mat-icon class="material-icons-outlined">{{
        showPassword ? 'visibility' : 'visibility_off'
      }}</mat-icon>
    </button>
    <mat-error *ngIf="newPassword.invalid">{{
      newPasswordErrorMessage
    }}</mat-error>
  </mat-form-field>
  <mat-form-field floatLabel="always" appearance="outline" class="full-width">
    <mat-label>Confirm Password</mat-label>
    <input
      matInput
      required
      formControlName="confirmPassword"
      [type]="showPassword ? 'text' : 'password'"
    />
    <button
      type="button"
      mat-icon-button
      matSuffix
      (click)="showPassword = !showPassword"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="!showPassword"
    >
      <mat-icon class="material-icons-outlined">{{
        showPassword ? 'visibility' : 'visibility_off'
      }}</mat-icon>
    </button>
    <mat-error *ngIf="confirmPassword.invalid">{{
      confirmPasswordErrorMessage
    }}</mat-error>
  </mat-form-field>
  <mat-error *ngIf="changePasswordForm.invalid">{{
    formErrorMessage
  }}</mat-error>

  <div class="actions">
    <ng-container *ngIf="{ loadingValue: loading$ | async } as value">
      <button
        type="submit"
        mat-flat-button
        color="accent"
        [disabled]="value.loadingValue"
      >
        Change Password
        <mat-spinner
          diameter="20"
          appCustomSpinner
          color="white"
          *ngIf="value.loadingValue"
        ></mat-spinner>
      </button>
    </ng-container>
  </div>
</form>

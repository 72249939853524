import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
  OnInit,
  ElementRef,
  OnChanges,
  SimpleChanges,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { renderMathInElement } from 'mathlive';

@Component({
  selector: 'app-render-math',
  template: `<span #contentContainer></span>`,
  styles: [
    `
      @keyframes blink-animation {
        50% {
          opacity: 0;
        }
      }
    `,
  ],
})
export class RenderMathComponent implements OnInit, OnChanges {
  @Input() innerHTML: string;
  @Input() html: string;
  @Input() isStreaming: boolean = false;
  @ViewChild('contentContainer', { static: true }) contentContainer: ElementRef;

  constructor(private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.renderMath();
  }

  ngOnInit() {
    this.renderMath();
  }

  private renderMath(): void {
    let stringVal: string = '';
    if (this.innerHTML) {
      stringVal = this.innerHTML;
    } else if (this.html) {
      stringVal = this.html;
    }
    stringVal = stringVal.replace(/<p><br><\/p>/g, '\n');
    stringVal = stringVal.replace(/\n/g, '<br>');

    this.contentContainer.nativeElement.innerHTML = stringVal;
    renderMathInElement(this.contentContainer.nativeElement, {
      TeX: {
        delimiters: {
          inline: [
            ['$$', '$$'],
            ['\\[', '\\]'],
          ],
          display: [['\\(', '\\)']],
        },
      },
      fontsDirectory: '/assets/fonts',
    });

    const hasDelimiters = /\$\$?|\\\(|\\\[/.test(stringVal);
    if (hasDelimiters) {
      this.contentContainer.nativeElement.classList.add('rendered-math');
    }

    if (this.isStreaming) {
      this.applyBlinkingEffect();
    }
  }

  private applyBlinkingEffect(): void {
    let content = this.contentContainer.nativeElement.innerText;
    const lastCharIndex = content.length - 1;
    const lastChar = content.charAt(lastCharIndex);
    content = content.substring(0, lastCharIndex); // Remove the last character
    this.contentContainer.nativeElement.innerHTML = content; // Update innerHTML
    const span = this.renderer.createElement('span');
    const text = this.renderer.createText(lastChar);
    this.renderer.setStyle(span, 'background-color', '#777');
    this.renderer.setStyle(
      span,
      'animation',
      'blink-animation 1s step-start infinite'
    );
    this.renderer.appendChild(span, text);
    this.renderer.appendChild(this.contentContainer.nativeElement, span);
  }
}

@NgModule({
  declarations: [RenderMathComponent],
  exports: [RenderMathComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RenderMathModule {}

import { Injectable } from '@angular/core';
import { Store } from 'src/app/store';
import {
  ChapterView,
  UserSubject,
  ChapterContent,
  DashboardContent,
  CourseEnrollment,
} from './dashboard.model';
import {
  LessonPlan,
  LessonPlanAIGenerate,
} from './lesson-plans/lesson-plans.model';

type DashboardState = {
  currentChapter: number | undefined;
  currentCourseId: number | undefined;
  currentCourseName: string | undefined;
  currentSubject: UserSubject | undefined;
  currentCourseContentId: number | undefined;
  bookmarkedChapter: number | undefined;
  currentContentTypeId: number | undefined;
  currentChapterContent: ChapterContent | undefined;
  dashboardContent: DashboardContent | undefined;
  currentChapterLesson: LessonPlan | undefined;
  currentChapterView: ChapterView | undefined;
  hasPermission: boolean | undefined;
  currentSessionParticipants: CourseEnrollment[] | undefined;
  adminDefaultViewMode: string | undefined;
  currentLessonAIGen: LessonPlanAIGenerate | undefined;
  currentResourceSessionId: number | undefined;
};

const initialState: DashboardState = {
  currentChapter: undefined,
  currentCourseId: undefined,
  currentCourseName: undefined,
  currentSubject: undefined,
  currentCourseContentId: undefined,
  currentContentTypeId: undefined,
  bookmarkedChapter: undefined,
  currentChapterContent: undefined,
  currentChapterLesson: undefined,
  dashboardContent: undefined,
  currentChapterView: undefined,
  currentSessionParticipants: undefined,
  currentLessonAIGen: undefined,
  currentResourceSessionId: undefined,
  hasPermission: true,
  adminDefaultViewMode: null,
};

@Injectable({ providedIn: 'root' })
export class DashboardStateService extends Store<DashboardState> {
  constructor() {
    super(initialState, { persist: true, key: 'dashboard' });
  }
}

<div class="notification-container">
  <div class="header-row">
    <div class="header-left">
      <div class="header-text">Notifications</div>
      <div class="h-b"></div>
    </div>
    <div class="header-right">
      <button mat-icon-button *ngIf="hasUnreadNotifications()">
        <mat-icon (click)="onClickMarkAllRead()" class="notification-close-icon"
          >done_all</mat-icon
        >
      </button>
    </div>
  </div>
  <div class="notification-body">
    <ng-container *ngIf="notifications.length === 0">
      <div class="e-text">You are all caught up!</div>
    </ng-container>
    <ng-container *ngIf="notifications.length > 0">
      <ng-container *ngFor="let notification of notifications">
        <div
          class="notification-item"
          (click)="onNotificationClicked(notification)"
        >
          <div class="avatar">
            <div class="name">
              {{ extractNotificationName(notification) }}
            </div>
            <div
              *ngIf="!isNotificationRead(notification)"
              class="read-badge"
            ></div>
          </div>
          <div class="cl-right">
            <div class="nt-box">
              <div class="nt-box-left">
                <div
                  class="notification-text"
                  [ngClass]="{ strongText: !isNotificationRead(notification) }"
                >
                  {{ notification.message }}
                </div>
                <div class="notification-date"></div>
              </div>
              <div class="nt-box-right"></div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
